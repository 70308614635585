<template>
  <footer class="page-footer">
    <div class="footer-logo">
      <div class="cael">
        <img src="/assets/images/cael-logo.svg" alt="Cael Logo" />
      </div>
    </div>
  </footer>
</template>

<script>
  export default {
    name: 'Footer',
    components: {},
    data() {
      return {}
    },
    computed: {},
    methods: {},
    updated() {},
    mounted() {},
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';

  .page-footer {
    border-top: 5px solid #d2252f;
    background: #d8d8d8;
    margin-top: 6em;
    padding: 2em;
    display: flex;
    color: #343131;
    font-size: 16px;
    line-height: 1em;
    justify-content: flex-end;

    .footer-logo {
      flex: 0 0 auto;
      display: -ms-flexbox;
      display: flex;

      .cael {
        align-self: flex-end;
        position: relative;

        img {
          height: 3em;
        }
      }
    }
  }
</style>
