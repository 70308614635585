var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"survey-details"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"12","lg":"6"}},[(!_vm.assessment.studentExtId)?_c('a',{staticClass:"survey-link",attrs:{"href":'/admin/entries/assessments/' +
          _vm.assessment.id +
          '-' +
          _vm.assessment.slug}},[_vm._v(" Add External ID ")]):_vm._e(),(_vm.assessment.studentExtId)?_c('p',{staticClass:"survey-id"},[_vm._v(" "+_vm._s(_vm.assessment.studentExtId)+_vm._s(_vm.assessment.studentSpExtId ? '-' + _vm.assessment.studentSpExtId : '')+" "),_c('br'),(_vm.assessment.studentExtId)?_c('span',{staticClass:"response-count"},[_vm._v(" "+_vm._s(_vm.getSurvey(_vm.assessment, 'student') ? _vm.getSurvey(_vm.assessment, 'student').responseCount + ' Responses' : 'No Data')+" ")]):_vm._e()]):_vm._e(),_c('v-btn',{staticClass:"edit-field",attrs:{"icon":"","href":'/admin/entries/assessments/' +
          _vm.assessment.id +
          '-' +
          _vm.assessment.slug}},[_c('v-icon',[_vm._v("create")])],1)],1),_c('v-col',{attrs:{"cols":"6","md":"12","lg":"6"}},[(!_vm.assessment.institutionExtId)?_c('a',{staticClass:"survey-link",attrs:{"href":'/admin/entries/assessments/' +
          _vm.assessment.id +
          '-' +
          _vm.assessment.slug}},[_vm._v(" Add External ID ")]):_vm._e(),(_vm.assessment.institutionExtId)?_c('p',{staticClass:"survey-id"},[_vm._v(" "+_vm._s(_vm.assessment.institutionExtId)+" "),_c('br'),(_vm.assessment.institutionExtId)?_c('span',{staticClass:"response-count"},[_vm._v(" "+_vm._s(_vm.getSurvey(_vm.assessment, 'institution') ? _vm.getSurvey(_vm.assessment, 'institution').responseCount + ' Responses' : 'No Data')+" ")]):_vm._e()]):_vm._e(),_c('v-btn',{staticClass:"edit-field",attrs:{"icon":"","href":'/admin/entries/assessments/' +
          _vm.assessment.id +
          '-' +
          _vm.assessment.slug}},[_c('v-icon',[_vm._v("create")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"12","lg":"6"}},[(_vm.getSurvey(_vm.assessment, 'student'))?_c('v-btn',{staticClass:"button primary",attrs:{"to":'/assessment/' +
          _vm.assessment.id +
          '/analyze/student/' +
          _vm.defaultStudentPrincipleId}},[_vm._v(" Analyze Student Data ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"6","md":"12","lg":"6"}},[(_vm.getSurvey(_vm.assessment, 'institution'))?_c('v-btn',{staticClass:"button primary",attrs:{"to":'/assessment/' +
          _vm.assessment.id +
          '/analyze/institution/' +
          _vm.defaultInstitutionPrincipleId}},[_vm._v(" Analyze Institution Data ")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }