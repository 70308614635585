<template>
  <section class="data">
    <!-- With Rows -->
    <div>
      <div class="principle-scores">
        <div class="header-score">
          <span v-if="hasRows">
            Principle
            {{ qualityOrSatisfaction }}
            Score
          </span>
        </div>
      </div>
      <template v-for="(currentQuestion, index) in questions">
        <template v-if="hasRows">
          <div
            class="question"
            :class="'question-double-' + choicesLength(currentQuestion.id)"
            :key="currentQuestion.id"
          >
            <div class="title" colspan="2">
              <a :id="`question-${currentQuestion.id}`"></a>
              <p>
                <strong>Q{{ getQuestion(currentQuestion.id).qNum }}.</strong>
                {{ getQuestion(currentQuestion.id).title }}
              </p>
              <p class="related-questions">
                <a
                  :href="getQuestion(currentQuestion.id).crossQuestionLink"
                  v-if="getQuestion(currentQuestion.id).crossQuestionLink"
                >
                  See related
                  {{
                    getQuestion(currentQuestion.id).questionType ===
                    'institutional'
                      ? 'student'
                      : 'institution'
                  }}
                  questions
                </a>
              </p>
            </div>
            <div class="question-responses">
              <div class="title-section">{{ qualityOrSatisfaction }}</div>
              <div class="labels">
                <div>
                  <strong>SCORE</strong>
                </div>
                <div
                  v-for="choice in data[page_type + 'ChoicesLookup']"
                  :key="choice.id"
                >
                  {{ choice.title }}
                </div>
              </div>
            </div>
          </div>
        </template>

        <!-- Without Rows -->
        <div
          v-else
          class="question"
          :class="
            'question-single-' +
            choicesLength(currentQuestion.smId || currentQuestion.id)
          "
          :key="currentQuestion.smId || currentQuestion.id"
        >
          <div class="title">
            <a :id="`question-${currentQuestion.id}`"></a>
            <p>
              <strong>
                Q{{
                  currentQuestion.otherQuestion
                    ? index + 1
                    : getQuestion(currentQuestion.id).qNum
                }}.
              </strong>
              {{
                currentQuestion.otherQuestion
                  ? currentQuestion.title
                  : getQuestion(currentQuestion.id).title | stripHtml
              }}
            </p>
            <p class="related-questions">
              <a
                :href="question.crossQuestionLink"
                v-if="question.crossQuestionLink"
              >
                See related
                {{
                  getQuestion(currentQuestion.id).questionType ===
                  'institutional'
                    ? 'student'
                    : 'institution'
                }}
                questions
              </a>
            </p>
          </div>

          <div class="question-responses question-responses-single">
            <div class="labels">
              <div
                v-for="choice in questionChoices(
                  currentQuestion.smId || currentQuestion.id
                )"
                :key="choice.id || choice.smId"
              >
                {{ choice.id ? data.choices[choice.id].title : choice.title }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="bumper"
          :key="
            'b-' +
            (currentQuestion.smId ? currentQuestion.smId : currentQuestion.id)
          "
        >
          <div></div>
          <div></div>
        </div>
      </template>
    </div>
    <column
      v-for="(column, index) in columns"
      :id="page_id"
      :index="index"
      :column="column"
      :key="`column-${column.uuid}`"
      :pageType="page_type"
      :updateData="updateData"
      :remove="removeColumn"
      :editing="editing"
      :questions="questions"
      :filteringData="filteringData"
      :hasRows="hasRows"
    ></column>
  </section>
</template>

<script>
  import _ from 'lodash'

  import { BehaviorSubject } from 'rxjs'

  import Column from '../models/Column'
  import FilteringData from '../models/FilteringData'

  import ColumnVue from './Column'

  export default {
    components: {
      column: ColumnVue,
    },
    props: {
      assessment_id: {
        type: String,
      },
      page_id: {
        type: String,
      },
      page_type: {
        type: String,
      },
    },
    data() {
      return {
        columns: [],
        tasks: [],
        filteringData: new FilteringData(),
        error: false,
        editing: new BehaviorSubject(null),
        additionalQuestions: [],
        aqSplitCrossRef: {},
        aqTitleLookup: {},
      }
    },
    computed: {
      questions() {
        // if additional questions principle selected
        if (this.isAQP) {
          // only need primary student survey as questions are same for split survey
          return this.additionalQuestions
        }
        // else return principle questions
        else {
          return this.getPrinciple(this.page_id)[this.page_type + 'Questions']
        }
      },
      surveyId() {
        return this.getAssessment(this.assessment_id)[this.page_type + 'ExtId']
      },
      survey() {
        return this.data.surveys[this.surveyId]
      },
      principleScore() {
        return this.survey.principleScores[this.page_id]
      },
      choices() {
        return this.data[this.page_type + 'ChoicesLookup']
      },
      qualityOrSatisfaction() {
        //console.log('page type: ' + this.page_type.toUpperCase())
        return this.page_type.toUpperCase() == 'INSTITUTION'
          ? 'Quality/Effectiveness'
          : 'Satisfaction'
      },
      hasRows() {
        // if not Additional Questions or About Me principle, has rows = true
        return !this.isAQP && !this.isAMP
      },
      // survey has additional questions
      hasAQ() {
        return this.additionalQuestions > 0
      },
      // is additional questions principle
      isAQP() {
        return this.page_id == 428192
      },
      // is about me principle
      isAMP() {
        return this.page_id == 426368
      },
      // choicesLookup() {
      //   let choicesLookup = {}
      //   // Create lookup for student question choices
      //   for (const [i, choice] of this.data.studentQuestionChoices.entries()) {
      //     choicesLookup[choice.id] = {
      //       score: i + 1,
      //       title: choice.title,
      //     }
      //   }

      //   // Create lookup for institution question choices
      //   for (const [
      //     i,
      //     choice,
      //   ] of this.data.institutionQuestionChoices.entries()) {
      //     choicesLookup[choice.id] = {
      //       score: i + 1,
      //       title: choice.title,
      //     }
      //   }
      //   return choicesLookup
      // },
    },
    watch: {
      // do things after route changes
      $route(to, from) {
        this.updateData(true)
      },
    },
    methods: {
      question(id) {
        return this.data.questions[id]
      },
      choicesLength(q) {
        //console.log('question:' + q)
        if (q) {
          if (this.data.questions[q]) {
            if (this.data.questions[q].choices.length) {
              return this.data.questions[q].choices.length
            } else {
              return Object.entries(this.choices).length
            }
          } else {
            for (const question of this.additionalQuestions) {
              let choices = []
              if (question.smId == q && question.choices) {
                for (const [choiceId, choice] of Object.entries(
                  question.choices
                )) {
                  choices.push(choice)
                }
                //console.log('answer count:' + choices.length)
                return choices.length
              }
            }
          }
        }
      },
      questionChoices(questionId) {
        //console.log('question choices:' + questionId)
        if (this.isAQP && this.additionalQuestions.length > 0) {
          for (const question of this.additionalQuestions) {
            let choices = []
            if (question.smId == questionId && question.choices) {
              for (const choice of Object.values(question.choices)) {
                choices.push(choice)
              }
              //console.log('answer count:' + choices.length)
              return choices
            }
          }
          return []
        } else {
          return this.getQuestion(questionId).choices
        }
      },
      hydrate() {
        console.log('hydrate')
        this.getFilteringData()
        const self = this
        if (sessionStorage.getItem('columns')) {
          this.columns = _.map(
            JSON.parse(sessionStorage.getItem('columns')),
            (column) => {
              if (
                !sessionStorage.getItem('pageType') ||
                sessionStorage.getItem('pageType') === self.page_type
              ) {
                return new Column(column)
              } else {
                return new Column(_.omit(column, 'subsets'))
              }
            }
          )
        }
        this.setFirstColumn()
        this.updateData()
      },

      saveColumns() {
        console.log('saveColumns')
        sessionStorage.setItem('pageType', this.page_type)
        sessionStorage.setItem(
          'columns',
          JSON.stringify(
            _.chain(this.columns)
              .filter((column) => !column.empty)
              .map((column) => column.apiData())
              .value()
          )
        )
      },

      getFilteringData() {
        console.log('getFilteringData')
        const self = this
        this.error = false

        // fetch(
        //   // `/assessment/${this.assessment_id}/filteringData/${this.page_type}`,
        //   `/internal/surveys/filters.json`, // TODO: Build out dynamic filter file
        //   {
        //     method: 'GET',
        //     cache: 'default',
        //   }
        // )
        //   .then((response) => {
        //     if (!response.ok) {
        //       throw new Error(response.statusText)
        //     }
        //     return response.json()
        //   })
        //   .then((json) => {
        //     if (json && json.subsets && json.filters)
        //       self.filteringData.setData(json)
        //     //console.log(json)
        //   })
        //   .catch(() => {
        //     self.error = true
        //   })
        let filterData = {}
        filterData.filters = []
        for (const assessment of this.data.assessments) {
          if (this.getSurvey(assessment, this.page_type)) {
            filterData.filters.push({
              name: 'assessment!!' + assessment.id,
              title: assessment.title,
              filter: null,
            })
          }
        }

        // Add Systems to the Filters list
        for (const system of this.data.systems) {
          filterData.filters.push({
            name: 'system!!' + system.id,
            title: 'SYSTEM: ' + system.title,
            filter: null,
          })
        }

        // Add Accreditations to the Filters list
        var accreditationSubselectors = new Array()
        for (const accreditation of this.data.accreditations) {
          accreditationSubselectors.push({
            title: accreditation.title,
            key: accreditation.id,
          })
        }
        filterData.filters.push({
          name: 'accreditation',
          title: 'DETAIL: Accreditation',
          filter: { name: 'accreditation', label: 'Accreditation' },
          subselectors: accreditationSubselectors,
        })

        // Add Minority Recognition to the Filters list
        var minoritySubselectors = new Array()
        for (const minority of this.data.minorityRecognition) {
          minoritySubselectors.push({
            title: minority.title,
            key: minority.id,
          })
        }
        filterData.filters.push({
          name: 'minorityRecognition',
          title: 'DETAIL: Minority Recognition',
          filter: {
            name: 'minorityRecognition',
            label: 'Minority Recognition',
          },
          subselectors: minoritySubselectors,
        })

        // Add Sector to the Filters List
        var sectorSubselectors = new Array()
        for (const sector of this.data.institutionSector) {
          sectorSubselectors.push({
            title: sector.title,
            key: sector.id,
          })
        }
        filterData.filters.push({
          name: 'institutionSector',
          title: 'DETAIL: Sector',
          filter: {
            name: 'institutionSector',
            label: 'Sector',
          },
          subselectors: sectorSubselectors,
        })

        // Add Type to the Filters List
        var typeSubselectors = new Array()
        for (const insttype of this.data.institutionType) {
          typeSubselectors.push({
            title: insttype.title,
            key: insttype.id,
          })
        }
        filterData.filters.push({
          name: 'institutionType',
          title: 'DETAIL: Type',
          filter: {
            name: 'institutionType',
            label: 'Type',
          },
          subselectors: typeSubselectors,
        })

        // Add Institutional Size to the Filters List
        var sizeSubselectors = new Array()
        for (const size of this.data.institutionSize) {
          sizeSubselectors.push({
            title: size.title,
            key: size.id,
          })
        }
        filterData.filters.push({
          name: 'institutionSize',
          title: 'DETAIL: Size',
          filter: {
            name: 'institutionSize',
            label: 'Size',
          },
          subselectors: sizeSubselectors,
        })

        // Add All to the Filters List
        var all = {
          name: 'all',
          title: 'All',
          filter: { name: 'all', label: 'All' },
          subselectors: [],
        }
        filterData.filters.push(all)

        // Add Subsets to the object
        filterData.subsets = []

        if (this.page_type.toUpperCase() === 'STUDENT') {
          // Add Age to the Subsets List
          filterData.subsets.push({
            id: '1',
            questionId: '427535',
            title: 'Age',
            choices: this.assembleQuestionChoices('427535'),
          })

          // Add Ethnicity to the Subsets List
          filterData.subsets.push({
            id: '2',
            questionId: '427553',
            title: 'Race/Ethnicity',
            choices: this.assembleQuestionChoices('427553'),
          })

          // Add Special Class to the Subsets List
          filterData.subsets.push({
            id: '3',
            questionId: '427565',
            title: 'Special Class',
            choices: this.assembleQuestionChoices('427565'),
          })

          // Add Marital Status to the Subsets List
          filterData.subsets.push({
            id: '4',
            questionId: '427575',
            title: 'Marital Status',
            choices: this.assembleQuestionChoices('427575'),
          })

          // Add Dependents to the Subsets List
          filterData.subsets.push({
            id: '5',
            questionId: '427589',
            title: 'Dependents',
            choices: this.assembleQuestionChoices('427589'),
          })

          // Add Class Load to the Subsets List
          filterData.subsets.push({
            id: '6',
            questionId: '427597',
            title: 'Class Load',
            choices: this.assembleQuestionChoices('427597'),
          })

          // Add Credits Completed to the Subsets List
          filterData.subsets.push({
            id: '7',
            questionId: '427609',
            title: 'Credits Completed',
            choices: this.assembleQuestionChoices('427609'),
          })

          // Add Program to the Subsets List
          filterData.subsets.push({
            id: '8',
            questionId: '427705',
            title: 'Program',
            choices: this.assembleQuestionChoices('427705'),
          })

          // Add Goal to the Subsets List
          filterData.subsets.push({
            id: '9',
            questionId: '427717',
            title: 'Goal',
            choices: this.assembleQuestionChoices('427717'),
          })

          // Add Highest Level Education to the Subsets List
          filterData.subsets.push({
            id: '10',
            questionId: '427729',
            title: 'Highest Level Education',
            choices: this.assembleQuestionChoices('427729'),
          })

          // Add Funding Source to the Subsets List
          filterData.subsets.push({
            id: '11',
            questionId: '427745',
            title: 'Funding Source',
            choices: this.assembleQuestionChoices('427745'),
          })

          // Add Hours Worked to the Subsets List
          filterData.subsets.push({
            id: '12',
            questionId: '427759',
            title: 'Hours Worked',
            choices: this.assembleQuestionChoices('427759'),
          })

          // Add Gender Identity to the Subsets List
          filterData.subsets.push({
            id: '13',
            questionId: '427777',
            title: 'Gender Identity',
            choices: this.assembleQuestionChoices('427777'),
          })
        }

        if (this.page_type.toUpperCase() === 'INSTITUTION') {
          // Add Departments to the Subsets List - HIDDEN FOR NOW
          /*filterData.subsets.push({
          id: '1',
          questionId: '427841',
          title: 'Acedemic School/Department',
          choices: this.assembleQuestionChoices('427841'),
        })*/
        }

        self.filteringData.setData(filterData)
      },

      assembleQuestionChoices(qId) {
        var choices = new Array()
        for (const choice of this.data.questions[qId].choices) {
          let choiceContent = this.data.choices[choice.id]
          choices.push({
            title: choiceContent.title,
            id: choiceContent.id,
          })
        }

        return choices
      },

      calcSurveyData(column, surveys) {
        //console.log('calcSurveyData')
        //console.log('column', column)
        // loop through survey question/response data and build initial calculation
        let calcs = {
          filter: column.filter,
          signature: JSON.stringify([column.filter, column.subsets]),
          subsets: '["' + column.subsets + '"]',
          qualityAverage: 0,
          importanceAverage: 0,
          qualityPercentile: 0,
          importancePercentile: 0,
          questionsObj: {},
          questions: [],
        }

        //console.log('calcs start:' + JSON.stringify(calcs))
        //console.log('survey count: ' + surveys.length)
        //console.log(surveys)

        for (const survey of surveys) {
          //console.log('SURVEY ID: ' + survey.id)
          if (survey) {
            //console.log('survey response count: ' + survey.responses.length)
            //console.log('subsets:', column.subsets)
            for (const response of survey.responses) {
              // FILTER RESPONSES BASED ON SUBSETS HERE

              //console.log('response:', response)
              if (column.subsets.length) {
                var matches = new Object()
                calcs.subsets = column.subsets
                for (let subset of column.subsets) {
                  if (matches[subset.parent]) {
                    break
                  } else if (
                    response.questions[subset.parent] == subset.subsetRef
                  ) {
                    matches[subset.parent] = true
                  } else {
                    matches[subset.parent] = false
                  }
                }
                //console.log(matches)

                var includeResponse = true
                for (let match in matches) {
                  //console.log(matches[match])
                  if (!matches[match]) {
                    includeResponse = false
                    break
                  }
                }

                if (!includeResponse) {
                  continue
                }
              }

              for (const [initialQId, qVal] of Object.entries(
                response.questions
              )) {
                let qId = initialQId
                // if additional questions from splitSurveyData
                // crossreference split survey questionIDs
                if (this.isAQP && survey.isSplitSurveyData && qId.length > 7) {
                  qId = this.aqSplitCrossRef[initialQId].smId
                }

                if (!calcs.questionsObj[qId]) calcs.questionsObj[qId] = {}
                let calcsQuestion = calcs.questionsObj[qId]

                // if row exists with standard questions
                if (qVal.q) {
                  // step through i and q of each question and calculate counts, sums and scores
                  for (const [rowId, choiceId] of Object.entries(qVal)) {
                    if (choiceId) {
                      // initialize row object
                      if (!calcsQuestion[rowId])
                        // cc = choice count
                        calcsQuestion[rowId] = { cc: 0, sum: 0, s: 0, tcc: 0 }
                      if (!calcsQuestion[rowId][choiceId])
                        calcsQuestion[rowId][choiceId] = { c: 0, s: 0 }

                      // increment choice count
                      calcsQuestion[rowId][choiceId].c += 1
                      calcsQuestion[rowId][choiceId].s =
                        this.data[this.page_type + 'ChoicesLookup'][
                          choiceId
                        ].score // base choice score

                      // increment total choice count (includes "not sure/does not apply")
                      calcsQuestion[rowId].tcc++

                      // if choice is not 6 = 'not sure/does not apply', then
                      // add score to sum and increment choice count
                      if (
                        this.data[this.page_type + 'ChoicesLookup'][choiceId]
                          .score < 6
                      ) {
                        calcsQuestion[rowId].sum +=
                          this.data[this.page_type + 'ChoicesLookup'][
                            choiceId
                          ].score
                        calcsQuestion[rowId].cc++
                      }

                      // calculate score
                      calcsQuestion[rowId].s =
                        calcsQuestion[rowId].sum / calcsQuestion[rowId].cc
                    }
                  }
                } else {
                  // in the case of Additional or About Me questions,
                  // there is no row. Just set choiceId with qVal
                  // set choiceId to array if single About Me string
                  if (!qVal.openEnded) {
                    let choiceIds = Array.isArray(qVal) ? qVal : [qVal]
                    if (choiceIds.length) {
                      // initialize cc (choice count)
                      for (const initialChoiceId of choiceIds) {
                        let choiceId = initialChoiceId

                        // if additional choices from splitSurveyData
                        // crossreference split survey choiceIDs
                        if (
                          this.isAQP &&
                          survey.isSplitSurveyData &&
                          qId.length > 7
                        ) {
                          choiceId =
                            this.aqSplitCrossRef[initialQId].choices[
                              initialChoiceId
                            ]
                        }

                        if (!calcsQuestion.tcc) calcsQuestion.tcc = 0
                        if (!calcsQuestion[choiceId])
                          calcsQuestion[choiceId] = { c: 0 }

                        // increment choice count
                        calcsQuestion[choiceId].c += 1

                        // increment total choice count (includes "not sure/does not apply")
                        calcsQuestion.tcc++
                      }
                    }
                  }
                  // if open ended additional question push to answers array
                  else {
                    if (!calcs.questionsObj[qId].answers)
                      calcs.questionsObj[qId].answers = []
                    calcs.questionsObj[qId].answers.push(qVal.answer)
                  }
                }
              }
            }
          }
        }

        // At this point, questionsObj has all the individual responses
        //console.log('calcs.questionsObj:', calcs)
        //console.log('obj count:' + Object.keys(calcs.questionsObj).length)

        // if not additional questions
        if (this.additionalQuestions.length == 0) {
          // loop through all principle questions
          for (const question of this.questions) {
            let qId = question.id
            let qVal = calcs.questionsObj[qId]
            // if response question exists and it's a standard question (rowId = q)
            if (qVal && qVal.q) {
              for (const [rowId, data] of Object.entries(qVal)) {
                // limit data to "Quality/Satisfaction" choices
                if (data && rowId == 'q') {
                  let respCalcs = []

                  for (const choice of this.data[
                    this.page_type + 'QuestionChoices'
                  ]) {
                    let respCalc = 0
                    for (const [id, respData] of Object.entries(data)) {
                      // calculate choice percentage
                      if (choice.id == id) respCalc = respData.c / data.tcc
                    }

                    respCalcs.push(respCalc)
                  }
                  calcs.questions.push({
                    questionId: qId,
                    rowId: rowId,
                    score: data.s,
                    data: respCalcs,
                  })
                }
              }
              // if about us question (no rowId = 'q')
            } else if (qVal) {
              let respCalcs = []

              for (const choice of this.getQuestion(question.id).choices) {
                let respCalc = 0
                for (const [id, respData] of Object.entries(qVal)) {
                  // calculate choice percentage
                  if (choice.id == id) respCalc = respData.c / qVal.tcc
                }
                respCalcs.push(respCalc)
              }

              calcs.questions.push({
                questionId: qId,
                data: respCalcs,
              })
            }
            // push empty question as filler for view. This typically happens when the survey question title does not match what's in the CMS.  Look at the error-XXXXXX.json file to confirm what question is missing
            else {
              calcs.questions.push({
                questionId: qId,
              })
            }
          }
        }

        // calculate additional questions totals
        else {
          for (const question of this.additionalQuestions) {
            let respCalcs = []

            // if question has choices
            if (question.choices) {
              for (const choice of Object.values(question.choices)) {
                // additional questions use survey monkey ID as they are not stored in Craft
                let qVal = calcs.questionsObj[question.smId]

                if (qVal) {
                  // workaround for not split surveys
                  let respCalc = 0
                  for (const [smId, respData] of Object.entries(qVal)) {
                    // calculate choice percentage
                    if (choice.smId == smId) respCalc = respData.c / qVal.tcc
                  }
                  respCalcs.push(respCalc)
                }
              }
            }
            // else if question is open ended
            else {
              respCalcs = calcs.questionsObj[question.smId]
            }

            calcs.questions.push({
              questionId: question.smId,
              data: respCalcs,
            })
          }
        }

        // Remove all individual responses
        delete calcs.questionsObj

        //console.log('calcs end:', calcs)

        return calcs
      },

      loadAllData(assessments) {
        console.log('loadAllData')
        var surveys = new Array()

        //console.log('assessments again: ' + assessments)

        for (let assessmentId of assessments) {
          //console.log('assessmentId:' + assessmentId)
          for (const assessment of this.data.assessments) {
            if (assessment.id == assessmentId) {
              if (this.getSurvey(assessment, this.page_type)) {
                surveys.push(this.getSurveyData(assessment, this.page_type))

                // get split survey id if student survey
                if (this.page_type == 'student') {
                  surveys.push(
                    this.getSurveyData(assessment, this.page_type, true)
                  )
                }
              } else {
                //console.log('NOT FOUND - surveyId:' + surveyId)
              }
            }
          }
        }
        return surveys
      },

      updateData(forceUpdate = false) {
        const vm = this

        console.log('updateData')
        // clear additionalQuestions
        this.additionalQuestions = []
        this.saveColumns()
        // Set blank column if necessary (if fetch is occuring from new column)
        this.setBlankColumn()
        const self = this
        this.error = false
        // const query = _.chain(this.columns)
        //   .filter((column) => !column.data && !column.empty)
        //   .map((column) => column.apiData())
        //   .value()

        for (const column of this.columns) {
          //console.log('column.filter: ' + column.filter)
          if ((column.data == null || forceUpdate) && !column.empty) {
            //const surveyData = this.getSurveyData(surveyId)
            //const splitSurveyData = this.getSurveyData(splitSurveyId)

            var assessments = new Array()
            var schools = new Array()
            //console.log('column.filter:', column.filter)
            if (column.filter.indexOf('system') >= 0) {
              var systemId = column.filter.split('!!')[1]
              //console.log('systemId:' + systemId)
              for (const system of this.data.systems) {
                if (system.id == systemId) {
                  for (let assessment of system.assessments) {
                    //console.log('assessment.id:' + assessment.id)
                    assessments.push(assessment.id)
                  }
                }
              }
            } else if (column.filter.indexOf('assessment') >= 0) {
              assessments.push(column.filter.split('!!')[1])
            } else if (column.filter.indexOf('accreditation') >= 0) {
              var accreditationId = column.filter.split('!!')[1]
              for (let school of this.data.schools) {
                for (let accreditation of school.accreditations) {
                  if (accreditation.id == accreditationId) {
                    schools.push(school.id)
                  }
                }
              }
              assessments = this.lookupAssessmentsFromSchools(schools)
            } else if (column.filter.indexOf('minorityRecognition') >= 0) {
              var mrId = column.filter.split('!!')[1]
              for (let school of this.data.schools) {
                for (let recognition of school.minorityRecognitions) {
                  if (recognition.id == mrId) {
                    schools.push(school.id)
                  }
                }
              }
              assessments = this.lookupAssessmentsFromSchools(schools)
            } else if (column.filter.indexOf('institutionSector') >= 0) {
              var sectorId = column.filter.split('!!')[1]
              for (let sector of this.data.institutionSector) {
                if ((sector.id = sectorId)) {
                  var sectorTitle = sector.title
                }
              }
              for (let school of this.data.schools) {
                //console.log(school.id)
                if (school.ipeds.length) {
                  if (sectorTitle == school.ipeds[0].institutionalType) {
                    schools.push(school.id)
                  }
                }
              }
              assessments = this.lookupAssessmentsFromSchools(schools)
            } else if (column.filter.indexOf('institutionType') >= 0) {
              var typeId = column.filter.split('!!')[1]
              for (let insttype of this.data.institutionType) {
                if (insttype.id == typeId) {
                  var typeTitle = insttype.title
                }
              }
              for (let school of this.data.schools) {
                if (school.ipeds.length) {
                  if (typeTitle == school.ipeds[0].institutionalCategory) {
                    schools.push(school.id)
                  }
                }
              }
              assessments = this.lookupAssessmentsFromSchools(schools)
            } else if (column.filter.indexOf('institutionSize') >= 0) {
              var sizeId = column.filter.split('!!')[1]
              //console.log('sizeId:' + sizeId)
              for (let size of this.data.institutionSize) {
                if (size.id == sizeId) {
                  var sizeTitle = size.title
                  //console.log('sizeTitle:' + sizeTitle)
                }
              }
              for (let school of this.data.schools) {
                //console.log('school:' + school.title)
                if (school.ipeds.length) {
                  //console.log('school institutionSize:' + school.ipeds[0].institutionSize)
                  if (sizeTitle == school.ipeds[0].institutionSize) {
                    schools.push(school.id)
                  }
                }
              }
              assessments = this.lookupAssessmentsFromSchools(schools)
            } else if (column.filter.indexOf('all') >= 0) {
              for (let assessment of this.data.assessments) {
                assessments.push(assessment.id)
              }
            }
            //console.log('assessments: ' + assessments)

            const surveyData = this.loadAllData(assessments)

            Promise.all(surveyData)
              .then((surveys) => {
                //console.log('loaded!' + surveys)
                if (surveys) {
                  // set hasAdditionalQuestions to hide or show nav
                  this.appState.hasAdditionalQuestions = !!Object.values(
                    surveys[0].additionalQuestions
                  ).length

                  //console.log('surveys returned!')
                  var splitSurveyData = true

                  // add additional questions from primary survey if AQ array is empty
                  if (vm.isAQP && vm.additionalQuestions.length == 0) {
                    for (const aq of Object.values(
                      surveys[0].additionalQuestions
                    )) {
                      vm.additionalQuestions.push(aq)

                      // set splitSurveyData to false for first survey
                      surveys[0].isSplitSurveyData = false

                      // build out question/chioice SM lookup for split survey additional questions
                      if (splitSurveyData) {
                        let primaryTitle = vm.cleanKey(aq.title)
                        vm.aqTitleLookup[primaryTitle] = {
                          smId: aq.smId,
                          choices: {},
                        }
                        if (aq.choices) {
                          for (const choice of Object.values(aq.choices)) {
                            let primaryChoice = vm.cleanKey(choice.title)
                            vm.aqTitleLookup[primaryTitle].choices[
                              primaryChoice
                            ] = choice.smId
                          }
                        }
                      }
                    }
                    if (splitSurveyData) {
                      // set splitSurveyData to true for second associated survey
                      surveys[1].isSplitSurveyData = true

                      // loop through other split survey and build out question/choice IDs
                      for (const aq of Object.values(
                        surveys[1].additionalQuestions
                      )) {
                        let secondaryTitle = vm.cleanKey(aq.title)
                        let primarySmId = vm.aqTitleLookup[secondaryTitle].smId
                        vm.aqSplitCrossRef[aq.smId] = {
                          smId: primarySmId,
                          choices: {},
                        }

                        if (aq.choices) {
                          for (const choice of Object.values(aq.choices)) {
                            let secondaryChoice = vm.cleanKey(choice.title)
                            vm.aqSplitCrossRef[aq.smId].choices[choice.smId] =
                              vm.aqTitleLookup[secondaryTitle].choices[
                                secondaryChoice
                              ]
                          }
                        }
                      }
                    }
                  }

                  // console.log('vm.aqSplitCrossRef:', vm.aqSplitCrossRef)

                  //self.questions = json.questions || []
                  let newData = new Array()
                  newData.push(self.calcSurveyData(column, surveys))
                  self.setColumnData(newData)
                }
              })
              .catch(() => {
                self.error = true
              })
          }
        }
      },

      lookupAssessmentsFromSchools(schools) {
        var assessments = new Array()
        //console.log('schools:', schools)
        for (let assessment of this.data.assessments) {
          //console.log(assessment.id + ':' + assessment.school[0].id)
          if (assessment.school.length) {
            if (schools.includes(assessment.school[0].id)) {
              assessments.push(assessment.id)
            }
          }
        }
        //console.log('assessments:', assessments)
        return assessments
      },

      getChoiceTitle(choiceTitle, rowTitle) {
        const choices = choiceTitle.split(' / ')
        // Neutral
        if (choices.length < 2) return choiceTitle
        return rowTitle === 'Importance' ? choices[0] : choices[1]
      },

      setFirstColumn() {
        console.log('setFirstColumn')
        if (this.columns.length) {
          if (this.columns[0].getAssessmentId() !== this.assessment_id)
            this.columns[0].setAssessmentId(this.assessment_id)
        } else {
          this.columns.push(
            new Column({ filter: `assessment!!${this.assessment_id}` })
          )
          this.setBlankColumn()
        }
      },

      setColumnData(data) {
        console.log('setColumnData')
        const self = this
        _.each(data, (item) =>
          _.chain(self.columns)
            .filter(
              (column) => !item.task && item.signature === column.getSignature()
            )
            .each((column) => column.setData(item))
            .value()
        )
        _.each(
          data,
          (item) =>
            item.task &&
            !_.find(self.tasks, (task) => task.signature === item.signature) &&
            self.pollTask(item)
        )
      },

      pollTask(task) {
        this.tasks.push(task)
        this.poll()
        this.runTasks()
      },

      poll() {
        _.throttle(function () {
          const self = this
          Promise.all(
            _.map(this.tasks, (task) =>
              fetch(`/analyzeData/status/${task.task.id}`, {
                method: 'GET',
                cache: 'default',
              })
                .then((response) => {
                  if (response.status === 404) return false
                  if (response.status >= 400) throw new Error()
                  return response.json()
                })
                .then((json) => {
                  return json && json !== 'error' ? false : task
                })
                .catch(() => {
                  self.error = true
                })
            )
          ).then((values) => {
            // Remove completed.
            self.tasks = _.filter(
              self.tasks,
              (task) => !_.find(values, (value) => value === task)
            )
            if (_.find(values, (value) => value)) self.updateData()
            if (self.tasks.length) {
              self.runTasks()
              self.poll()
            }
          })
        }, 1000)
      },

      // Craft needs a nudge to turn on tasks
      runTasks() {
        _.throttle(function () {
          fetch('/analyzeData/runTasks', {
            method: 'GET',
            cache: 'default',
          })
        }, 1000)
      },

      removeColumn(column) {
        const thisColumn = _.find(this.columns, (_column) => _column === column)
        if (thisColumn) this.columns.splice(this.columns.indexOf(thisColumn), 1)
        this.saveColumns()
        this.setBlankColumn()
      },

      setBlankColumn() {
        console.log('setBlankColumn')
        if (!_.last(this.columns).empty)
          this.columns.push(new Column({ empty: true }))
      },
    },

    mounted() {
      this.hydrate()
    },
  }
</script>
