export default class FilteringData {
  constructor() {
    this.filters = []
    this.subsets = []
  }

  setData({ filters, subsets }) {
    if (filters) this.filters = filters
    if (subsets) this.subsets = subsets
  }
}
