<template>
  <div class="page-container">
    <main class="page-content">
      <div class="page home">
        <div class="page-content-inner">
          <div class="login">
            <div v-if="!resetPassword" id="login-form">
              <h3>Login</h3>
              <p>Login to analyze AL360 surveys</p>

              <form @submit="login">
                <input type="hidden" name="action" value="users/login" />
                <input type="hidden" name="redirect" value="/" />
                <input type="hidden" name="rememberMe" value="1" />
                <input
                  v-model="loginName"
                  type="text"
                  placeholder="Email Address"
                />

                <input
                  v-model="password"
                  :type="showPassword ? 'text' : 'password'"
                  placeholder="Password"
                />
                <div v-if="loginError" class="error">{{ loginError }}</div>

                <input
                  id="show-password"
                  type="checkbox"
                  class="styled"
                  v-model="showPassword"
                />
                <label for="show-password" class="styled">
                  <i class></i>
                  <span>Show Password</span>
                </label>

                <div class="actions">
                  <input type="submit" class="button" value="Log-In" />
                  <a
                    id="show-reset-password"
                    class="post-login"
                    @click="resetPassword = true"
                  >
                    Reset Password
                  </a>
                </div>
                <div class="clear"></div>
              </form>
            </div>
            <div v-else>
              <h3>Password Reset</h3>

              <form @submit="forgotPassword">
                <input
                  v-model="resetLoginName"
                  type="text"
                  placeholder="Username or Email Address"
                />
                <div v-if="checkEmail">{{ checkEmail }}</div>
                <div v-if="loginError" class="error">{{ loginError }}</div>

                <div class="actions">
                  <input type="submit" class="button" value="Submit" />
                  <a
                    id="show-login"
                    class="post-login"
                    @click="resetPassword = false"
                  >
                    Show Login
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'App',
    components: {},
    data() {
      return {
        loginName: '',
        resetLoginName: '',
        password: '',
        showPassword: false,
        loginError: '',
        resetPassword: false,
        checkEmail: '',
      }
    },
    computed: {},
    methods: {
      login(e) {
        e.preventDefault()

        this.loginError = ''

        const data = {
          actionInput: 'users/login',
          redirectInput: '/',
          rememberMe: 1,
          loginName: this.loginName,
          password: this.password,
          CRAFT_CSRF_TOKEN: this.csrfToken,
        }

        axios.post('/admin/login', data).then((response) => {
          const data = response.data
          if (data.error) {
            this.loginError = data.error
          } else {
            // update csrf token
            this.$store.commit('SET_CSRF_TOKEN', response.data.csrfTokenValue)

            axios.get('/api/user').then((response) => {
              // get user data
              this.$store.commit('SET_USER', response.data)

              this.$store.commit('CHANGE_LOADING_STATUS', true)
              this.$store.dispatch('loadData')

              this.$router.push({ name: 'dashboard' })
            })
          }
        })
      },
      forgotPassword(e) {
        e.preventDefault()
        this.checkEmail = ''
        this.loginError = ''

        const data = {
          actionInput: 'users/send-password-reset-email',
          redirectInput: '/',
          loginName: this.resetLoginName,
          CRAFT_CSRF_TOKEN: this.csrfToken,
        }

        axios
          .post('/admin/actions/users/send-password-reset-email', data)
          .then((response) => {
            const data = response.data
            if (data.error) {
              this.loginError = data.error

              console.log(data)
            } else {
              console.log(data)
              this.checkEmail =
                'Check your email for instructions to reset your password.'
            }
          })
      },
    },
    updated() {},
    mounted() {},
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';
  .v-application div.error {
    background-color: transparent !important;
    color: #d22630;
  }
</style>
