<template>
  <div class="page-container">
    <main class="page-content">
      <div class="analyze page">
        <h2>{{ toTitle(page_type) }} Survey Selections</h2>
        <principle-tabs :assessment_id="assessment_id" :page_type="page_type" :page_id="page_id"></principle-tabs>
        <div class="analyze-content">
          <h2>{{ getPrinciple(page_id).title }}</h2>
          <analyze :assessment_id="assessment_id" :page_type="page_type" :page_id="page_id"></analyze>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
  // import AboveMedian from '@/components/AboveMedian'
  import Analyze from '@/components/Analyze'
  import PrincipleTabs from '@/components/PrincipleTabs'
  // import AssessmentDetails from '@/components/AssessmentDetails'
  // import AssessmentList from '@/components/AssessmentList'
  // import AssessmentTable from '@/components/AssessmentTable'
  // import ClassificationDetails from '@/components/ClassificationDetails'
  // import Column from '@/components/Column'
  // import Contacts from '@/components/Contacts'
  // import DocumentLinks from '@/components/DocumentLinks'
  // import Filter from '@/components/Filter'
  // import Subset from '@/components/Subset'
  // import SurveyDetails from '@/components/SurveyDetails'
  // import SurveyStatus from '@/components/SurveyStatus'
  // import TopQuestions from '@/components/TopQuestions'

  export default {
    components: {
      // AboveMedian,
      Analyze,
      PrincipleTabs,
      // AssessmentDetails,
      // AssessmentList,
      // AssessmentTable,
      // ClassificationDetails,
      // Column,
      // Contacts,
      // DocumentLinks,
      // Filter,
      // Subset,
      // SurveyDetails,
      // SurveyStatus,
      // TopQuestions,
    },
    props: {
      assessment_id: {
        type: String,
      },
      page_type: {
        type: String,
      },
      page_id: {
        type: String,
      },
    },
    data() {
      return {}
    },
    computed: {},
    methods: {},
    updated() {},
    mounted() {},
  }
</script>

<style lang="less">
@import (reference) '~@/less/variables.less';
</style>
