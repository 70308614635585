<template>
  <div class="compare-filter comparison-tool">
    <h3 ref="title" tabindex="1">Subset:</h3>
    <div v-if="subsets.length == 0">
      <p>No Options Available</p>
    </div>
    <template v-for="subset in subsets">
      <input
        :id="`Parent-${subset.questionId}`"
        :key="`Parent-${subset.questionId}`"
        type="checkbox"
        name="parents"
        class="styled"
        :value="subset.questionId"
        :checked="parentSelected(subset.questionId, subset.choices)"
        @click="toggleParent(subset.questionId, subset.choices)"
      />
      <label
        :for="'Parent-' + subset.questionId"
        :key="'Parent2-' + subset.questionId"
        class="styled"
      >
        <i class></i>
        <span>{{ subset.title }}</span>
      </label>
      <div
        class="subset-selection"
        v-show="inParents(subset.questionId)"
        :key="subset.questionId"
      >
        <template v-for="choice in subset.choices">
          <input
            @click="() => setSubset(subset.questionId, choice.id)"
            :id="`Choice-${subset.questionId}-${choice.id}`"
            :key="`Choice-${subset.questionId}-${choice.id}`"
            type="checkbox"
            name="subset-choice"
            class="styled"
            :value="`${subset.questionId}!!${choice.id}`"
            :checked="inSubset(choice.id)"
          />
          <label
            :for="'Choice-' + subset.questionId + '-' + choice.id"
            :key="'ChoiceKey-' + subset.questionId + '-' + choice.id"
            class="styled"
          >
            <i class></i>
            <span>{{ choice.title }}</span>
          </label>
        </template>
      </div>
    </template>
    <a href @click.prevent="submit" class="button" ref="submit">
      See Responses
    </a>
  </div>
</template>

<script>
  import _ from 'lodash'

  export default {
    name: 'Subset',
    props: ['column', 'subsets', 'submit', 'type'],
    data() {
      return {
        parents: [],
      }
    },
    methods: {
      inParents(parentId) {
        return _.find(this.parents, (_parent) => _parent === parentId)
      },
      setSubset(parentId, subsetRef) {
        this.column.toggleSubset({
          parent: parentId,
          subsetRef,
          type: this.type,
        })
      },

      inSubset(subsetId) {
        return _.find(
          this.column.subsets,
          (subset) => Number(subset.subsetRef) === Number(subsetId)
        )
      },

      parentSelected(id, choices) {
        return choices
          ? _.find(this.parents, (_parent) => _parent === id)
          : _.find(
              this.column.subsets,
              (subset) => Number(subset.subsetRef) === Number(id)
            )
      },

      toggleParent(id, choices) {
        if (choices) {
          // Student Section
          if (this.parentSelected(id, choices)) {
            this.parents.splice(this.parents.indexOf(id), 1)
            this.column.clearSubsetChildren(id)
          } else {
            this.parents.push(id)
          }
        } else {
          // Institutional Section
          this.column.toggleSubset({
            parent: null,
            subsetRef: id,
            type: this.type,
          })
        }
      },

      focusOnLoad() {
        this.$refs.title.focus()
      },
    },
    mounted() {
      this.parents = this.column.getParentIds()
      this.focusOnLoad()
    },
  }
</script>
