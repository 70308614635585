<template>
  <header class="page-header">
    <div class="page-header-items">
      <div class="page-header-left">
        <div class="logo">
          <a href="/">
            <img
              src="/img/Adult-Learner-360-Logo.svg"
              alt="Adult Learner 360"
            />
          </a>
        </div>

        <div class="page-title">
          <h2>AL360 Assessments</h2>
        </div>
      </div>
      <div class="page-header-right">
        <div v-if="loggedIn" class="account">
          <p>Hi, {{ user.friendlyName }}</p>
          <ul class="account-actions">
            <li>
              <router-link to="/">Dashboard</router-link>
            </li>
            <li>
              <a @click="logout()">Logout</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'Header',
    components: {},
    data() {
      return {}
    },
    computed: {},
    methods: {
      logout() {
        axios.get('/admin/logout').then(() => {
          // clear localstorage
          localStorage.removeItem('user')
          localStorage.removeItem('data')
          location.assign('/')

          // redirect to login
          // this.$router.push({ name: 'login' })
        })
      },
    },
    updated() {},
    mounted() {},
  }
</script>

<style lang="less">
  // @import (reference) '~@/less/variables.less';
</style>
