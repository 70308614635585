<template>
  <div class="page-container">
    <main class="page-content">
      <assessment-list></assessment-list>
    </main>
  </div>
</template>

<script>
  // import AboveMedian from '@/components/AboveMedian'
  // import Analyze from '@/components/Analyze'
  // import AssessmentDetails from '@/components/AssessmentDetails'
  import AssessmentList from '@/components/AssessmentList'
  // import AssessmentTable from '@/components/AssessmentTable'
  // import ClassificationDetails from '@/components/ClassificationDetails'
  // import Column from '@/components/Column'
  // import Contacts from '@/components/Contacts'
  // import DocumentLinks from '@/components/DocumentLinks'
  // import Filter from '@/components/Filter'
  // import Subset from '@/components/Subset'
  // import SurveyDetails from '@/components/SurveyDetails'
  // import SurveyStatus from '@/components/SurveyStatus'
  // import TopQuestions from '@/components/TopQuestions'

  export default {
    name: 'Dashboard',
    components: {
      // AboveMedian,
      // Analyze,
      // AssessmentDetails,
      AssessmentList,
      // AssessmentTable,
      // ClassificationDetails,
      // Column,
      // Contacts,
      // DocumentLinks,
      // Filter,
      // Subset,
      // SurveyDetails,
      // SurveyStatus,
      // TopQuestions,
    },
    data() {
      return {}
    },
    computed: {},
    methods: {},
    updated() {},
    mounted() {},
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';
</style>
