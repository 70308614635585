<template>
  <v-row class="contacts">
    <v-col cols="12">
      <h3>Contacts</h3>
      <p class="address" v-html="streetAddress"></p>
      <a :href="'//' + website" v-if="website">{{ website }}</a>
      <p class="primary-contact">
        <a
          :href="'mailto:' + school.primaryContact[0].emailAddress"
        >{{ school.primaryContact[0].contactName }}</a>
        &nbsp;|&nbsp;{{ school.primaryContact[0].contactTitle }}&nbsp;|&nbsp;
        {{ formatPhone(school.primaryContact[0].officePhone) }}
      </p>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'Contacts',
    components: {},
    props: {
      schoolId: {
        type: Number,
        default: -1,
      },
    },
    data() {
      return {}
    },
    computed: {
      school() {
        if (this.schoolId > 0 && this.data.schools.length > 0) {
          for (let school of this.data.schools) {
            if (school.id == this.schoolId) {
              return school
            }
          }
          return true
        } else return false
      },
      website() {
        let school = this.school
        if (school.websiteOverride) {
          return school.websiteOverride
        } else if (school.ipeds) {
          return school.ipeds[0].website ? school.ipeds[0].website : false
        } else {
          return false
        }
      },
      streetAddress() {
        let school = this.school
        var address
        if (school.addressOverride.length) {
          var override = school.addressOverride[0]
          address =
            override.streetAddress +
            '<br>' +
            override.city +
            ', ' +
            override.state +
            ' ' +
            override.zip
        } else {
          if (school.ipeds.length) {
            var addr = school.ipeds[0]
            address =
              addr.streetAddress +
              '<br>' +
              addr.city +
              ', ' +
              addr.state +
              ' ' +
              addr.zip
          } else {
            address = ''
          }
        }

        return address
      },
    },
    methods: {
      formatPhone(num) {
        if (num.length == 10) {
          var number =
            '(' +
            num.substring(0, 3) +
            ') ' +
            num.substring(3, 6) +
            '-' +
            num.substring(6, 10)
          return number
        } else {
          return num
        }
      },
    },
    updated() {},
    mounted() {},
  }
</script>

<style lang="less">
@import (reference) '~@/less/variables.less';

.contacts {
  margin-bottom: 1em;
  padding-bottom: 1em;
  border-bottom: solid @brand-gray 1px;

  p {
    margin: 0.5em 0;
  }
}
</style>
