<template>
  <div class="assessment-list">
    <v-text-field
      solo
      clearable
      placeholder="Filter Assessments"
      v-model="filterTerm"
    ></v-text-field>
    <v-expansion-panels
      multiple
      v-for="assessment in filteredAssessments"
      :key="assessment.id"
    >
      <assessment-details :assessmentData="assessment"></assessment-details>
    </v-expansion-panels>
  </div>
</template>

<script>
  import AssessmentDetails from '@/components/AssessmentDetails'

  export default {
    name: 'AssessmentList',
    components: {
      AssessmentDetails,
    },
    data: function () {
      return { filterTerm: '' }
    },
    computed: {
      filteredAssessments: function () {
        if (this.filterTerm && this.filterTerm.length > 0) {
          var term = this.filterTerm.toUpperCase()
          // If the search term is in the title, keep it in the list
          return this.data.assessments.filter(function (assessment) {
            let title = assessment.title.toUpperCase()
            return title.indexOf(term) != -1
          })
        }
        return this.data.assessments
      },
    },
    methods: {},
    updated() {},
    mounted() {},
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';

  .assessment-list {
    margin: 2em;
    width: auto;

    .v-input.v-text-field {
      max-width: 40em;
      .v-input__control {
        .v-input__slot {
          padding: 0;
          margin: 0;
          border: 1px solid #999999;
          box-shadow: inset 0 1px 3px 2px rgba(137, 137, 137, 0.5);
          border-radius: 8px;
          padding: 1em;

          input {
            border-width: 0;
            line-height: 2.5em;
            padding: 0;
          }
        }
      }
    }
  }
</style>
