// Used to pull in VueX store globally along with other computed elements

// VUEX STORE
import { mapGetters, mapState } from 'vuex'

// OTHER GLOBAL LIBRARIES
import axios from 'axios'
import vClickOutside from 'v-click-outside'

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  computed: {
    // Include Vuex getters globally
    ...mapGetters(['dataLoading', 'data', 'csrfToken', 'loggedIn']),
    ...mapState(['user', 'appState', 'calculated']),

    // Other global computed vars
    // set lodash to just underscore (_) instead of this._
    _() {
      return this._
    },
    defaultPrincipleId() {
      return this.data.principles[0].id
    },
    defaultStudentPrincipleId() {
      for (const principle of this.data.principles) {
        if (Object.keys(principle.studentQuestions).length > 0) {
          return principle.id
        }
      }
      return false
    },
    defaultInstitutionPrincipleId() {
      for (const principle of this.data.principles) {
        if (Object.keys(principle.institutionQuestions).length > 0) {
          return principle.id
        }
      }
    },
  },
  methods: {
    getAssessment(id) {
      for (const assessment of this.data.assessments) {
        if (assessment.id == id) return assessment
      }
      return false
    },
    getPrinciple(id) {
      for (const principle of this.data.principles) {
        if (principle.id == id) return principle
      }
      return false
    },
    getSurvey(assessment, page_type, split = false) {
      let surveyId = assessment[page_type + (split ? 'Sp' : '') + 'ExtId']
      let isAlchemer = assessment[page_type + 'AlcId'] ? true : false
      if (!surveyId) return

      return this.data.surveys[(isAlchemer ? 'a' : '') + surveyId] || null
    },
    getSurveyData(assessment, page_type, split = false) {
      let surveyId = assessment[page_type + (split ? 'Sp' : '') + 'ExtId']
      let isAlchemer = assessment[page_type + 'AlcId'] ? true : false
      if (!surveyId) return

      return new Promise((resolve, reject) => {
        // get survey
        axios
          .get(`/internal/surveys/${isAlchemer ? 'a' : ''}${surveyId}.json`)
          .then((response) => {
            resolve(response.data)
          })
          .catch((response) => {
            self.error = true
            reject(response)
          })
      })
    },
    getQuestion(id) {
      return this.data.questions[id] ? this.data.questions[id] : false
    },
    // Camel Case or sentence to Kebab Case
    // https://gist.github.com/nblackburn/875e6ff75bc8ce171c758bf75f304707#gistcomment-2588452
    toKebab(string) {
      if (!string) return ''
      if (string.trim().search(' ') > -1) {
        return string.replace(/\s+/g, '-').toLowerCase()
      } else {
        return string.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase()
      }
    },
    // Kebab Case or sentence to Camel Case
    // https://stackoverflow.com/questions/57556471/convert-kebab-case-to-camelcase-javascript
    toCamel(string) {
      if (!string) return ''
      if (string.trim().search(' ') > -1) {
        return string.replace(/\s+/g, (x) => x.toUpperCase()[1])
      } else {
        return string.replace(/-./g, (x) => x.toUpperCase()[1])
      }
    },
    // Convert kebeb or camel case to titlecase string
    toTitle(string) {
      if (!string) return ''
      if (string.trim().search('-') > -1) {
        return string
          .replace(/-/g, ' ')
          .toLowerCase()
          .split(' ')
          .map(function (word) {
            return word.charAt(0).toUpperCase() + word.slice(1)
          })
          .join(' ')
      } else {
        return string
          .replace(/([a-z0-9])([A-Z])/g, '$1 $2')
          .toLowerCase()
          .split(' ')
          .map(function (word) {
            return word.charAt(0).toUpperCase() + word.slice(1)
          })
          .join(' ')
      }
    },
    // strip all characters except alpha numeric
    onlyAlphaNumeric(string) {
      return string.replace(/[^0-9a-z]/gi, '')
    },
    // remove all html elements and strip all characters except alpha numeric
    cleanKey(string) {
      return string.replace(/<\/?[^>]+>/gi, ' ').replace(/[^0-9a-z]/gi, '')
    },
  },
  filters: {
    // remove all html elements
    stripHtml(string) {
      return string.replace(/<\/?[^>]+>/gi, ' ')
    },
  },
}
