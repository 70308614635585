import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'md',
  },
  theme: {
    themes: {
      light: {
        primary: '#D22630',
        secondary: '#00a94e',
        accent: '#1394d5',
        error: '#F15025',
        info: '#2196F3',
        success: '#85bf42',
        warning: '#E4A513',
        darkgray: '#444444',
      },
    },
  },
})
