import { v1 as uuidv1 } from 'uuid'
import _ from 'lodash'

export default class Column {
  constructor({ filter, subsets = [], empty = false }) {
    this.filter = filter
    this.subsets = subsets
    this.data = null
    this.empty = empty
    this.uuid = uuidv1()
  }

  getAssessmentId() {
    return this.filter.split('!!').length < 2
      ? null
      : this.filter.split('!!')[1]
  }

  setAssessmentId(id) {
    this.filter = `assessment!!${id}`
  }

  setData(data) {
    this.data = data
  }

  apiData() {
    return {
      filter: this.filter,
      subsets: this.subsets,
      signature: this.getSignature(),
    }
  }

  getSignature() {
    return JSON.stringify([this.filter, this.subsets])
  }

  getParentIds() {
    return _.map(this.subsets, 'parent')
  }

  setFilter(filter) {
    this.filter = filter
  }

  toggleSubset(subset) {
    const thisSubset = _.find(
      this.subsets,
      (_subset) => _subset.subsetRef === subset.subsetRef
    )
    if (thisSubset) this.subsets.splice(this.subsets.indexOf(thisSubset), 1)
    else this.subsets.push(subset)
  }

  removeEmpty() {
    this.empty = false
  }

  clearSubsetChildren(parentId) {
    this.subsets = _.filter(
      this.subsets,
      (subset) => subset.parent !== parentId
    )
  }
}
