<template>
  <v-row class="classification-details">
    <v-col cols="12">
      <h3>About</h3>

      <v-chip v-if="ipeds">{{ ipeds.institutionalSector }}</v-chip>
      <v-chip v-if="ipeds">IPEDS: {{ ipeds.ipedsNumber }}</v-chip>
      <v-chip v-for="acc in school.accreditations" :key="acc.id">
        {{ acc.title }}
      </v-chip>
      <v-chip v-for="minor in school.minorityRecognitions" :key="minor.id">
        {{ minor.title }}
      </v-chip>
      <template v-if="ipeds">
        <v-chip v-if="ipeds.historicallyBlackCu">
          Historically Black College/University
        </v-chip>
        <v-chip v-if="ipeds.tribalCollege">Tribal College/University</v-chip>
      </template>
      <v-chip v-for="system in systems" :key="system.id">
        System: {{ system.title }}
      </v-chip>
      <p
        v-if="
          !ipeds &&
          !school.accreditations.length &&
          !school.minorityRecognitions.length &&
          !systems.length
        "
      >
        No Classifications
      </p>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'ClassificationDetails',
    components: {},
    props: {
      schoolId: {
        type: Number,
        default: -1,
      },
      assessmentId: {
        type: Number,
        default: -1,
      },
    },
    data() {
      return {}
    },
    computed: {
      school() {
        if (this.schoolId > 0 && this.data.schools.length > 0) {
          for (let school of this.data.schools) {
            if (school.id == this.schoolId) {
              return school
            }
          }
          return true
        } else return false
      },
      ipeds() {
        if (this.school.ipeds.length) {
          return this.school.ipeds[0]
        } else {
          return false
        }
      },
      systems() {
        var systems = new Array()
        for (let system of this.data.systems) {
          for (let assessment of system.assessments) {
            if (assessment.id == this.assessmentId) {
              systems.push(system)
            }
          }
        }
        return systems
      },
    },
    methods: {},
    updated() {},
    mounted() {},
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';

  .classification-details {
    margin-bottom: 1em;
    padding-bottom: 1em;
    border-bottom: solid @brand-gray 1px;

    .v-chip.theme--light {
      background-color: @brand-green-pale;
      margin: 0.5em 0.5em 0.5em 0;

      .v-chip__content {
        overflow: hidden;
        white-space: nowrap;
        display: block;
        text-overflow: ellipsis;
        height: auto;
      }
    }
  }
</style>
