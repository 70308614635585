<template>
  <div class="survey-details">
    <v-row>
      <v-col cols="6" md="12" lg="6">
        <a
          v-if="!assessment.studentExtId"
          :href="
            '/admin/entries/assessments/' +
            assessment.id +
            '-' +
            assessment.slug
          "
          class="survey-link"
        >
          Add External ID
        </a>
        <p v-if="assessment.studentExtId" class="survey-id">
          {{ assessment.studentExtId
          }}{{
            assessment.studentSpExtId ? '-' + assessment.studentSpExtId : ''
          }}
          <br />
          <span class="response-count" v-if="assessment.studentExtId">
            {{
              getSurvey(assessment, 'student')
                ? getSurvey(assessment, 'student').responseCount + ' Responses'
                : 'No Data'
            }}
          </span>
        </p>
        <v-btn
          icon
          class="edit-field"
          :href="
            '/admin/entries/assessments/' +
            assessment.id +
            '-' +
            assessment.slug
          "
        >
          <v-icon>create</v-icon>
        </v-btn>
        <!--<v-select solo v-if="linkStudent"></v-select>
        <v-btn @click="linkStudent = false" v-if="linkStudent">Link</v-btn>-->
      </v-col>
      <v-col cols="6" md="12" lg="6">
        <a
          v-if="!assessment.institutionExtId"
          :href="
            '/admin/entries/assessments/' +
            assessment.id +
            '-' +
            assessment.slug
          "
          class="survey-link"
        >
          Add External ID
        </a>
        <p v-if="assessment.institutionExtId" class="survey-id">
          {{ assessment.institutionExtId }}
          <br />
          <span class="response-count" v-if="assessment.institutionExtId">
            {{
              getSurvey(assessment, 'institution')
                ? getSurvey(assessment, 'institution').responseCount +
                  ' Responses'
                : 'No Data'
            }}
          </span>
        </p>
        <v-btn
          icon
          class="edit-field"
          :href="
            '/admin/entries/assessments/' +
            assessment.id +
            '-' +
            assessment.slug
          "
        >
          <v-icon>create</v-icon>
        </v-btn>
        <!--<v-select solo v-if="linkInstitution"></v-select>
        <v-btn @click="linkInstitution = false" v-if="linkInstitution">Link</v-btn>-->
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" md="12" lg="6">
        <v-btn
          class="button primary"
          :to="
            '/assessment/' +
            assessment.id +
            '/analyze/student/' +
            defaultStudentPrincipleId
          "
          v-if="getSurvey(assessment, 'student')"
        >
          Analyze Student Data
        </v-btn>
      </v-col>
      <v-col cols="6" md="12" lg="6">
        <v-btn
          class="button primary"
          :to="
            '/assessment/' +
            assessment.id +
            '/analyze/institution/' +
            defaultInstitutionPrincipleId
          "
          v-if="getSurvey(assessment, 'institution')"
        >
          Analyze Institution Data
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'SurveyDetails',
    components: {},
    props: {
      assessment: {
        type: Object,
      },
    },
    data() {
      return {
        linkInstitution: false,
        linkStudent: false,
      }
    },
    computed: {},
  }
</script>

<style lang="less">
  .survey-details {
    .survey-link {
      font-size: 1.1428571em;
    }
    .survey-id {
      float: left;
      margin-bottom: 1.5em;
      font-weight: 600;

      p {
        margin: 0;
      }

      .response-count {
        font-weight: 400;
      }
    }

    .button.primary {
      height: auto;
      min-width: 0;
      padding: 1em 1.5em;
    }

    .v-input.v-text-field.v-select {
      clear: left;
      .v-input__control {
        .v-input__slot {
          padding: 0.5em;
        }
      }
    }
  }
</style>
