var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"button-group"},[_vm._l((_vm.data.principles),function(principle){return [(
        (principle.id != 428192 ||
          (principle.id == 428192 && _vm.appState.hasAdditionalQuestions)) &&
        ((_vm.page_type == 'student' && _vm.hasStudentQuestions(principle.id)) ||
          (_vm.page_type == 'institution' &&
            _vm.hasInstitutionQuestions(principle.id)))
      )?_c('li',{key:principle.id,staticClass:"button",class:_vm.page_id == principle.id ? 'selected' : ''},[_c('router-link',{attrs:{"to":`/assessment/${_vm.assessment_id}/analyze/${_vm.page_type}/${principle.id}`}},[_vm._v(" "+_vm._s(principle.title)+" ")])],1):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }