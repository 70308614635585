<template>
  <v-row class="document-links">
    <v-col cols="6" md="12" lg="6" class="column1">
      <a
        :href="assessment.presentationUrl"
        target="new"
        v-if="assessment.presentationUrl"
      >Access Presentation</a>
      <a
        :href="
          '/admin/entries/assessments/' + assessment.id + '-' + assessment.slug
        "
        v-if="!assessment.presentationUrl"
      >Add Presentation URL</a>

      <v-btn
        v-if="!editPresentation"
        icon
        class="edit-field"
        :href="
          '/admin/entries/assessments/' + assessment.id + '-' + assessment.slug
        "
      >
        <v-icon>create</v-icon>
      </v-btn>
      <!--<v-text-field
        solo
        type="url"
        placeholder="https://slides.google.com/..."
        v-if="editPresentation"
      ></v-text-field>
      <v-btn @click="editPresentation = false" v-if="editPresentation">Save</v-btn>-->
    </v-col>
    <v-col cols="6" md="12" lg="6" class="column2">
      <a :href="assessment.reportUrl" target="new" v-if="assessment.reportUrl">Access Report</a>
      <a
        :href="
          '/admin/entries/assessments/' + assessment.id + '-' + assessment.slug
        "
        v-if="!assessment.reportUrl"
      >Add Report URL</a>

      <v-btn
        v-if="!editReport"
        icon
        class="edit-field"
        :href="
          '/admin/entries/assessments/' + assessment.id + '-' + assessment.slug
        "
      >
        <v-icon>create</v-icon>
      </v-btn>
      <!--<v-text-field solo type="url" placeholder="https://docs.google.com/..." v-if="editReport"></v-text-field>
      <v-btn @click="editReport = false" v-if="editReport">Save</v-btn>-->
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'DocumentLinks',
    components: {},
    props: {
      assessment: {
        type: Object,
      },
    },
    data() {
      return {
        editPresentation: false,
        editReport: false,
      }
    },
  }
</script>

<style lang="less">
@import (reference) '~@/less/variables.less';

.document-links {
  font-size: 1.142857em;
  padding: 1em 0;
  margin-top: 1em;
  margin-bottom: 1em;
  border: solid @brand-gray 1px;
  border-width: 1px 0px;

  .v-input.v-text-field.v-text-field--single-line {
    .v-input__control {
      .v-input__slot {
        padding: 0.5em;
      }
    }
  }
}
</style>
