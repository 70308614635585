<template>
  <v-tabs background-color="transparent" class="top-questions" color="secondary">
    <v-tab>Top Challenges</v-tab>
    <v-tab>Top Strengths</v-tab>

    <v-tab-item>
      <v-simple-table v-if="challenges">
        <table>
          <thead>
            <tr>
              <th colspan="2"></th>
              <th>Student Satisfaction</th>
              <th>Median Comparison</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="challenge in challenges" :key="challenge[0]">
              <td>
                <img />
              </td>
              <td>
                <p>
                  <strong>
                    Q{{ getQuestion(challenge[0]).qNum }}:
                    {{ getQuestion(challenge[0]).principle }}
                  </strong>
                  <br />
                  {{ getQuestion(challenge[0]).title }}
                </p>
                <a
                  :href="getQuestion(challenge[0]).challengeUrl"
                  v-if="getQuestion(challenge[0]).challengeUrl"
                  target="content-library"
                >Recommended Approach</a>
              </td>
              <td>{{ challenge[1].toFixed(2) }}</td>
              <td>{{ data.studentQuestionsMedian[challenge[0]].toFixed(2) }}</td>
            </tr>
          </tbody>
        </table>
      </v-simple-table>
      <p v-if="!challenges">Top Challenges not available</p>
    </v-tab-item>

    <v-tab-item>
      <v-simple-table v-if="strengths">
        <table>
          <thead>
            <tr>
              <th colspan="2"></th>
              <th>Student Satisfaction</th>
              <th>Median Comparison</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="strength in strengths" :key="strength[0]">
              <td>
                <img />
              </td>
              <td>
                <p>
                  <strong>
                    Q{{ getQuestion(strength[0]).qNum }}:
                    {{ getQuestion(strength[0]).principle }}
                  </strong>
                  <br />
                  {{ getQuestion(strength[0]).title }}
                </p>
                <a
                  :href="getQuestion(strength[0]).strengthUrl"
                  v-if="getQuestion(strength[0]).strengthUrl"
                  target="content-library"
                >Recommended Reinforcement</a>
              </td>
              <td>{{ strength[1].toFixed(2) }}</td>
              <td>{{ data.studentQuestionsMedian[strength[0]].toFixed(2) }}</td>
            </tr>
          </tbody>
        </table>
      </v-simple-table>
      <p v-if="!strengths">Top Strengths not available</p>
    </v-tab-item>
  </v-tabs>
</template>

<script>
export default {
  name: 'TopQuestions',
  components: {},
  props: {
    studentSurvey: {
      type: Object,
    },
  },
  data() {
    return {}
  },
  computed: {
    challenges() {
      if (this.studentSurvey) {
        return this.sortChallenges(this.studentSurvey)
      } else {
        return false
      }
    },
    strengths() {
      if (this.studentSurvey) {
        return this.studentSurvey.topStrengths
      } else {
        return false
      }
    },
  },
  methods: {
    sortChallenges(survey) {
      if (survey) {
        var sorted = survey.topChallenges.sort(function (a, b) {
          return a[1] - b[1]
        })
        //console.log('sorted: ' + JSON.stringify(sorted))
        return sorted
      } else {
        return false
      }
    },
  },
}
</script>

<style lang="less">
@import (reference) '~@/less/variables.less';

.top-questions {
  table {
    tbody tr:nth-child(2n + 1) {
      background: none;
    }

    th {
      font-size: 0.857142em;
      line-height: 1.1em;
      padding: 0 0.5em;

      &:nth-child(2) {
        border-right: solid @brand-gray 1px;
      }
    }

    td {
      padding: 0.75em 0.5em;

      &:nth-child(3),
      &:nth-child(4) {
        text-align: center;
        font-weight: 600;
      }

      &:nth-child(3) {
        border-right: solid @brand-gray 1px;
      }

      p {
        margin-bottom: 0.5em;
      }

      a {
        font-size: 0.85714285em;
      }
    }
  }
}
</style>
