<template>
  <v-app>
    <div class="loading" v-if="dataLoading">
      <v-progress-circular indeterminate color="#F15025" size="100" width="14">
        <span>AL360</span>
      </v-progress-circular>
    </div>
    <div v-if="!dataLoading" class="page-container">
      <view-header></view-header>
      <v-main>
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </v-main>
      <view-footer></view-footer>
    </div>
  </v-app>
</template>

<script>
  // VIEWS
  import ViewHeader from '@/components/ViewHeader'
  import ViewFooter from '@/components/ViewFooter'
  import Dashboard from '@/views/Dashboard'
  import axios from 'axios'

  // COMPONENTS
  // import Navigation from '@/components/Navigation.vue'
  // import TopBkg from '@/components/TopBkg'

  export default {
    name: 'App',
    views: {
      Dashboard,
    },
    components: { ViewHeader, ViewFooter },

    data() {
      return {}
    },

    computed: {},
    methods: {
      scrollContent() {
        let elem = document.getElementById('list-wrap')
        //console.log('scroll element: ' + elem)
        if (elem) elem.scrollTo(0, 0)
      },
    },
    watch: {
      // do things after route changes
      $route(to, from) {
        // console.log('to: ' + to + ' from: ' + from)
      },
    },
    mounted() {
      //console.log('app mounted')
      // // See if user is logged in and load credentials if so
      // this.$store.dispatch('checkAuth').then(() => {
      //   ////console.log('user: ' + JSON.stringify(this.user))
      // })
      // If deep linking, set cap path and active domain
      // this.setlastCapabilityPath()
      // this.setActiveDomain()
      // this.fadeCopy()
    },
    updated() {},
  }
</script>

<style lang="less">
  @import 'less/variables.less';

  /* Colors */
  /* Sizes */
  /* line 1, _reset.scss */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  /* line 7, _reset.scss */
  a {
    text-decoration: none;
  }

  /* line 1, _global.scss */
  a {
    color: #00a94e;
    text-decoration: underline;
  }

  /* line 6, _global.scss */
  h2 {
    font-size: 24px;
  }

  /* line 10, _global.scss */
  h3 {
    font-size: 18px;
  }

  /* line 14, _global.scss */
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  /* line 20, _global.scss */
  table {
    border-collapse: collapse;
    width: 100%;
  }
  /* line 25, _global.scss */
  table thead td,
  table thead th {
    font-size: 18px;
    font-weight: bold;
  }
  /* line 33, _global.scss */
  table tbody tr:nth-child(2n + 1) {
    background: rgba(241, 239, 239, 0.98);
  }
  /* line 36, _global.scss */
  table tbody tr.bumper {
    background-color: white;
  }
  /* line 42, _global.scss */
  table td,
  table tr {
    border-box: box-sizing;
  }

  /* line 47, _global.scss */
  .error,
  .errors {
    color: red;
    font-size: 12px;
    font-weight: normal;
  }

  /* line 53, _global.scss */
  .errors {
    margin-top: 10px;
  }
  /* line 56, _global.scss */
  .errors li {
    margin-bottom: 5px;
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  /* Layout and sections */
  /* Colors */
  /* line 3, _layout.scss */
  body {
    background: white;
    font-family: Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans,
      Tahoma, sans-serif;
    color: @text-color;
  }

  /* line 8, _layout.scss */
  input {
    border: 1px solid #979797;
  }

  /* line 12, _layout.scss */
  input[type='text'],
  input[type='password'],
  input[type='tel'],
  input[type='email'],
  input[type='number'] {
    border: 1px solid #979797;
    border-radius: 5px;
    font-size: 18px;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
  }

  /* line 21, _layout.scss */
  .clear {
    clear: both;
  }

  /* Lifted directly from CAEL */
  @font-face {
    font-family: 'Trebuchet MS';
    src: url('assets/fonts/Trebuchet_MS.ttf');
    /* IE9 Compat Modes */
    src: url('assets/fonts/Trebuchet_MS.ttf') format('embedded-opentype'),
      url('assets/fonts/Trebuchet_MS.ttf') format('truetype');
  }
  /* line 1, _header.scss */
  .page-header {
    background: #00a94e;
    color: white;
    padding: 26px 34px 3px 34px;
  }
  /* line 8, _header.scss */
  .page-header a {
    color: white;
  }

  /* line 13, _header.scss */
  .page-header-items {
    display: -ms-flexbox;
    display: flex;
  }

  /* line 17, _header.scss */
  .page-header-left {
    min-width: 41%;
    margin-top: 31px;
  }

  /* line 22, _header.scss */
  .leftOnly .page-header-left {
    min-width: 100%;
  }

  /* line 26, _header.scss */
  .page-header-right {
    margin-top: 2px;
  }

  /* line 30, _header.scss */
  .page-header-left,
  .page-header-middle,
  .page-header-right {
    -ms-flex: 1;
    flex: 1;
  }

  /* line 36, _header.scss */
  .page-header-image {
    width: 370px;
    height: 203px;
    -ms-flex: 0;
    flex: 0;
    margin: 0 auto;
  }
  /* line 42, _header.scss */
  .page-header-image img {
    max-width: 100%;
  }

  /* line 47, _header.scss */
  .logo {
    font-weight: bold;
    min-width: 400px;
    margin-bottom: 45px;
    display: -ms-flexbox;
    display: flex;
  }
  /* line 53, _header.scss */
  .logo a {
    text-decoration: none;
  }
  /* line 57, _header.scss */
  .logo img {
    max-height: 137px;
  }

  /* line 61, _header.scss */
  .logo.logged-in img {
    max-height: 95px;
    max-width: 272.5px;
  }

  /* line 66, _header.scss */
  .page-header-right {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  /* line 71, _header.scss */
  .page-header-right .account {
    width: 150px;
  }
  /* line 74, _header.scss */
  .page-header-right .account a {
    text-decoration: none;
    box-shadow: inset 0 0px 0 white, inset 0 -2px 0 white;
    color: white;
  }

  /* line 81, _header.scss */
  .account {
    height: 162px;
    font-size: 16px;
    font-weight: bold;
  }

  /* line 87, _header.scss */
  .actions {
    font-size: 18px;
    text-align: right;
  }
  /* line 91, _header.scss */
  .actions .see-all-institutions {
    display: inline-block;
    padding-right: 38px;
    background-image: url('/assets/images/see-all-institutions.svg');
    background-repeat: no-repeat;
    background-position: right;
    background-size: 23px 23px;
  }
  /* line 100, _header.scss */
  .actions .see-all-institutions.open {
    background-image: url('/assets/images/close-white.svg');
  }

  /* line 106, _header.scss */
  .mobile-actions {
    display: none;
    font-size: 16px;
    margin: 0;
    text-align: center;
  }

  /* line 113, _header.scss */
  .account {
    p {
      margin-bottom: 0;
    }

    ul.account-actions {
      text-transform: uppercase;
      padding-left: 0;
    }
  }
  /* line 116, _header.scss */
  .account-actions li {
    display: inline-block;
    text-decoration: underline;
    border-right: 1px solid white;
    margin-right: 10px;
    padding-right: 10px;
    font-size: 12px;
  }
  /* line 125, _header.scss */
  .account-actions li:last-child {
    padding-right: 0;
    margin-right: 0;
    border-right: none;
  }

  /* line 133, _header.scss */
  .institutions {
    margin-top: -9px;
    margin-bottom: 100px;
    transition-duration: 0.3s;
    max-height: 0;
    overflow: hidden;
  }
  /* line 140, _header.scss */
  .institutions.show {
    display: block;
  }
  /* line 144, _header.scss */
  .institutions table {
    width: 100%;
    border-collapse: collapse;
  }
  /* line 150, _header.scss */
  .institutions table tr:nth-child(2n) td {
    padding: 13px 10px;
  }
  /* line 157, _header.scss */
  .institutions table thead td {
    padding-bottom: 30px;
  }
  /* line 162, _header.scss */
  .institutions table td {
    padding: 8px 10px 7px 10px;
  }
  /* line 165, _header.scss */
  .institutions table td a {
    text-decoration: underline;
  }
  /* line 170, _header.scss */
  .institutions table td:nth-child(3n + 1) {
    width: 40%;
  }
  /* line 174, _header.scss */
  .institutions table td:nth-child(3n + 2) {
    width: 30%;
  }
  /* line 178, _header.scss */
  .institutions table thead {
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 15px;
  }
  /* line 185, _header.scss */
  .institutions table tbody tr {
    background: transparent;
  }
  /* line 189, _header.scss */
  .institutions table tbody tr:nth-child(2n + 1) {
    background: #00853d;
  }

  /* line 197, _header.scss */
  .dashboard-title .page-title {
    margin-top: 0px;
  }
  /* line 200, _header.scss */
  .dashboard-title .page-title h2 {
    font-size: 28px;
  }

  /* line 206, _header.scss */
  .assess-title {
    font-size: 12px;
  }

  /* line 210, _header.scss */
  .page-title {
    margin-bottom: 34px;
    margin-top: 43px;
  }
  /* line 214, _header.scss */
  .page-title h2 {
    font-size: 24px;
  }
  /* line 218, _header.scss */
  .page-title p {
    margin-top: 7px;
    font-size: 18px;
  }
  /* line 222, _header.scss */
  .page-title p a {
    text-decoration: none;
    box-shadow: inset 0 0px 0 white, inset 0 -2px 0 white;
  }

  @media only screen and (max-width: 1024px) {
    /* line 231, _header.scss */
    body .page-container .page-header {
      padding: 5px 10px 34px 10px;
      min-height: 0;
    }
    /* line 236, _header.scss */
    body .page-container .page-header-left {
      margin-top: 0;
    }
    /* line 240, _header.scss */
    body .page-container .page-header-left .page-title h2 {
      font-size: 18px;
    }
    /* line 244, _header.scss */
    body .page-container .page-header-left .page-title p {
      margin-top: 0;
      font-size: 16px;
    }
    /* line 252, _header.scss */
    body .page-container .logo {
      display: block;
      min-width: 0;
      margin-bottom: 0;
      height: 104px;
    }
    /* line 258, _header.scss */
    body .page-container .logo h1 {
      padding: 0;
      font-size: 35px;
      border: none;
      margin: none;
      line-height: initial;
      display: block;
      max-height: none;
    }
    /* line 267, _header.scss */
    body .page-container .logo h1 a {
      text-decoration: none;
    }
    /* line 272, _header.scss */
    body .page-container .logo p {
      font-size: 16px;
      margin: 0;
      padding: 0;
      border-left: none;
      width: auto;
      display: block;
      max-width: none;
    }
    /* line 283, _header.scss */
    body .page-container h2 {
      font-size: 18px;
    }
    /* line 288, _header.scss */
    body .page-container .page-header-left p {
      max-width: 200px;
      margin-top: -2px;
    }
    /* line 294, _header.scss */
    body .page-container .page-header-middle {
      display: none;
    }
    /* line 298, _header.scss */
    body .page-container .page-header-right {
      min-width: 70px;
      -ms-flex: 0;
      flex: 0;
    }
    /* line 302, _header.scss */
    body .page-container .page-header-right .actions {
      display: none;
    }
    /* line 306, _header.scss */
    body .page-container .page-header-right .account {
      margin-top: 3px;
      text-align: left;
      width: auto;
    }
    /* line 312, _header.scss */
    body .page-container .page-header-right .account-actions {
      margin-top: 7px;
    }
    /* line 315, _header.scss */
    body .page-container .page-header-right .account-actions li {
      display: block;
      border: none;
      font-weight: bold;
      margin: 0 0 5px 0;
      padding: 0;
    }
    /* line 325, _header.scss */
    body .page-container .actions.mobile-actions {
      display: block;
      margin-bottom: 0px;
      float: none;
      margin-top: 3px;
    }
    /* line 331, _header.scss */
    body .page-container .actions.mobile-actions .see-all-institutions {
      display: inline-block;
    }
    /* line 336, _header.scss */
    body .page-container .institutions {
      margin: 0;
    }
    /* line 339, _header.scss */
    body .page-container .institutions.show {
      padding-bottom: 14px;
      margin: 57px 0 0 0;
    }
    /* line 344, _header.scss */
    body .page-container .institutions:before {
      content: 'Institution';
      text-transform: uppercase;
      font-weight: bold;
      margin-left: 10px;
    }
    /* line 351, _header.scss */
    body .page-container .institutions table {
      margin-top: 30px;
      margin-bottom: 100px;
    }
    /* line 355, _header.scss */
    body .page-container .institutions table thead {
      display: none;
    }
    /* line 360, _header.scss */
    body .page-container .institutions table tbody tr,
    body .page-container .institutions table tbody td {
      display: block;
    }
    /* line 364, _header.scss */
    body .page-container .institutions table tbody tr {
      margin-bottom: 28px;
    }
    /* line 367, _header.scss */
    body .page-container .institutions table tbody tr:nth-child(2n + 1) {
      background: transparent;
    }
    /* line 371, _header.scss */
    body .page-container .institutions table tbody tr td {
      width: 100%;
      font-weight: bold;
      padding: 8px 20px 7px 20px;
    }
    /* line 376, _header.scss */
    body .page-container .institutions table tbody tr td a {
      text-decoration: none;
      box-shadow: inset 0 0px 0 white, inset 0 -2px 0 white;
    }
    /* line 382, _header.scss */
    body .page-container .institutions table tbody tr td:first-child {
      background: #00853d;
      font-size: 18px;
      font-weight: bold;
      padding-bottom: 5px;
    }
    /* line 389, _header.scss */
    body .page-container .institutions table tbody tr td:nth-child(2n),
    body .page-container .institutions table tbody tr td:nth-child(3n) {
      padding-top: 14px;
      padding-bottom: 7px;
    }
    /* line 391, _header.scss */
    body .page-container .institutions table tbody tr td:nth-child(2n):before,
    body .page-container .institutions table tbody tr td:nth-child(3n):before {
      text-transform: uppercase;
    }
    /* line 400, _header.scss */
    body .page-container .institutions table tbody tr td:nth-child(2n):before {
      content: 'Status: ';
    }
    /* line 406, _header.scss */
    body .page-container .institutions table tbody tr td:nth-child(3n):before {
      content: 'Special Notes: ';
    }
  }
  /* Colors */
  /* Sizes */

  /* Components */
  /* line 3, components/_form.scss */
  input.styled {
    visibility: hidden;
    position: absolute;
  }

  /* line 8, components/_form.scss */
  label.styled {
    display: block;
    cursor: pointer;
  }
  /* line 12, components/_form.scss */
  label.styled * {
    opacity: 0;
  }

  /* line 18, components/_form.scss */
  input.styled:checked + label * {
    opacity: 1;
  }

  /* line 23, components/_form.scss */
  .selectric {
    background: white;
    font-size: 18px;
    border-radius: 8px;
    border: 1px solid #979797;
    padding: 10px;
    position: relative;
  }
  /* line 32, components/_form.scss */
  .selectric.placeholder .label {
    color: #999;
  }
  /* line 37, components/_form.scss */
  .selectric:after {
    content: '';
    background-image: url('/assets/images/arrow_down.svg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 24px 14px;
    border-left: 1px solid #979797;
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
  }
  /* line 51, components/_form.scss */
  .selectric .label {
    font-size: 18px;
  }
  /* line 55, components/_form.scss */
  .selectric .button {
    display: none;
    background: transparent;
    box-shadow: none;
    padding: 0;
  }

  /* line 64, components/_form.scss */
  .selectric-hover .selectric,
  .selectric-focus .selectric {
    border: 1px solid #979797;
  }

  /* line 70, components/_form.scss */
  .selectric-above .selectric-items {
    padding: 0 0 59px 0;
    margin: 0 0 -60px 0;
    border-radius: 0 0 8px 8px;
  }

  /* line 77, components/_form.scss */
  .selectric-items {
    padding: 59px 0 0 0;
    margin: -60px 0 0 0;
    border-radius: 8px 8px 0 0;
    border: 1px solid #979797;
  }
  /* line 83, components/_form.scss */
  .selectric-items li:first-child {
    text-indent: -9999px;
  }
  /* line 87, components/_form.scss */
  .selectric-items li {
    padding: 12px 10px;
  }
  /* line 91, components/_form.scss */
  .selectric-items .selectric-scroll {
    background: white;
  }
  /* line 95, components/_form.scss */
  .selectric-items ul,
  .selectric-items li {
    font-size: 18px;
  }

  /* line 101, components/_form.scss */
  input[type='checkbox'].styled:checked + label i {
    background-image: url('/assets/images/analyze/checkbox_checked.svg');
  }

  /* line 107, components/_form.scss */
  input.radio.styled + label i {
    margin-right: 6px;
    border-radius: 19px;
    min-width: 19px;
    height: 19px;
    background-image: none;
  }

  /* line 117, components/_form.scss */
  input.radio.styled:checked + label i {
    background: transparent;
    border: 1px solid transparent;
    background-image: url('/assets/images/checkmark_green.svg');
    background-size: 21px;
    background-position: -1px -1px;
  }

  /* line 127, components/_form.scss */
  label span {
    font-weight: bold;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
  }
  /* line 132, components/_form.scss */
  label i {
    margin: 0 20px 0 0;
    background-repeat: no-repeat;
    background-position: 50%;
    min-width: 25px;
    height: 25px;
    display: block;
    border: 1px solid #00a94e;
    background: white;
  }

  /* line 1, components/_buttons.scss */

  .v-application a.button,
  .button,
  input[type='submit'] {
    display: inline-block;
    text-decoration: none;
    text-align: center;
    background: @brand-red;
    font-size: 16px;
    color: white;
    font-weight: bold;
    font-family: Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans,
      Tahoma, sans-serif;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    border: none;
    padding: 1.5em 2em;
    box-sizing: border-box;
    text-transform: none;
    height: auto;
    min-width: 0;
  }

  .v-application {
    button,
    select,
    input,
    textarea {
      background: white;
    }
    select {
      -webkit-appearance: revert;
      -moz-appearance: revert;
      background-color: white;
      border: 1px solid #00a94e;
      border-radius: 5px;
      padding: 5px 0;
    }

    label + select {
      margin-top: -1em;
      margin-bottom: 1em;
      max-width: 25em;
    }
  }

  /* line 22, components/_buttons.scss */
  .button.small,
  button.small,
  input[type='submit'].small {
    padding: 13px;
    width: 190px;
  }
  /* line 27, components/_buttons.scss */
  .button.large,
  button.large,
  input[type='submit'].large {
    padding: 29px;
    width: 250px;
    text-transform: uppercase;
  }

  /* line 3, components/_progressBar.scss */
  .progress-bar {
    position: relative;
    height: 35px;
    width: 100%;
    margin: 20px 0 0 0;
  }
  /* line 9, components/_progressBar.scss */
  .progress-bar.has-target {
    margin-bottom: 20px;
  }
  /* line 13, components/_progressBar.scss */
  .progress-bar label {
    font-size: 14px;
    position: absolute;
    color: #343131;
    right: 15px;
    line-height: 35px;
    z-index: 10;
  }

  /* line 23, components/_progressBar.scss */
  .progress-bar-container {
    background: white;
    overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 8px;
  }

  /* line 32, components/_progressBar.scss */
  .progress-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.5);
    z-index: 2;
  }

  /* line 42, components/_progressBar.scss */
  .progress-target {
    background: #d8d8d8;
    position: absolute;
    height: 66px;
    top: -15.5px;
    width: 2px;
    z-index: 3;
  }
  /* line 51, components/_progressBar.scss */
  .progress-target label {
    position: absolute;
    bottom: -35px;
    width: 200px;
    text-align: center;
    left: -100px;
    color: white;
  }

  /* line 61, components/_progressBar.scss */
  .progress-complete,
  .progress-target-background {
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  /* line 68, components/_progressBar.scss */
  .progress-target-background {
    background: #d8d8d8;
  }

  /* line 72, components/_progressBar.scss */
  .progress-complete {
    background: #ffb320;
  }

  /* line 1, components/_modal.scss */
  .modal-content {
    display: none;
  }

  /* line 5, components/_modal.scss */
  .jquery-modal {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 10;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
  }
  /* line 18, components/_modal.scss */
  .jquery-modal .modal-content {
    position: fixed;
    background: white;
    margin: 0 auto;
    width: 1020px;
    padding: 80px 107px 60px 107px;
    border-radius: 8px;
    position: relative;
    text-align: center;
  }
  /* line 29, components/_modal.scss */
  .jquery-modal .close-modal {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    color: #d2252f;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    text-decoration: underline;
  }
  /* line 40, components/_modal.scss */
  .jquery-modal .close-modal:after {
    content: ' X';
  }
  /* line 45, components/_modal.scss */
  .jquery-modal .title {
    font-size: 24px;
    color: #00a94e;
    text-transform: uppercase;
    margin-bottom: 40px;
  }
  /* line 52, components/_modal.scss */
  .jquery-modal p {
    font-size: 24px;
    margin-bottom: 40px;
  }
  /* line 57, components/_modal.scss */
  .jquery-modal .buttons {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
  }
  /* line 61, components/_modal.scss */
  .jquery-modal .buttons .button {
    margin-right: 30px;
  }
  /* line 64, components/_modal.scss */
  .jquery-modal .buttons .button:last-child {
    margin-right: 0;
  }

  @media only screen and (max-width: 1024px) {
    /* line 72, components/_modal.scss */
    .jquery-modal .modal-content {
      padding: 40px;
      box-sizing: border-box;
      width: auto;
      margin: 10px;
    }
    /* line 78, components/_modal.scss */
    .jquery-modal .modal-content .title {
      font-size: 18px;
    }
    /* line 82, components/_modal.scss */
    .jquery-modal .modal-content p {
      font-size: 18px;
    }
    /* line 86, components/_modal.scss */
    .jquery-modal .modal-content .buttons {
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
    }
    /* line 89, components/_modal.scss */
    .jquery-modal .modal-content .buttons .button {
      margin: 10px 0;
      width: 275px;
      display: block;
    }
  }
  /* line 1, components/_button_group.scss */
  .button-group {
    border: 1px solid #979797;
    border-radius: 8px;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    margin-bottom: 30px;
    padding-left: 0 !important;
  }
  /* line 8, components/_button_group.scss */
  .button-group .button {
    margin: 0;
    padding: 0;
    border-radius: 0;
    background: inherit;
    border: none;
    box-shadow: none;
    color: inherit;
    -ms-flex: 1;
    flex: 1;
    border-right: 1px solid #979797;
    font-size: 14px;
    cursor: pointer;
  }
  /* line 21, components/_button_group.scss */
  .button-group .button a,
  .button-group .button label {
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    text-transform: initial;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    min-height: 75px;
    height: 100%;
    padding: 15px;
    color: inherit;
    text-decoration: none;
  }
  /* line 35, components/_button_group.scss */
  .button-group .button label.styled * {
    opacity: 1;
  }
  /* line 39, components/_button_group.scss */
  .button-group .button:last-child {
    border: none;
  }
  /* line 43, components/_button_group.scss */
  .button-group .button.selected,
  .button-group .button:hover,
  .button-group .button input.styled:checked + label {
    background: #d2252f;
    color: white;
    border-right: #d2252f;
  }

  @media only screen and (max-width: 1024px) {
    /* line 55, components/_button_group.scss */
    .button-group {
      -ms-flex-direction: column;
      flex-direction: column;
      margin-bottom: 20px;
    }
    /* line 59, components/_button_group.scss */
    .button-group .button {
      border-right: none;
      border-bottom: 1px solid #979797;
      text-align: center;
      width: auto;
    }
    /* line 65, components/_button_group.scss */
    .button-group .button:last-child {
      border: none;
    }
  }
  /* line 3, components/_comparison.scss */
  .comparison-tool {
    font-size: 14px;
    display: -ms-flexbox;
    display: flex;
    padding: 30px 0;
    box-sizing: border-box;
    background: rgba(241, 239, 239, 0.98);
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  /* line 12, components/_comparison.scss */
  .comparison-tool .title {
    margin: 0;
    display: -ms-flexbox;
    display: flex;
    font-size: 14px;
    text-transform: uppercase;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: right 50%;
    padding-right: 35px;
    background-image: url('/assets/images/analyze/title_arrow.svg');
  }
  /* line 25, components/_comparison.scss */
  .comparison-tool .comparisons {
    height: 100%;
    padding-left: 60px;
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
  }
  /* line 32, components/_comparison.scss */
  .comparison-tool .comparisons input[type='text'] {
    padding: 10px;
    margin-bottom: 40px;
    -ms-flex: 0;
    flex: 0;
  }
  /* line 38, components/_comparison.scss */
  .comparison-tool
    .comparisons
    input.styled:checked
    + label
    + .selectric-wrapper {
    display: block;
  }
  /* line 42, components/_comparison.scss */
  .comparison-tool .comparisons label + .selectric-wrapper {
    margin-top: 5px;
    display: none;
  }
  /* line 48, components/_comparison.scss */
  .comparison-tool .comparison-rows {
    margin-left: 70px;
    -ms-flex: 1;
    flex: 1;
  }
  /* line 53, components/_comparison.scss */
  .comparison-tool .row {
    margin-right: 20px;
    clear: both;
  }
  /* line 58, components/_comparison.scss */
  .comparison-tool .comparison-table {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  /* line 63, components/_comparison.scss */
  .comparison-tool .comparison-table .comparison {
    padding-right: 20px;
    padding-bottom: 10px;
    box-sizing: border-box;
    -ms-flex: 0 1 33.333%;
    flex: 0 1 33.333%;
  }
  /* line 70, components/_comparison.scss */
  .comparison-tool .comparison-table .comparison label i {
    margin-right: 10px;
  }
  /* line 75, components/_comparison.scss */
  .comparison-tool .comparison-table .comparison:last-child {
    margin-bottom: 0;
  }
  /* line 82, components/_comparison.scss */
  .comparison-tool label {
    display: -ms-flexbox;
    display: flex;
    line-height: 25px;
  }
  /* line 86, components/_comparison.scss */
  .comparison-tool label * {
    opacity: 1;
  }
  /* line 91, components/_comparison.scss */
  .comparison-tool .subsets-container {
    -ms-flex: 1 1 23%;
    flex: 1 1 23%;
    width: 23%;
    margin-left: 20px;
    padding: 0 60px 20px 50px;
    box-sizing: border-box;
    border-left: 1px solid #979797;
  }
  /* line 100, components/_comparison.scss */
  .comparison-tool .subsets-scroll {
    -ms-flex: 1;
    flex: 1;
    overflow-x: none;
    overflow-y: scroll;
    height: 100%;
  }
  /* line 107, components/_comparison.scss */
  .comparison-tool .subset {
    padding-right: 20px;
    padding-bottom: 7px;
    box-sizing: border-box;
  }
  /* line 112, components/_comparison.scss */
  .comparison-tool .subset input.styled:checked + label + .selectric-wrapper {
    display: block;
  }
  /* line 116, components/_comparison.scss */
  .comparison-tool .subset .selectric-wrapper {
    display: none;
  }

  /* line 122, components/_comparison.scss */
  .comparison-title {
    padding-bottom: 20px;
    font-weight: bold;
    text-transform: uppercase;
  }

  /* line 128, components/_comparison.scss */
  .comparison-open {
    min-width: 275px;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  /* line 133, components/_comparison.scss */
  .comparison-data {
    display: none;
    width: 100%;
    margin-top: 45px;
  }
  /* line 138, components/_comparison.scss */
  .comparison-data .comparisons {
    display: block;
  }
  /* line 141, components/_comparison.scss */
  .comparison-data .comparisons .comparison-rows {
    margin-left: 0;
  }

  /* line 147, components/_comparison.scss */
  .comparison .comparison-tool .comparisons {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    padding-right: 60px;
  }

  /* line 152, components/_comparison.scss */
  .responses-section.responses-section {
    margin-top: 35px;
  }
  /* line 155, components/_comparison.scss */
  .responses-section.responses-section h2 {
    text-align: left;
  }
  /* line 158, components/_comparison.scss */
  .responses-section.responses-section hr {
    margin: 25px 0;
  }

  /* line 163, components/_comparison.scss */
  .responses-body.responses-body p {
    display: block;
    margin-bottom: 0;
    font-size: 14px;
    line-height: normal;
  }
  /* line 169, components/_comparison.scss */
  .responses-body.responses-body .button {
    width: 190px;
    padding: 13px;
    margin: 12px 0 30px;
    font-size: 13px;
  }

  /* Pages */
  /* line 1, pages/_404.scss */
  .fourohfour {
    padding-top: 60px;
    font-size: 18px;
  }
  /* line 5, pages/_404.scss */
  .fourohfour .page-left,
  .fourohfour .page-right {
    -ms-flex: 1;
    flex: 1;
  }
  /* line 9, pages/_404.scss */
  .fourohfour h2 {
    font-size: 65px;
    color: #d2252f;
    text-transform: uppercase;
    margin-bottom: 40px;
  }
  /* line 16, pages/_404.scss */
  .fourohfour h3 {
    font-size: 24px;
    color: #343131;
  }
  /* line 21, pages/_404.scss */
  .fourohfour h4 {
    font-size: 18px;
    margin-bottom: 5px;
    margin-top: 40px;
  }
  /* line 27, pages/_404.scss */
  .fourohfour .page-left {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    border-right: 1px solid #979797;
    text-align: right;
    padding: 100px 80px 100px 0;
  }
  /* line 34, pages/_404.scss */
  .fourohfour .page-left .text-container {
    max-width: 465px;
  }
  /* line 39, pages/_404.scss */
  .fourohfour .page-right {
    padding: 80px 0 0 80px;
    line-height: 30px;
  }

  @media only screen and (max-width: 1024px) {
    /* line 46, pages/_404.scss */
    .page.fourohfour {
      margin: 0 10px;
      padding-top: 45px;
    }
    /* line 50, pages/_404.scss */
    .page.fourohfour h2 {
      font-size: 45px;
    }
    /* line 54, pages/_404.scss */
    .page.fourohfour h3 {
      font-size: 18px;
      margin: 0;
    }
    /* line 59, pages/_404.scss */
    .page.fourohfour h4 {
      margin-top: 30px;
      margin-bottom: 15px;
    }
    /* line 64, pages/_404.scss */
    .page.fourohfour p {
      margin-bottom: 7px;
    }
    /* line 68, pages/_404.scss */
    .page.fourohfour .page-left {
      border-bottom: 1px solid #979797;
      padding-bottom: 30px;
      margin: 0 10px 30px 10px;
    }
    /* line 73, pages/_404.scss */
    .page.fourohfour .page-left h3 {
      max-width: 319px;
    }
  }
  /* line 2, pages/_home.scss */
  .page-container .home {
    background: #00a94e;
    margin: 0 0 -100px 0;
    padding: 0 82px 50px 114px;
    color: white;
    min-height: 200px;
  }
  /* line 9, pages/_home.scss */
  .page-container .home h2 {
    font-size: 48px;
    text-transform: uppercase;
    margin-bottom: 19px;
  }
  /* line 15, pages/_home.scss */
  .page-container .home h3 {
    font-size: 28px;
    margin-bottom: 30px;
  }
  /* line 20, pages/_home.scss */
  .page-container .home p {
    line-height: 35px;
    font-size: 16px;
    margin: 0;
  }
  /* line 26, pages/_home.scss */
  .page-container .home .button {
    background: white;
    color: #d2252f;
    width: 275px;
  }
  /* line 32, pages/_home.scss */
  .page-container .home input {
    border: none;
    padding: 28px 20px;
    margin-top: 30px;
    font-size: 16px;
  }
  /* line 39, pages/_home.scss */
  .page-container .home input[type='submit'] {
    text-transform: uppercase;
    width: 225px;
    padding: 13px 20px;
    margin-bottom: 16px;
  }
  /* line 46, pages/_home.scss */
  .page-container .home .image {
    margin: 0 auto;
    padding: 20px 0 137px 0;
    width: 513px;
  }
  /* line 51, pages/_home.scss */
  .page-container .home .image img {
    max-width: 100%;
  }
  /* line 57, pages/_home.scss */
  .page-container .home .page-content-inner:after {
    content: '';
    display: table;
    clear: both;
  }
  /* line 64, pages/_home.scss */
  .page-container .home .login {
    padding-left: 48.5px;
    margin-left: 48.5px;
    float: right;
    width: 381px;
    border-left: 1px solid white;
    min-height: 461px;
  }
  /* line 72, pages/_home.scss */
  .page-container .home .login h3 {
    margin-bottom: 20px;
  }
  /* line 76, pages/_home.scss */
  .page-container .home .login p {
    line-height: 28px;
  }
  /* line 81, pages/_home.scss */
  .page-container .home #password-reset-form {
    display: none;
  }

  /* line 87, pages/_home.scss */
  .actions {
    float: right;
  }

  /* line 91, pages/_home.scss */
  .post-login {
    display: block;
    color: white;
    text-align: center;
  }

  /* line 97, pages/_home.scss */
  label.styled[for='show-password'] {
    color: white;
    display: -ms-flexbox;
    display: flex;
    margin-top: 20px;
  }
  /* line 102, pages/_home.scss */
  label.styled[for='show-password'] > * {
    opacity: 1;
  }
  /* line 106, pages/_home.scss */
  label.styled[for='show-password'] i {
    margin-right: 10px;
  }
  /* line 110, pages/_home.scss */
  label.styled[for='show-password'] span {
    line-height: 25px;
  }

  @media only screen and (max-width: 1024px) {
    /* line 117, pages/_home.scss */
    .page-container .home {
      padding: 0 0 50px 0;
    }
    /* line 120, pages/_home.scss */
    .page-container .home .login {
      padding: 0 0 70px 0;
      margin: 0 10px;
      float: none;
      border: none;
      border-bottom: 1px solid white;
      width: auto;
      padding-bottom: 20px;
    }
    /* line 129, pages/_home.scss */
    .page-container .home .login h3,
    .page-container .home .login p {
      margin: 0 8px;
    }
    /* line 134, pages/_home.scss */
    .page-container .home .page-main {
      margin: 0 10px;
      padding-top: 77px;
    }
    /* line 138, pages/_home.scss */
    .page-container .home .page-main h2,
    .page-container .home .page-main h3 {
      font-size: 24px;
    }
    /* line 142, pages/_home.scss */
    .page-container .home .page-main .button {
      display: block;
      margin: 41px auto 0 auto;
    }
    /* line 148, pages/_home.scss */
    .page-container .home .image {
      margin: 75px auto 0 auto;
      max-width: 311px;
    }
    /* line 152, pages/_home.scss */
    .page-container .home .image img {
      max-width: 100%;
    }
  }
  /* line 1, pages/_page.scss */
  .page-flex {
    display: -ms-flexbox;
    display: flex;
  }

  /* line 5, pages/_page.scss */
  .page-left,
  .page-right {
    -ms-flex: 1;
    flex: 1;
  }

  /* line 9, pages/_page.scss */
  .page {
    margin: 42px 34px 34px;
    box-sizing: border-box;
    color: #343131;
  }
  /* line 14, pages/_page.scss */
  .page h2 {
    margin-bottom: 40px;
  }
  /* line 18, pages/_page.scss */
  .page h3 {
    margin-bottom: 10px;
  }
  /* line 22, pages/_page.scss */
  .page p {
    margin-bottom: 20px;
    line-height: 30px;
    font-size: 16px;
  }

  /* line 29, pages/_page.scss */
  .page-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  @media only screen and (max-width: 1024px) {
    /* line 35, pages/_page.scss */
    .page {
      margin: 80px 10px;
    }
    /* line 38, pages/_page.scss */
    .page.page-flex {
      display: block;
    }
    /* line 41, pages/_page.scss */
    .page.page-flex .page-left,
    .page.page-flex .page-right {
      text-align: center;
      padding: 0;
      border: none;
    }
    /* line 48, pages/_page.scss */
    .page p {
      line-height: 24px;
    }
  }
  /* Colors */
  /* Sizes */
  /* line 8, pages/_dashboard.scss */
  .dashboard-steps {
    max-width: 1370px;
    padding: 0 35px;
    margin: -62px auto 0 auto;
    color: white;
    display: -ms-flexbox;
    display: flex;
  }

  /* line 16, pages/_dashboard.scss */
  .dashboard-step {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-preferred-size: 190px;
    flex-basis: 190px;
    border-radius: 8px;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 35px;
    background: #999999;
    max-height: 122px;
  }
  /* line 27, pages/_dashboard.scss */
  .dashboard-step:last-child {
    margin-right: 0;
  }
  /* line 31, pages/_dashboard.scss */
  .dashboard-step.open {
    -ms-flex-preferred-size: 557px;
    flex-basis: 557px;
    max-height: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  }
  /* line 37, pages/_dashboard.scss */
  .dashboard-step.open,
  .dashboard-step.active {
    background: #343131;
  }
  /* line 41, pages/_dashboard.scss */
  .dashboard-step a {
    color: white;
  }

  /* line 46, pages/_dashboard.scss */
  .dashboard-step-title {
    text-decoration: none;
    text-align: center;
    margin-bottom: 15px;
  }
  /* line 51, pages/_dashboard.scss */
  .dashboard-step-title p,
  .dashboard-step-title h3 {
    color: white;
  }
  /* line 55, pages/_dashboard.scss */
  .dashboard-step-title h3 {
    font-size: 24px;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
  }
  /* line 62, pages/_dashboard.scss */
  .dashboard-step-title p {
    font-size: 18px;
    font-size: 16px;
  }

  /* line 69, pages/_dashboard.scss */
  .dashboard-step.open {
    padding-top: 70px;
  }
  /* line 72, pages/_dashboard.scss */
  .dashboard-step.open h4 {
    font-size: 1.1em;
    margin-bottom: 0.3em;
  }
  /* line 77, pages/_dashboard.scss */
  .dashboard-step.open .dashboard-step-title {
    margin-bottom: 25px;
  }
  /* line 80, pages/_dashboard.scss */
  .dashboard-step.open .dashboard-step-title .subtitle {
    margin-top: 25px;
    margin-bottom: -25px;
  }
  /* line 85, pages/_dashboard.scss */
  .dashboard-step.open .dashboard-step-title h3 {
    font-size: 24px;
  }
  /* line 89, pages/_dashboard.scss */
  .dashboard-step.open .dashboard-step-title p {
    font-size: 18px;
  }
  /* line 94, pages/_dashboard.scss */
  .dashboard-step.open .dashboard-step-icon {
    width: 110px;
    height: 110px;
    border-radius: 110px;
    top: -55px;
    margin-left: -55px;
  }

  /* line 103, pages/_dashboard.scss */
  .dashboard-step-icon {
    width: 29px;
    height: 29px;
    border-radius: 29px;
    background: white;
    top: -14.5px;
    position: absolute;
    left: 50%;
    margin-left: -14.5px;
    background-image: url('/assets/images/checkmark_white.svg');
    background-repeat: no-repeat;
    background-position: 50%;
  }

  /* line 117, pages/_dashboard.scss */
  .dashboard-step-content {
    padding: 30px 35px 50px 35px;
    line-height: 22px;
    font-size: 16px;
  }

  /* line 124, pages/_dashboard.scss */
  .dashboard-step-text p:first-child {
    font-size: 18px;
  }
  /* line 128, pages/_dashboard.scss */
  .dashboard-step-text p {
    font-size: 16px;
    margin-bottom: 20px;
  }

  /* line 136, pages/_dashboard.scss */
  .open.dashboard-step-1 .dashboard-step-icon {
    background-image: url('/assets/images/step1.svg');
  }

  /* line 136, pages/_dashboard.scss */
  .open.dashboard-step-2 .dashboard-step-icon {
    background-image: url('/assets/images/step2.svg');
  }

  /* line 136, pages/_dashboard.scss */
  .open.dashboard-step-3 .dashboard-step-icon {
    background-image: url('/assets/images/step3.svg');
  }

  /* line 136, pages/_dashboard.scss */
  .open.dashboard-step-4 .dashboard-step-icon {
    background-image: url('/assets/images/step4.svg');
  }

  /* line 136, pages/_dashboard.scss */
  .open.dashboard-step-5 .dashboard-step-icon {
    background-image: url('/assets/images/step5.svg');
  }

  /* line 143, pages/_dashboard.scss */
  .dashboard-step-checklist li {
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 35px;
  }
  /* line 147, pages/_dashboard.scss */
  .dashboard-step-checklist li:last-child {
    margin-bottom: 0;
  }

  /* line 153, pages/_dashboard.scss */
  .dashboard-step-checklist-icon {
    -ms-flex-preferred-size: 29px;
    flex-basis: 29px;
    height: 29px;
    margin-right: 23px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url('/assets/images/checkmark_gray.svg');
  }
  /* line 161, pages/_dashboard.scss */
  .dashboard-step-checklist-icon.complete {
    background-image: url('/assets/images/checkmark_green.svg');
  }

  /* line 166, pages/_dashboard.scss */
  .dashboard-step-checklist-body {
    width: 100%;
  }
  /* line 169, pages/_dashboard.scss */
  .dashboard-step-checklist-body small {
    font-size: 14px;
    line-height: 20px;
  }
  /* line 173, pages/_dashboard.scss */
  .dashboard-step-checklist-body small a {
    color: inherit;
  }

  /* line 179, pages/_dashboard.scss */
  .dashboard-step-buttons {
    display: -ms-flexbox;
    display: flex;
  }
  /* line 182, pages/_dashboard.scss */
  .dashboard-step-buttons.hide {
    display: none;
  }
  /* line 186, pages/_dashboard.scss */
  .dashboard-step-buttons button {
    display: block;
    width: 50%;
    margin-right: 30px;
    margin-bottom: 0;
  }
  /* line 192, pages/_dashboard.scss */
  .dashboard-step-buttons button:last-child {
    margin-right: 0;
  }

  /* line 198, pages/_dashboard.scss */
  .dashboard-step-columns {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  /* line 203, pages/_dashboard.scss */
  .dashboard-step-columns > * {
    -ms-flex-preferred-size: 50px;
    flex-basis: 50px;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    margin-right: 30px;
  }
  /* line 209, pages/_dashboard.scss */
  .dashboard-step-columns > *:last-child {
    margin-right: 0;
  }
  /* line 214, pages/_dashboard.scss */
  .dashboard-step-columns .button {
    padding: 20px;
  }

  /* line 219, pages/_dashboard.scss */
  p + .dashboard-chart {
    margin-top: 1em;
  }

  /* line 223, pages/_dashboard.scss */
  .dashboard-chart {
    padding-left: 3em;
    line-height: 3em;
    display: block;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 2em;
  }
  /* line 231, pages/_dashboard.scss */
  .dashboard-chart.chart-web {
    background-image: url('/assets/images/analyze/chart-web-white.svg');
  }
  /* line 234, pages/_dashboard.scss */
  .dashboard-chart.chart-web.chart-green {
    background-image: url('/assets/images/analyze/chart-web-green.svg');
  }
  /* line 239, pages/_dashboard.scss */
  .dashboard-chart.chart-distribution {
    background-image: url('/assets/images/analyze/chart-distribution-white.svg');
  }
  /* line 242, pages/_dashboard.scss */
  .dashboard-chart.chart-distribution.chart-green {
    background-image: url('/assets/images/analyze/chart-distribution-green.svg');
  }
  /* line 247, pages/_dashboard.scss */
  .dashboard-chart.chart-bar {
    background-image: url('/assets/images/analyze/chart-bar-white.svg');
  }
  /* line 250, pages/_dashboard.scss */
  .dashboard-chart.chart-bar.chart-green {
    background-image: url('/assets/images/analyze/chart-bar-green.svg');
  }
  /* line 255, pages/_dashboard.scss */
  .dashboard-chart.chart-demo {
    background-image: url('/assets/images/analyze/chart-demo-white.svg');
  }
  /* line 258, pages/_dashboard.scss */
  .dashboard-chart.chart-demo.chart-green {
    background-image: url('/assets/images/analyze/chart-demo-green.svg');
  }

  /* line 265, pages/_dashboard.scss */
  .two-part-survey-spacer {
    margin: 70px;
  }

  /* line 269, pages/_dashboard.scss */
  .survey-end-date-approaching {
    display: none;
  }
  /* line 272, pages/_dashboard.scss */
  .survey-end-date-approaching.has-target {
    margin-top: 55px;
  }

  /* line 277, pages/_dashboard.scss */
  .deadline-kept {
    margin-top: 30px;
    font-size: 14px;
  }
  /* line 281, pages/_dashboard.scss */
  .deadline-kept a {
    color: white;
    cursor: pointer;
  }

  @media only screen and (max-width: 1024px) {
    /* line 290, pages/_dashboard.scss */
    .dashboard-steps {
      display: block;
      margin-top: 50px;
      padding: 0 10px;
    }

    /* line 296, pages/_dashboard.scss */
    .dashboard-step {
      margin-right: 0;
      margin-bottom: 30px;
      padding-top: 30px;
      padding-bottom: 10px;
    }
    /* line 302, pages/_dashboard.scss */
    .dashboard-step.open {
      margin-top: -20px;
    }
    /* line 306, pages/_dashboard.scss */
    .dashboard-step .dashboard-step-icon {
      background-image: url('/assets/images/checkmark_gray_mobile.svg');
      width: 34px;
      height: 34px;
    }
    /* line 315, pages/_dashboard.scss */
    .dashboard-step.not-open .dashboard-step-title h3:after {
      content: ': ';
    }
    /* line 320, pages/_dashboard.scss */
    .dashboard-step.not-open .dashboard-step-title h3,
    .dashboard-step.not-open .dashboard-step-title p {
      display: inline-block;
    }
    /* line 327, pages/_dashboard.scss */
    .dashboard-step.active .dashboard-step-icon {
      background-image: url('/assets/images/checkmark_green_mobile.svg');
    }
    /* line 332, pages/_dashboard.scss */
    .dashboard-step.open {
      padding-top: 40px;
    }
    /* line 335, pages/_dashboard.scss */
    .dashboard-step.open .dashboard-step-icon {
      display: none;
    }
    /* line 339, pages/_dashboard.scss */
    .dashboard-step.open .subtitle {
      margin-top: 50px;
    }
    /* line 343, pages/_dashboard.scss */
    .dashboard-step.open .dashboard-step-content {
      padding: 40px 35px;
    }
    /* line 347, pages/_dashboard.scss */
    .dashboard-step.open .dashboard-step-buttons {
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
    }
    /* line 350, pages/_dashboard.scss */
    .dashboard-step.open .dashboard-step-buttons button {
      width: 225px;
      margin: 20px auto;
    }
    /* line 354, pages/_dashboard.scss */
    .dashboard-step.open .dashboard-step-buttons button:first-child {
      margin: 0 auto;
    }
    /* line 361, pages/_dashboard.scss */
    .dashboard-step.open
      .dashboard-step-checklist
      .survey-end-date-approaching {
      display: block;
      line-height: 20px;
      margin-left: -41px;
      text-align: center;
    }
    /* line 368, pages/_dashboard.scss */
    .dashboard-step.open .dashboard-step-checklist li:last-child {
      margin-bottom: 0;
    }
    /* line 372, pages/_dashboard.scss */
    .dashboard-step.open
      .dashboard-step-checklist
      li:last-child
      .dashboard-step-checklist-body
      > *:last-child {
      margin-bottom: 0;
    }
    /* line 380, pages/_dashboard.scss */
    .dashboard-step.open .dashboard-step-checklist-body .button {
      margin-left: -42px;
      width: 275px;
    }

    /* line 388, pages/_dashboard.scss */
    .dashboard-step-columns {
      -ms-flex-direction: column;
      flex-direction: column;
    }
  }
  /* line 1, pages/_about.scss */
  .about {
    margin: 0 40px 40px 40px;
  }

  /* line 5, pages/_about.scss */
  .sections {
    margin: 0;
    padding: 40px 0 0 0;
  }

  /* this allows a user to set a piece of text
  as bold in the editor, and have it use the title
  treatment in a content section */
  /* line 13, pages/_about.scss */
  .sections h3,
  .section strong {
    font-size: 18px;
    font-weight: bold;
  }

  /* line 18, pages/_about.scss */
  .section strong {
    margin-top: 30px;
    display: block;
  }

  /* line 23, pages/_about.scss */
  .section p strong {
    display: inline;
  }

  /* line 27, pages/_about.scss */
  .section {
    list-style-type: none;
    padding: 0;
    border-bottom: 1px solid #999999;
    padding-bottom: 20px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  /* line 35, pages/_about.scss */
  .section:last-child {
    border-bottom: none;
  }

  /* line 40, pages/_about.scss */
  .principles-container {
    margin: 0px 40px 40px 0;
    box-shadow: 0 3px 4px rgba(156, 156, 156, 0.5);
    padding-bottom: 80px;
  }
  /* line 45, pages/_about.scss */
  .principles-container h2 {
    background: #343131;
    color: white;
    text-align: center;
    padding: 20px;
    text-transform: uppercase;
    display: block;
  }

  /* line 55, pages/_about.scss */
  .principle {
    display: table-cell;
    border-right: 1px solid #343131;
  }

  /* line 60, pages/_about.scss */
  .principle-row {
    display: table-row;
  }

  /* line 64, pages/_about.scss */
  .principles {
    display: table;
    margin-top: 60px;
  }
  /* line 68, pages/_about.scss */
  .principles:after {
    content: '';
    display: table;
    clear: both;
  }

  /* line 75, pages/_about.scss */
  .principle-content {
    padding: 0 15px 40px 15px;
    text-align: center;
    line-height: 2;
  }
  /* line 80, pages/_about.scss */
  .principle-content img {
    height: 50px;
  }

  /* line 85, pages/_about.scss */
  .principle:nth-child(2n) {
    border-right: none;
  }

  @media only screen and (max-width: 1024px) {
    /* line 90, pages/_about.scss */
    .page.about-page {
      margin: 0px 10px;
    }
    /* line 93, pages/_about.scss */
    .page.about-page .page-left,
    .page.about-page .page-right {
      text-align: left;
    }
    /* line 97, pages/_about.scss */
    .page.about-page .section {
      line-height: 30px;
      padding-bottom: 10px;
    }
    /* line 101, pages/_about.scss */
    .page.about-page .section p {
      line-height: 30px;
    }
    /* line 106, pages/_about.scss */
    .page.about-page button.large {
      width: 275px;
      margin: 0 auto;
      display: block;
    }
    /* line 112, pages/_about.scss */
    .page.about-page .principles-container {
      box-shadow: none;
      margin: 60px 0 0 0;
    }
    /* line 116, pages/_about.scss */
    .page.about-page .principles-container h2 {
      margin-left: -10px;
      font-size: 24px;
      line-height: 86px;
      padding: 0;
      margin: 0 -10px;
    }

    /* line 126, pages/_about.scss */
    .about {
      margin: 0;
    }

    /* line 130, pages/_about.scss */
    .principles {
      display: block;
    }

    /* line 134, pages/_about.scss */
    .principle {
      display: block;
      width: 100%;
      padding: 0 0 20px 0;
      border-right: none;
    }
    /* line 140, pages/_about.scss */
    .principle .principle-content {
      border: none;
      padding: 0 15px;
    }
    /* line 144, pages/_about.scss */
    .principle .principle-content p {
      line-height: 30px;
    }
  }
  /* line 1, pages/_definitions.scss */
  .definitions {
    margin: 80px 80px 80px 80px;
  }
  /* line 4, pages/_definitions.scss */
  .definitions ul li ul {
    padding: 20px 10px;
  }
  /* line 8, pages/_definitions.scss */
  .definitions ul {
    margin-top: 40px;
  }
  /* line 12, pages/_definitions.scss */
  .definitions h2 {
    font-size: 18px;
    margin-bottom: 20px;
  }
  /* line 17, pages/_definitions.scss */
  .definitions input {
    width: 728px;
    height: 75px;
    border-radius: 8px;
    font-size: 18px;
    padding: 27px 20px;
    box-sizing: border-box;
  }

  /* line 28, pages/_definitions.scss */
  .letter h2 {
    background: rgba(241, 239, 239, 0.98);
    padding: 27px 20px;
    color: #d2252f;
    font-size: 18px;
  }

  /* line 36, pages/_definitions.scss */
  .definition {
    margin-bottom: 40px;
    font-size: 16px;
    max-width: 1146px;
  }
  /* line 41, pages/_definitions.scss */
  .definition h3 {
    margin-bottom: 20px;
    color: #343131;
  }

  @media only screen and (max-width: 1024px) {
    /* line 48, pages/_definitions.scss */
    .definitions {
      margin: 80px 10px;
    }
    /* line 51, pages/_definitions.scss */
    .definitions input {
      width: 100%;
      margin-bottom: 20px;
    }
    /* line 56, pages/_definitions.scss */
    .definitions ul {
      margin-top: 20px;
    }
    /* line 61, pages/_definitions.scss */
    .definitions .letter h2 {
      line-height: 50px;
      padding: 0 20px;
      box-sizing: border-box;
    }
    /* line 68, pages/_definitions.scss */
    .definitions p {
      line-height: 26px;
    }
  }
  /* line 3, pages/_manage.scss */
  .institutional_participants {
    padding-bottom: 60px;
    border-bottom: 1px solid #979797;
  }

  /* line 8, pages/_manage.scss */
  .student-participants {
    padding-top: 40px;
  }
  /* line 11, pages/_manage.scss */
  .student-participants h2 {
    margin-bottom: 60px;
  }
  /* line 15, pages/_manage.scss */
  .student-participants p {
    font-size: 14px;
  }
  /* line 19, pages/_manage.scss */
  .student-participants strong {
    font-size: 16px;
    display: block;
    float: left;
    margin-right: 10px;
    margin-bottom: 20px;
  }
  /* line 27, pages/_manage.scss */
  .student-participants button {
    margin-right: 20px;
    cursor: pointer;
  }

  /* line 34, pages/_manage.scss */
  .manage .table {
    display: table;
    margin-top: 80px;
    width: 100%;
  }
  /* line 39, pages/_manage.scss */
  .manage .table .tr {
    display: table-row;
    width: 100%;
  }
  /* line 43, pages/_manage.scss */
  .manage .table .tr form {
    width: 100%;
  }
  /* line 48, pages/_manage.scss */
  .manage .table .td,
  .manage .table .th {
    display: table-cell;
  }
  /* line 52, pages/_manage.scss */
  .manage .table .thead {
    display: table-header-group;
  }
  /* line 55, pages/_manage.scss */
  .manage .table .thead .td {
    padding: 0 20px 40px 20px;
    font-size: 18px;
    font-weight: bold;
  }
  /* line 62, pages/_manage.scss */
  .manage .table .tbody {
    display: table-row-group;
  }
  /* line 65, pages/_manage.scss */
  .manage .table .tbody .tr {
    height: 75px;
  }
  /* line 68, pages/_manage.scss */
  .manage .table .tbody .tr:nth-child(2n + 1) {
    background: rgba(241, 239, 239, 0.98);
  }
  /* line 72, pages/_manage.scss */
  .manage .table .tbody .tr.add-participants-row-body,
  .manage .table .tbody .tr.add-participants-row-header {
    background: white;
  }
  /* line 77, pages/_manage.scss */
  .manage .table .tbody .td:first-child {
    font-weight: bold;
  }
  /* line 82, pages/_manage.scss */
  .manage .table .td {
    padding: 20px;
    border-right: 1px solid #979797;
    vertical-align: top;
    min-width: 300px;
  }
  /* line 88, pages/_manage.scss */
  .manage .table .td:nth-child(5n + 4),
  .manage .table .td:nth-child(5n + 5) {
    min-width: 0;
    width: 149px;
    box-sizing: border-box;
  }
  /* line 95, pages/_manage.scss */
  .manage .table .td:nth-child(5n + 5) {
    border-right: none;
  }
  /* line 101, pages/_manage.scss */
  .manage .hasPortalAccess,
  .manage .isRemovable {
    background-repeat: no-repeat;
    background-position: 50%;
  }
  /* line 106, pages/_manage.scss */
  .manage .hasPortalAccess {
    background-image: url('/assets/images/checkmark_green.svg');
  }
  /* line 110, pages/_manage.scss */
  .manage .isRemovable {
    background-color: transparent;
    padding: 0;
    box-shadow: none;
    background-image: url('/assets/images/close.svg');
    width: 29px;
    cursor: pointer;
    height: 29px;
    display: block;
    margin: 0 auto;
  }
  /* line 122, pages/_manage.scss */
  .manage a.hasPortalAccess,
  .manage i.hasPortalAccess {
    width: 29px;
    height: 29px;
    display: block;
    margin: 0 auto;
  }
  /* line 129, pages/_manage.scss */
  .manage .add-participants-row-body,
  .manage .add-participants-row-header {
    font-size: 18px;
  }
  /* line 133, pages/_manage.scss */
  .manage .table {
    margin-bottom: 130px;
  }
  /* line 137, pages/_manage.scss */
  .manage label[for='hasPortalAccess'] {
    width: 29px;
    height: 29px;
    border: 1px solid #979797;
    border-radius: 29px;
    margin: 0 auto;
  }
  /* line 144, pages/_manage.scss */
  .manage label[for='hasPortalAccess'] i {
    border: none;
  }
  /* line 149, pages/_manage.scss */
  .manage input[type='checkbox'].styled:checked + label {
    border: 1px solid transparent;
  }
  /* line 152, pages/_manage.scss */
  .manage input[type='checkbox'].styled:checked + label i {
    background-image: url('/assets/images/checkmark_green.svg');
  }

  /* line 158, pages/_manage.scss */
  .submit-container {
    position: relative;
    margin-top: 50px;
  }
  /* line 162, pages/_manage.scss */
  .submit-container input[type='submit'] {
    position: absolute;
    right: -21px;
    width: 275px;
    bottom: -170px;
  }

  /* line 170, pages/_manage.scss */
  .add-participants,
  .replace-participants {
    display: none;
    padding-top: 40px;
  }
  /* line 174, pages/_manage.scss */
  .add-participants h3,
  .replace-participants h3 {
    font-size: 18px;
  }
  /* line 178, pages/_manage.scss */
  .add-participants.show,
  .replace-participants.show {
    display: block;
  }
  /* line 182, pages/_manage.scss */
  .add-participants small,
  .replace-participants small {
    font-size: 14px;
    display: block;
  }
  /* line 187, pages/_manage.scss */
  .add-participants input,
  .replace-participants input {
    display: block;
  }
  /* line 191, pages/_manage.scss */
  .add-participants .subtitle,
  .replace-participants .subtitle {
    font-size: 14px;
    background-image: url('/assets/images/warning.svg');
    background-repeat: no-repeat;
    background-position: left 50%;
    padding-left: 30px;
    padding-top: 10px;
  }
  /* line 200, pages/_manage.scss */
  .add-participants .cancel,
  .replace-participants .cancel {
    color: #d2252f;
    text-transform: uppercase;
    font-weight: bold;
    display: block;
    margin-top: 20px;
  }

  /* line 209, pages/_manage.scss */
  .upload-and-add {
    background-repeat: no-repeat;
    background-position: right 50px top 50%;
    background-image: url('/assets/images/lock.svg');
    width: 275px;
    padding-right: 70px;
  }
  /* line 216, pages/_manage.scss */
  .upload-and-add.loading {
    background-image: url('/assets/images/loading.svg');
    background-size: 50px;
    background-position: right 20px top 50%;
  }

  /* line 223, pages/_manage.scss */
  .upload-status {
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #979797;
    padding: 21px 25px;
    width: 585px;
    display: inline-block;
    margin-right: 25px;
  }
  /* line 232, pages/_manage.scss */
  .upload-status p {
    font-size: 18px;
    color: #d2252f;
    text-transform: uppercase;
    margin: 0;
    min-height: 30px;
  }

  /* line 241, pages/_manage.scss */
  .upload-form {
    display: inline-block;
  }

  @media only screen and (max-width: 1024px) {
    /* line 246, pages/_manage.scss */
    .manage {
      margin: 60px 10px;
    }
    /* line 249, pages/_manage.scss */
    .manage h2 {
      margin-bottom: 0;
    }
    /* line 253, pages/_manage.scss */
    .manage .table {
      margin-top: 40px;
    }
    /* line 256, pages/_manage.scss */
    .manage .table .thead {
      display: none;
    }
    /* line 261, pages/_manage.scss */
    .manage .table .tbody .tr {
      display: block;
      background: white;
      height: auto;
      position: relative;
    }
    /* line 267, pages/_manage.scss */
    .manage .table .tbody .tr .td {
      border-right: none;
      display: block;
      box-sizing: border-box;
      width: 100%;
      padding-bottom: 0;
    }
    /* line 274, pages/_manage.scss */
    .manage .table .tbody .tr .td.hasPortalAccess {
      background-position: 130px 15px;
      height: 50px;
    }
    /* line 278, pages/_manage.scss */
    .manage .table .tbody .tr .td.hasPortalAccess:before {
      content: 'Portal Access: ';
    }
    /* line 283, pages/_manage.scss */
    .manage .table .tbody .tr .td .isRemovable {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    /* line 289, pages/_manage.scss */
    .manage .table .tbody .tr .td:first-child {
      background: rgba(241, 239, 239, 0.98);
      padding: 0 20px;
      line-height: 50px;
      height: 50px;
    }
    /* line 298, pages/_manage.scss */
    .manage .table .tbody .tr.add-participants-row-header .td {
      padding: 0;
    }
    /* line 302, pages/_manage.scss */
    .manage .table .tbody .tr.add-participants-row-header .td:first-child {
      background: white;
      margin-bottom: 20px;
    }
    /* line 309, pages/_manage.scss */
    .manage .table .tbody .tr.add-participants-row-body .td {
      padding: 0 0 10px 0;
      height: auto;
    }
    /* line 314, pages/_manage.scss */
    .manage .table .tbody .tr.add-participants-row-body .td:first-child {
      background: white;
    }
    /* line 318, pages/_manage.scss */
    .manage
      .table
      .tbody
      .tr.add-participants-row-body
      label[for='hasPortalAccess'] {
      margin-left: 135px;
      font-weight: bold;
      margin-top: 40px;
    }
    /* line 324, pages/_manage.scss */
    .manage
      .table
      .tbody
      .tr.add-participants-row-body
      label[for='hasPortalAccess']:before {
      content: 'Portal Access?';
      margin-left: -135px;
    }
    /* line 329, pages/_manage.scss */
    .manage
      .table
      .tbody
      .tr.add-participants-row-body
      label[for='hasPortalAccess']
      i {
      float: right;
    }
    /* line 339, pages/_manage.scss */
    .manage form input[type='submit'] {
      margin: 20px auto 0 auto;
      float: none;
      display: block;
    }
    /* line 346, pages/_manage.scss */
    .manage .institutional_participants {
      padding-bottom: 50px;
    }
    /* line 351, pages/_manage.scss */
    .manage .student-participants h2 {
      margin-bottom: 10px;
    }
    /* line 355, pages/_manage.scss */
    .manage .student-participants strong {
      float: none;
      margin-bottom: 10px;
      margin-top: 30px;
    }
    /* line 361, pages/_manage.scss */
    .manage .student-participants p {
      line-height: 18px;
    }
    /* line 365, pages/_manage.scss */
    .manage .student-participants button {
      margin: 10px auto;
      display: block;
      width: 275px;
    }
    /* line 373, pages/_manage.scss */
    .manage .add-participants h3,
    .manage .replace-participants h3 {
      font-size: 16px;
    }
    /* line 377, pages/_manage.scss */
    .manage .add-participants label.styled,
    .manage .replace-participants label.styled {
      margin: 20px auto;
    }
    /* line 381, pages/_manage.scss */
    .manage .add-participants small,
    .manage .replace-participants small {
      font-size: 14px;
      line-height: 24px;
    }
  }
  /* line 2, pages/_report.scss */
  .report h2 {
    margin-bottom: 40px;
  }
  /* line 6, pages/_report.scss */
  .report .sections {
    padding: 0;
    display: block;
  }
  /* line 11, pages/_report.scss */
  .report .section {
    padding: 0;
    padding-bottom: 10px;
    margin-bottom: 40px;
    border-bottom: 1px solid #999999;
  }
  /* line 17, pages/_report.scss */
  .report .section.no-border,
  .report .section:last-child {
    border-bottom: none;
  }
  /* line 22, pages/_report.scss */
  .report .section-content,
  .report .section-sidebar {
    min-width: 50%;
  }
  /* line 26, pages/_report.scss */
  .report .section-body {
    display: -ms-flexbox;
    display: flex;
  }
  /* line 29, pages/_report.scss */
  .report .section-body ol,
  .report .section-body ul {
    padding-left: 40px;
  }
  /* line 33, pages/_report.scss */
  .report .section-body ol li {
    list-style-type: decimal;
  }
  /* line 36, pages/_report.scss */
  .report .section-body ul li {
    list-style-type: disc;
  }
  /* line 41, pages/_report.scss */
  .report .recommendations {
    box-shadow: 0 3px 4px rgba(156, 156, 156, 0.5);
    padding-bottom: 40px;
  }
  /* line 45, pages/_report.scss */
  .report .recommendations h3 {
    text-transform: uppercase;
    background: #343131;
    padding: 20px;
    color: white;
    text-align: center;
  }
  /* line 53, pages/_report.scss */
  .report .recommendations .columns {
    display: -ms-flexbox;
    display: flex;
    margin-top: 40px;
  }
  /* line 58, pages/_report.scss */
  .report .recommendations .column {
    -ms-flex: 1;
    flex: 1;
    border-right: 1px solid #999999;
    padding: 20px;
  }
  /* line 63, pages/_report.scss */
  .report .recommendations .column h4 {
    text-transform: uppercase;
    font-size: 16px;
    text-align: center;
    background-repeat: no-repeat;
    background-position: 50% top;
    padding-top: 106px;
    margin-bottom: 40px;
  }
  /* line 74, pages/_report.scss */
  .report .recommendations .column h5 {
    color: green;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  /* line 81, pages/_report.scss */
  .report .recommendations .column .content {
    margin-left: 20px;
  }
  /* line 85, pages/_report.scss */
  .report .recommendations .column .recommendation {
    margin-bottom: 40px;
  }
  /* line 89, pages/_report.scss */
  .report .recommendations .column:last-child {
    border-right: none;
  }
  /* line 93, pages/_report.scss */
  .report .recommendations .column.strengths h4 {
    background-image: url('/assets/images/report/recommendations/strengths.svg');
  }
  /* line 94, pages/_report.scss */
  .report .recommendations .column.growth-opportunity h4 {
    background-image: url('/assets/images/report/recommendations/growth-opportunity.svg');
  }
  /* line 95, pages/_report.scss */
  .report .recommendations .column.area-for-investigation h4 {
    background-image: url('/assets/images/report/recommendations/area-for-investigation.svg');
  }
  /* line 99, pages/_report.scss */
  .report .supporting_information {
    background-color: rgba(241, 239, 239, 0.98);
    padding: 40px 20px;
  }
  /* line 103, pages/_report.scss */
  .report .supporting_information h2 {
    font-size: 18px;
  }
  /* line 107, pages/_report.scss */
  .report .supporting_information .columns {
    display: -ms-flexbox;
    display: flex;
  }
  /* line 111, pages/_report.scss */
  .report .supporting_information .column {
    border-right: 1px solid #999999;
    padding: 0 40px 20px 40px;
  }
  /* line 115, pages/_report.scss */
  .report .supporting_information .column li {
    margin-bottom: 20px;
  }
  /* line 119, pages/_report.scss */
  .report .supporting_information .column:first-child {
    padding-left: 0;
  }
  /* line 123, pages/_report.scss */
  .report .supporting_information .column:last-child {
    border-right: none;
  }
  /* line 129, pages/_report.scss */
  .report .button-group {
    width: 60%;
    min-width: 400px;
    margin: 5px auto;
    padding-left: 0 !important;
  }

  /* line 137, pages/_report.scss */
  .section-sidebar {
    padding-left: 40px;
    box-sizing: border-box;
  }
  /* line 141, pages/_report.scss */
  .section-sidebar table {
    border-collapse: collapse;
    width: 100%;
    text-align: left;
  }
  /* line 146, pages/_report.scss */
  .section-sidebar table td {
    padding: 0px 20px;
  }
  /* line 150, pages/_report.scss */
  .section-sidebar table thead {
    background: #00a94e;
    color: white;
    font-size: 18px;
    font-weight: bold;
  }
  /* line 156, pages/_report.scss */
  .section-sidebar table thead th,
  .section-sidebar table thead td {
    padding: 10px 0 10px 20px;
  }
  /* line 159, pages/_report.scss */
  .section-sidebar table thead th span,
  .section-sidebar table thead td span {
    display: block;
    border-right: 1px solid white;
    padding-bottom: 10px;
    margin-bottom: -10px;
    padding-right: 20px;
    line-height: 1.25em;
  }
  /* line 169, pages/_report.scss */
  .section-sidebar table thead th:last-child span,
  .section-sidebar table thead td:last-child span {
    border-right: none;
  }
  /* line 178, pages/_report.scss */
  .section-sidebar table tbody tr:nth-child(2n) {
    background: rgba(241, 239, 239, 0.98);
  }
  /* line 182, pages/_report.scss */
  .section-sidebar table tbody tr:nth-child(2n + 1) {
    background: white;
  }
  /* line 186, pages/_report.scss */
  .section-sidebar table tbody tr:last-child {
    background: white;
  }
  /* line 191, pages/_report.scss */
  .section-sidebar table tbody td {
    border-right: 1px solid #999999;
  }
  /* line 194, pages/_report.scss */
  .section-sidebar table tbody td:first-child::after {
    content: '';
    display: inline-block;
    vertical-align: top;
    min-height: 40px;
  }
  /* line 201, pages/_report.scss */
  .section-sidebar table tbody td:last-child {
    border-right: none;
  }
  /* line 209, pages/_report.scss */
  .section-sidebar .bar p {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
  }
  /* line 215, pages/_report.scss */
  .section-sidebar .bar .chart {
    margin-bottom: 50px;
  }
  /* line 218, pages/_report.scss */
  .section-sidebar .bar .chart p {
    text-align: left;
    font-size: 18px;
    margin-bottom: 10px;
  }
  /* line 223, pages/_report.scss */
  .section-sidebar .bar .chart .g-bar-row {
    height: 30px;
    margin: 10px 0;
    width: 100%;
  }
  /* line 228, pages/_report.scss */
  .section-sidebar .bar .chart .g-bar {
    position: relative;
    height: 30px;
    min-width: 1px;
    max-width: 100%;
    padding-left: 15px;
    font-size: 14px;
    color: #fff;
    background-color: #00a94e;
  }
  /* line 238, pages/_report.scss */
  .section-sidebar .bar .chart .g-bar-val {
    position: absolute;
    top: 0;
    left: 100%;
    padding: 0 8px;
    background-color: #000;
  }
  /* line 248, pages/_report.scss */
  .section-sidebar .toggle-data {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
    text-decoration: underline;
    text-transform: uppercase;
    color: #00a94e;
  }
  /* line 258, pages/_report.scss */
  .section-sidebar .mirror-bar {
    position: relative;
    margin-bottom: 100px;
  }
  /* line 263, pages/_report.scss */
  .section-sidebar .mirror-bar .graph > p {
    margin-bottom: 35px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  /* line 269, pages/_report.scss */
  .section-sidebar .mirror-bar .chart {
    padding-left: 100px;
    box-sizing: border-box;
  }
  /* line 273, pages/_report.scss */
  .section-sidebar .mirror-bar .bar {
    position: absolute;
    bottom: 0;
    height: 55%;
    border-width: 0;
    border-left-width: 18px;
    border-style: solid;
    border-color: #00a94e;
  }
  /* line 282, pages/_report.scss */
  .section-sidebar .mirror-bar .bar:last-child {
    border-color: #22cb70;
  }
  /* line 286, pages/_report.scss */
  .section-sidebar .mirror-bar .bar-hold {
    position: relative;
    width: 18px;
    height: 100%;
  }
  /* line 291, pages/_report.scss */
  .section-sidebar .mirror-bar .bar-hold:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 1px;
    height: 100%;
    background-color: #aeaeae;
  }
  /* line 301, pages/_report.scss */
  .section-sidebar .mirror-bar .bar-label {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 160px;
    font-size: 12px;
    line-height: 1em;
    font-weight: bold;
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -ms-transform-origin: center left;
    transform-origin: center left;
  }
  /* line 313, pages/_report.scss */
  .section-sidebar .mirror-half,
  .section-sidebar .mirror-half-double {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    height: 115px;
  }
  /* line 319, pages/_report.scss */
  .section-sidebar .mirror-half:last-child,
  .section-sidebar .mirror-half-double:last-child {
    border-top: solid 1px #aeaeae;
  }
  /* line 322, pages/_report.scss */
  .section-sidebar .mirror-half:last-child .bar,
  .section-sidebar .mirror-half-double:last-child .bar {
    border-color: #343131;
    top: 0;
    bottom: initial;
  }
  /* line 327, pages/_report.scss */
  .section-sidebar .mirror-half:last-child .bar:before,
  .section-sidebar .mirror-half-double:last-child .bar:before {
    top: 3px;
    bottom: initial;
  }
  /* line 331, pages/_report.scss */
  .section-sidebar .mirror-half:last-child .bar:nth-child(2),
  .section-sidebar .mirror-half-double:last-child .bar:nth-child(2) {
    border-color: #676464;
  }
  /* line 334, pages/_report.scss */
  .section-sidebar .mirror-half:last-child .bar:nth-child(2):before,
  .section-sidebar .mirror-half-double:last-child .bar:nth-child(2):before {
    content: 'S';
  }
  /* line 339, pages/_report.scss */
  .section-sidebar .mirror-half:last-child .bar-hold:before,
  .section-sidebar .mirror-half-double:last-child .bar-hold:before {
    z-index: 0;
  }
  /* line 342, pages/_report.scss */
  .section-sidebar .mirror-half:last-child .mirror-half-label,
  .section-sidebar .mirror-half-double:last-child .mirror-half-label {
    top: 0;
    bottom: initial;
    color: #343131;
  }
  /* line 352, pages/_report.scss */
  .section-sidebar .mirror-half-double .bar {
    border-left-width: 9px;
  }
  /* line 356, pages/_report.scss */
  .section-sidebar .mirror-half-label {
    position: absolute;
    right: 100%;
    bottom: 0;
    font-weight: bold;
    line-height: 1.25em;
    color: #00a94e;
  }
  /* line 364, pages/_report.scss */
  .section-sidebar .mirror-line-minor {
    position: absolute;
    top: 25%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #f6f6f6;
  }
  /* line 372, pages/_report.scss */
  .section-sidebar .mirror-line-minor:nth-child(1) {
    top: 75%;
  }
  /* line 376, pages/_report.scss */
  .section-sidebar .mirror-line-half {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #d3d4d3;
  }
  /* line 384, pages/_report.scss */
  .section-sidebar .mirror-line-half:before {
    content: '50th Percentile';
    position: absolute;
    right: 100%;
    text-align: right;
    line-height: 1.25em;
    font-size: 10px;
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }
  /* line 395, pages/_report.scss */
  .section-sidebar table {
    display: none;
    position: relative;
    top: 40px;
  }
  /* line 401, pages/_report.scss */
  .section-sidebar table thead th:not(:first-child) {
    font-size: 12px;
  }
  /* line 406, pages/_report.scss */
  .section-sidebar table tbody td {
    height: 23px;
    font-size: 12px;
  }
  /* line 410, pages/_report.scss */
  .section-sidebar table tbody td:first-child:after {
    display: none;
  }

  /* line 418, pages/_report.scss */
  .line {
    position: relative;
    margin-bottom: 60px;
  }
  /* line 422, pages/_report.scss */
  .line .percentile-hold {
    position: relative;
    padding-bottom: 25px;
    margin-bottom: 25px;
  }
  /* line 427, pages/_report.scss */
  .line .percentile-hold .percentile-line {
    position: absolute;
    left: 50%;
    bottom: 0;
    height: 85%;
    width: 1px;
    margin-left: 15px;
    background-color: #aeaeae;
  }
  /* line 436, pages/_report.scss */
  .line .percentile-hold .percentile-label {
    position: absolute;
    width: 50%;
    line-height: 1.25em;
  }

  /* line 445, pages/_report.scss */
  .internal-comparisons td:first-child {
    text-align: center;
    font-weight: bold;
  }

  /* line 451, pages/_report.scss */
  .radar {
    position: relative;
    margin-bottom: 40px;
  }
  /* line 455, pages/_report.scss */
  .radar > canvas {
    margin-bottom: 15px;
  }
  /* line 459, pages/_report.scss */
  .radar p {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
  }

  @media print {
    @page {
      margin: 0.75in 0.5in 0.75in 0.5in;
      orphans: 4;
      widows: 3;
    }
    /* line 472, pages/_report.scss */
    h1,
    h2,
    h3,
    h4,
    h5 {
      page-break-after: avoid;
    }

    /* line 475, pages/_report.scss */
    table,
    .graph .chart {
      page-break-inside: avoid;
    }

    /* line 478, pages/_report.scss */
    h3 {
      color: #00a94e;
      page-break-before: always;
    }

    /* line 483, pages/_report.scss */
    .section strong {
      font-size: 1em;
    }

    /* line 486, pages/_report.scss */
    .sections > div:first-child > h3:first-child {
      page-break-before: avoid;
    }

    /* line 490, pages/_report.scss */
    .page-header {
      padding: 0 40px;
      background-color: transparent;
    }
    /* line 494, pages/_report.scss */
    .page-header .page-header-middle,
    .page-header .page-header-right {
      display: none;
    }
    /* line 498, pages/_report.scss */
    .page-header .page-header-left {
      position: relative;
      margin-top: 0;
    }
    /* line 502, pages/_report.scss */
    .page-header .page-title {
      margin-top: 0;
      margin-bottom: 0;
    }
    /* line 506, pages/_report.scss */
    .page-header .page-title h2 {
      color: #00a94e;
    }
    /* line 509, pages/_report.scss */
    .page-header .page-title p {
      display: none;
    }
    /* line 513, pages/_report.scss */
    .page-header .logo {
      position: absolute;
      right: 0;
      min-width: 200px;
    }
    /* line 518, pages/_report.scss */
    .page-header .logo img {
      max-height: 70px;
      filter: invert(60%);
    }

    /* line 524, pages/_report.scss */
    .mirror-bar {
      page-break-after: always;
    }

    /* line 527, pages/_report.scss */
    .supporting_information,
    .page-footer .footer-columns,
    .button:not(.selected) {
      display: none;
    }

    /* line 532, pages/_report.scss */
    .page {
      margin-top: 10px;
    }

    /* line 535, pages/_report.scss */
    .report .section-body {
      display: block;
    }

    /* line 539, pages/_report.scss */
    .toggle-data {
      display: none;
    }

    /* line 542, pages/_report.scss */
    .section-sidebar {
      padding-left: 0;
      margin: 0;
    }
    /* line 546, pages/_report.scss */
    .section-sidebar .graph {
      page-break-before: always;
    }
    /* line 550, pages/_report.scss */
    .section-sidebar .mirror-bar {
      page-break-before: always;
    }
    /* line 553, pages/_report.scss */
    .section-sidebar .mirror-bar .chart {
      /* width: 70%; */
      margin-bottom: 110px;
    }
    /* line 558, pages/_report.scss */
    .section-sidebar table {
      display: table;
    }
    /* line 561, pages/_report.scss */
    .section-sidebar table thead {
      background-color: #ddd;
      border-bottom: solid 1px #888;
    }
    /* line 565, pages/_report.scss */
    .section-sidebar table thead th {
      padding: 2px 0 2px 20px;
      color: #333;
    }
    /* line 569, pages/_report.scss */
    .section-sidebar table thead th:first-child {
      font-size: 12px;
    }
    /* line 575, pages/_report.scss */
    .section-sidebar table tbody tr {
      position: relative;
      background: transparent !important;
    }
    /* line 579, pages/_report.scss */
    .section-sidebar table tbody tr:first-child,
    .section-sidebar table tbody tr:last-child {
      display: none;
    }
    /* line 583, pages/_report.scss */
    .section-sidebar
      table
      tbody
      tr:not(:first-child):not(:nth-last-child(-n + 1)):nth-child(odd):after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      border-bottom: solid 25px #f5f5f5;
      z-index: -1;
    }
    /* line 591, pages/_report.scss */
    .section-sidebar table tbody td {
      line-height: normal;
      padding: 1px 20px;
    }

    /* line 599, pages/_report.scss */
    .report .recommendations {
      box-shadow: none;
    }
    /* line 602, pages/_report.scss */
    .report .recommendations > h3 {
      padding: 0 20px;
      color: #00a94e;
      text-align: left;
      background-color: transparent;
    }
    /* line 608, pages/_report.scss */
    .report .recommendations .columns {
      display: block;
      margin-top: 10px;
    }
    /* line 612, pages/_report.scss */
    .report .recommendations .column {
      border-right: none;
    }
    /* line 615, pages/_report.scss */
    .report .recommendations .column h4 {
      padding-top: 0;
      margin-bottom: 20px;
      background-image: none !important;
      text-align: left;
    }
  }
  /* line 2, pages/_details.scss */
  .details .multi-input {
    min-height: 100px;
  }
  /* line 5, pages/_details.scss */
  .details .multi-input label {
    font-weight: bold;
    margin-left: 60px;
    width: 480px;
    display: inline-block;
  }
  /* line 12, pages/_details.scss */
  .details .multi-input input {
    box-sizing: border-box;
    height: 50px;
    line-height: 50px;
    border-radius: 8px;
    width: 303px;
    border: 1px solid #979797;
    padding: 0 20px;
    font-size: 16px;
  }

  /* line 25, pages/_details.scss */
  .checkboxAnswer {
    margin-left: 56px;
    margin-bottom: 40px;
  }

  /* line 32, pages/_details.scss */
  .checkboxAnswer .option,
  .details-content .option {
    margin-bottom: 20px;
  }
  /* line 36, pages/_details.scss */
  .checkboxAnswer label,
  .details-content label {
    line-height: 25px;
  }
  /* line 39, pages/_details.scss */
  .checkboxAnswer label *,
  .details-content label * {
    opacity: 1;
  }
  /* line 43, pages/_details.scss */
  .checkboxAnswer label i,
  .details-content label i {
    border: 1px solid #979797;
    float: left;
    margin-right: 15px;
  }

  /* line 52, pages/_details.scss */
  .detail {
    margin-bottom: 60px;
  }
  /* line 55, pages/_details.scss */
  .detail:first-of-type {
    margin-bottom: 0;
  }
  /* line 58, pages/_details.scss */
  .detail:first-of-type p {
    margin-bottom: 0;
  }
  /* line 62, pages/_details.scss */
  .detail.buttons-2 {
    width: 40%;
  }
  /* line 65, pages/_details.scss */
  .detail.buttons-2 .other input {
    width: 100%;
  }
  /* line 70, pages/_details.scss */
  .detail.buttons-3 {
    width: 60%;
  }
  /* line 73, pages/_details.scss */
  .detail.buttons-3 .other input {
    width: 66%;
  }
  /* line 78, pages/_details.scss */
  .detail.buttons-4 {
    width: 80%;
  }
  /* line 81, pages/_details.scss */
  .detail.buttons-4 .other input {
    width: 50%;
  }
  /* line 86, pages/_details.scss */
  .detail .other {
    display: none;
    text-align: right;
  }
  /* line 90, pages/_details.scss */
  .detail .other.show {
    display: block;
  }
  /* line 94, pages/_details.scss */
  .detail .other input {
    box-sizing: border-box;
    margin-left: 10px;
    padding: 10px;
    width: 40%;
  }

  @media only screen and (max-width: 1024px) {
    /* line 106, pages/_details.scss */
    .details .multi-input label {
      width: auto;
      margin-left: 0;
      margin-bottom: 10px;
    }
    /* line 112, pages/_details.scss */
    .details .multi-input input {
      width: 100%;
    }
  }
  /* line 120, pages/_details.scss */
  .subdetail.question .detail {
    margin-bottom: 20px;
  }
  /* line 123, pages/_details.scss */
  .subdetail.question .detail p {
    margin-bottom: 10px;
    font-weight: bold;
  }
  /* line 127, pages/_details.scss */
  .subdetail.question .detail .button-group {
    margin-bottom: 0;
  }

  /* line 135, pages/_details.scss */
  .details.page > .multi-input label,
  .details-content > .multi-input label {
    width: auto;
  }
  /* line 138, pages/_details.scss */
  .details.page > .multi-input input[type='text'],
  .details-content > .multi-input input[type='text'] {
    width: 200px;
  }

  /* line 3, pages/_demographics.scss */
  .demographics .columns {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  /* line 9, pages/_demographics.scss */
  .demographics .demographic {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    margin: 0 15px 45px 15px;
  }
  /* line 13, pages/_demographics.scss */
  .demographics .demographic .title {
    width: 46%;
    margin: 0;
    padding-right: 10px;
    padding-bottom: 8px;
    text-align: right;
    font-weight: bold;
    box-sizing: border-box;
  }
  /* line 23, pages/_demographics.scss */
  .demographics .demographic .chart {
    position: relative;
    padding: 23px;
    background-color: rgba(241, 239, 239, 0.98);
  }
  /* line 28, pages/_demographics.scss */
  .demographics .demographic .chart-divider {
    position: absolute;
    top: -42px;
    left: 46.14%;
    height: calc(100% - 46px);
    width: 1px;
    padding-top: 70px;
    background-color: #aeaeae;
  }
  /* line 37, pages/_demographics.scss */
  .demographics .demographic .chart-line {
    position: absolute;
    top: 20px;
    right: 60px;
    height: calc(100% - 40px);
    width: 1px;
    background-color: #aeaeae;
  }
  /* line 46, pages/_demographics.scss */
  .demographics .demographic .row {
    display: -ms-flexbox;
    display: flex;
    font-size: 14px;
  }
  /* line 49, pages/_demographics.scss */
  .demographics .demographic .row:not(:last-of-type) {
    margin-bottom: 10px;
  }
  /* line 53, pages/_demographics.scss */
  .demographics .demographic .row-label {
    -ms-flex-preferred-size: calc(46% - 15px);
    flex-basis: calc(46% - 15px);
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    text-align: right;
    padding-right: 15px;
  }
  /* line 60, pages/_demographics.scss */
  .demographics .demographic .row-label + .row-value {
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    padding-right: 48px;
    text-align: right;
  }
  /* line 66, pages/_demographics.scss */
  .demographics .demographic .bar-hold {
    -ms-flex: 1 0 32%;
    flex: 1 0 32%;
  }
  /* line 69, pages/_demographics.scss */
  .demographics .demographic .bar {
    height: 20px;
    width: 50%;
    background-color: #00a94e;
  }
  /* line 74, pages/_demographics.scss */
  .demographics .demographic .row-value {
    padding-right: 9px;
  }
  /* line 77, pages/_demographics.scss */
  .demographics .demographic .row-percent {
    -ms-flex-preferred-size: 30px;
    flex-basis: 30px;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-left: 9px;
  }

  /* line 2, pages/_comparison.scss */
  .comparison-page:after {
    content: '';
    display: table;
    clear: both;
  }
  /* line 8, pages/_comparison.scss */
  .comparison-page .row {
    display: none;
  }
  /* line 12, pages/_comparison.scss */
  .comparison-page .comparison-container {
    display: table;
    width: 100%;
    box-sizing: border-box;
    margin-top: 240px;
    padding-right: 50px;
  }
  /* line 20, pages/_comparison.scss */
  .comparison-page .th,
  .comparison-page .td {
    box-sizing: border-box;
    position: relative;
    width: 45px;
  }
  /* line 25, pages/_comparison.scss */
  .comparison-page .th:first-child,
  .comparison-page .td:first-child {
    width: auto;
  }
  /* line 30, pages/_comparison.scss */
  .comparison-page .thead {
    display: -ms-flexbox;
    display: flex;
    display: table-header-group;
  }
  /* line 34, pages/_comparison.scss */
  .comparison-page .thead .th {
    display: table-cell;
    border-right: 1px solid #979797;
    border-bottom: 1px solid black;
    padding: 8px;
    font-size: 12px;
  }
  /* line 42, pages/_comparison.scss */
  .comparison-page .thead .th.icon span {
    color: #00a94e;
  }
  /* line 47, pages/_comparison.scss */
  .comparison-page .thead .th span {
    position: absolute;
    left: 40%;
    bottom: 0px;
    white-space: nowrap;
    display: block;
    -ms-transform-origin: 0;
    transform-origin: 0;
    -ms-transform: rotate(-55deg);
    transform: rotate(-55deg);
  }
  /* line 57, pages/_comparison.scss */
  .comparison-page .thead .th:last-child {
    border-right: none;
  }
  /* line 63, pages/_comparison.scss */
  .comparison-page .tbody {
    display: table-row-group;
  }
  /* line 66, pages/_comparison.scss */
  .comparison-page .tbody .td {
    display: table-cell;
    border-right: 1px solid #979797;
    box-sizing: border-box;
    padding: 0;
    height: 50px;
    position: relative;
    font-size: 14px;
  }
  /* line 75, pages/_comparison.scss */
  .comparison-page .tbody .td:first-child {
    font-weight: bold;
  }
  /* line 78, pages/_comparison.scss */
  .comparison-page .tbody .td:first-child .td-content {
    padding-right: 20px;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  /* line 84, pages/_comparison.scss */
  .comparison-page .tbody .td:last-child {
    border-right: none;
  }
  /* line 88, pages/_comparison.scss */
  .comparison-page .tbody .td .td-content {
    display: -ms-flexbox;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    /*position: absolute;*/
  }
  /* line 98, pages/_comparison.scss */
  .comparison-page .tbody .td span {
    display: block;
    font-weight: bold;
    text-align: center;
  }
  /* line 103, pages/_comparison.scss */
  .comparison-page .tbody .td span.small {
    font-size: 9px;
  }
  /* line 111, pages/_comparison.scss */
  .comparison-page .tr {
    box-sizing: border-box;
    display: table-row;
    width: 100%;
    height: 50px;
  }
  /* line 117, pages/_comparison.scss */
  .comparison-page .tr:nth-child(2n) {
    background: rgba(241, 239, 239, 0.98);
  }
  /* line 121, pages/_comparison.scss */
  .comparison-page .tr.no-zebra {
    background: transparent;
  }
  /* line 126, pages/_comparison.scss */
  .comparison-page .short {
    height: 29px;
    display: none;
  }
  /* line 130, pages/_comparison.scss */
  .comparison-page .short .td {
    height: 29px;
  }
  /* line 136, pages/_comparison.scss */
  .comparison-page .totals-start {
    font-weight: bold;
    height: 40px;
  }
  /* line 140, pages/_comparison.scss */
  .comparison-page .totals-start .td:first-child {
    text-transform: uppercase;
    text-align: right;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  /* line 146, pages/_comparison.scss */
  .comparison-page .totals-start .td:first-child .td-content {
    max-width: 100px;
  }
  /* line 151, pages/_comparison.scss */
  .comparison-page .totals-start .td {
    height: 40px;
  }
  /* line 154, pages/_comparison.scss */
  .comparison-page .totals-start .td .td-content {
    border-top: 1px solid #979797;
  }
  /* line 160, pages/_comparison.scss */
  .comparison-page .totals-body {
    height: 40px;
  }
  /* line 164, pages/_comparison.scss */
  .comparison-page .totals-body .td {
    height: 40px;
  }
  /* line 169, pages/_comparison.scss */
  .comparison-page .icon-value {
    border-radius: 28px;
    height: 28px;
    width: 28px;
    text-align: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    color: white;
  }
  /* line 180, pages/_comparison.scss */
  .comparison-page .icon-value.strength {
    background: #00a94e;
  }
  /* line 184, pages/_comparison.scss */
  .comparison-page .icon-value.growth-opportunity {
    background: #d2252f;
  }
  /* line 188, pages/_comparison.scss */
  .comparison-page .icon-value.area-for-investigation {
    background: #ffb320;
  }
  /* line 193, pages/_comparison.scss */
  .comparison-page i {
    display: block;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 29px;
    width: 29px;
    height: 29px;
    position: relative;
    top: 8px;
  }
  /* line 204, pages/_comparison.scss */
  .comparison-page i.check {
    background-image: url('/assets/images/checkmark_green.svg');
  }
  /* line 208, pages/_comparison.scss */
  .comparison-page i.strength {
    background-image: url('/assets/images/comparison/strength.svg');
  }
  /* line 212, pages/_comparison.scss */
  .comparison-page i.growth-opportunity {
    background-image: url('/assets/images/comparison/growth-opportunity.svg');
  }
  /* line 216, pages/_comparison.scss */
  .comparison-page i.area-for-investigation {
    background-image: url('/assets/images/comparison/area-for-investigation.svg');
  }

  /* line 222, pages/_comparison.scss */
  .comparison-key {
    margin-top: 40px;
    width: 483px;
    float: right;
  }
  /* line 227, pages/_comparison.scss */
  .comparison-key h2 {
    text-align: center;
    text-transform: uppercase;
    border-bottom: 1px solid #979797;
    font-size: 14px;
    padding-bottom: 10px;
    margin: 0;
  }
  /* line 236, pages/_comparison.scss */
  .comparison-key .keys {
    display: -ms-flexbox;
    display: flex;
    margin: 10px auto;
    -ms-flex-pack: center;
    justify-content: center;
  }
  /* line 242, pages/_comparison.scss */
  .comparison-key .key {
    position: relative;
    width: 61px;
    margin-right: 60px;
  }
  /* line 247, pages/_comparison.scss */
  .comparison-key .key:last-child {
    margin-right: 0;
  }
  /* line 251, pages/_comparison.scss */
  .comparison-key .key i {
    left: 50%;
    margin-left: -15px;
    position: absolute;
  }
  /* line 257, pages/_comparison.scss */
  .comparison-key .key label {
    font-size: 10px;
    font-weight: bold;
    margin-top: 30px;
    display: block;
    text-align: center;
  }

  /* line 267, pages/_comparison.scss */
  #total-row-1 .td-content {
    position: static;
  }

  /* Colors */
  /* Sizes */
  /* line 2, pages/analyze/_navigation.scss */
  .analyze .navigation {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 100px;
    font-weight: bold;
  }
  /* line 8, pages/analyze/_navigation.scss */
  .analyze .navigation .next,
  .analyze .navigation .previous {
    min-width: 200px;
  }
  /* line 12, pages/analyze/_navigation.scss */
  .analyze .navigation button {
    box-shadow: none;
    width: 67px;
    height: 33px;
    font-size: 13px;
    color: white;
    text-transform: none;
    padding: 0;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    border-radius: 0;
    margin: 0 20px;
    font-weight: normal;
    position: relative;
    background-color: transparent;
  }
  /* line 30, pages/analyze/_navigation.scss */
  .analyze .navigation button span {
    position: relative;
    z-index: 1;
  }
  /* line 35, pages/analyze/_navigation.scss */
  .analyze .navigation button:before {
    z-index: 0;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 67px;
    height: 33px;
    background-image: url('/assets/images/analyze/navigation.svg');
  }
  /* line 47, pages/analyze/_navigation.scss */
  .analyze .navigation .previous {
    border-right: 1px solid #343131;
    padding-right: 30px;
  }
  /* line 51, pages/analyze/_navigation.scss */
  .analyze .navigation .previous.no-border {
    border-right: none;
  }
  /* line 55, pages/analyze/_navigation.scss */
  .analyze .navigation .previous button {
    padding-left: 5px;
  }
  /* line 60, pages/analyze/_navigation.scss */
  .analyze .navigation .next {
    padding-left: 30px;
  }
  /* line 63, pages/analyze/_navigation.scss */
  .analyze .navigation .next span {
    padding-right: 10px;
  }
  /* line 68, pages/analyze/_navigation.scss */
  .analyze .navigation .next button:before {
    -ms-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  /* line 74, pages/analyze/_navigation.scss */
  .analyze .navigation a {
    color: inherit;
    text-decoration: none;
  }
  /* line 80, pages/analyze/_navigation.scss */
  .analyze #assessment-id {
    display: none;
  }

  /* line 1, pages/analyze/_content.scss */
  .analyze-content {
    margin: 80px 0 60px 0;
  }
  /* line 4, pages/analyze/_content.scss */
  .analyze-content h2 {
    font-size: 24px;
    text-align: center;
  }
  /* line 9, pages/analyze/_content.scss */
  .analyze-content section.data,
  .analyze-content div.data {
    display: -ms-grid;
    display: grid;
    grid-template-columns: auto 1fr 1fr;
    -ms-grid-columns: auto auto auto auto auto auto auto auto auto auto auto
      auto auto auto auto auto auto auto auto auto auto auto;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }
  /* line 17, pages/analyze/_content.scss */
  .analyze-content table.data {
    table-layout: fixed;
  }
  /* line 21, pages/analyze/_content.scss */
  .analyze-content .data {
    min-width: 100%;
  }
  /* line 24, pages/analyze/_content.scss */
  .analyze-content .data > div {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  /* line 28, pages/analyze/_content.scss */
  .analyze-content .data > div:first-child {
    max-width: 32em;
  }
  /* line 32, pages/analyze/_content.scss */
  .analyze-content .data > div:nth-child(1) {
    -ms-grid-column: 1;
  }
  /* line 36, pages/analyze/_content.scss */
  .analyze-content .data > div:nth-child(2) {
    -ms-grid-column: 2;
  }
  /* line 40, pages/analyze/_content.scss */
  .analyze-content .data > div:nth-child(3) {
    -ms-grid-column: 3;
  }
  /* line 44, pages/analyze/_content.scss */
  .analyze-content .data > div:nth-child(4) {
    -ms-grid-column: 4;
  }
  /* line 48, pages/analyze/_content.scss */
  .analyze-content .data > div:nth-child(5) {
    -ms-grid-column: 5;
  }
  /* line 52, pages/analyze/_content.scss */
  .analyze-content .data > div:nth-child(6) {
    -ms-grid-column: 6;
  }
  /* line 56, pages/analyze/_content.scss */
  .analyze-content .data > div:nth-child(7) {
    -ms-grid-column: 7;
  }
  /* line 60, pages/analyze/_content.scss */
  .analyze-content .data > div:nth-child(8) {
    -ms-grid-column: 8;
  }
  /* line 64, pages/analyze/_content.scss */
  .analyze-content .data > div:nth-child(9) {
    -ms-grid-column: 9;
  }
  /* line 68, pages/analyze/_content.scss */
  .analyze-content .data > div:nth-child(10) {
    -ms-grid-column: 10;
  }
  /* line 72, pages/analyze/_content.scss */
  .analyze-content .data > div:nth-child(11) {
    -ms-grid-column: 11;
  }
  /* line 76, pages/analyze/_content.scss */
  .analyze-content .data > div:nth-child(12) {
    -ms-grid-column: 12;
  }
  /* line 80, pages/analyze/_content.scss */
  .analyze-content .data > div:nth-child(13) {
    -ms-grid-column: 13;
  }
  /* line 84, pages/analyze/_content.scss */
  .analyze-content .data > div:nth-child(14) {
    -ms-grid-column: 14;
  }
  /* line 88, pages/analyze/_content.scss */
  .analyze-content .data > div:nth-child(15) {
    -ms-grid-column: 15;
  }
  /* line 92, pages/analyze/_content.scss */
  .analyze-content .data > div:nth-child(16) {
    -ms-grid-column: 16;
  }
  /* line 96, pages/analyze/_content.scss */
  .analyze-content .data > div:nth-child(17) {
    -ms-grid-column: 17;
  }
  /* line 100, pages/analyze/_content.scss */
  .analyze-content .data > div:nth-child(18) {
    -ms-grid-column: 18;
  }
  /* line 104, pages/analyze/_content.scss */
  .analyze-content .data > div:nth-child(19) {
    -ms-grid-column: 19;
  }
  /* line 108, pages/analyze/_content.scss */
  .analyze-content .data > div:nth-child(20) {
    -ms-grid-column: 20;
  }
  /* line 112, pages/analyze/_content.scss */
  .analyze-content .data > div:nth-child(21) {
    -ms-grid-column: 21;
  }
  /* line 116, pages/analyze/_content.scss */
  .analyze-content .data > div:nth-child(22) {
    -ms-grid-column: 22;
  }
  /* line 121, pages/analyze/_content.scss */
  .analyze-content .data .loading-data {
    text-align: center;
    padding-bottom: 40px;
  }
  /* line 126, pages/analyze/_content.scss */
  .analyze-content .data .principle-scores {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -ms-flex-line-pack: end;
    align-content: flex-end;
    padding-right: 20px;
    height: 10em;
  }
  /* line 134, pages/analyze/_content.scss */
  .analyze-content .data .principle-scores .header-score {
    text-align: right;
  }
  /* line 139, pages/analyze/_content.scss */
  .analyze-content .data .add-another {
    background: url('/assets/images/analyze/add-another.svg');
    background-position: top center;
    background-size: 3.5em;
    background-repeat: no-repeat;
    padding-top: 3.85em;
    text-align: center;
    text-transform: none;
    box-shadow: none;
    font-size: 14px;
    color: #343131;
    font-weight: normal;
    width: 100%;
  }
  /* line 155, pages/analyze/_content.scss */
  .analyze-content .data .column-head {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: auto 1.75em;
    grid-template-columns: auto 1.75em;
    -ms-grid-rows: auto 1fr auto;
    grid-template-rows: auto 1fr auto;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0 10px;
    height: 10em;
  }
  /* line 163, pages/analyze/_content.scss */
  .analyze-content .data .column-head h4 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }
  /* line 168, pages/analyze/_content.scss */
  .analyze-content .data .column-head p {
    font-size: 14px;
    line-height: 22px;
    -ms-grid-column: 1;
    grid-column-start: 1;
    grid-column-end: 3;
    max-height: 8em;
    overflow: auto;
    margin-bottom: 0;
    -ms-grid-row: 2;
  }
  /* line 179, pages/analyze/_content.scss */
  .analyze-content .data .column-head .header-score {
    margin-top: auto;
    margin-bottom: 0;
    -ms-grid-column: 1;
    grid-column-start: 1;
    grid-column-end: 3;
    -ms-grid-row: 3;
  }
  /* line 186, pages/analyze/_content.scss */
  .analyze-content .data .column-head .header-score div {
    margin-bottom: 5px;
  }
  /* line 191, pages/analyze/_content.scss */
  .analyze-content .data .column-head .remove-column {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }
  /* line 195, pages/analyze/_content.scss */
  .analyze-content .data .column-head .remove-column img {
    width: 1.5em;
    height: 1.5em;
    margin-top: -8px;
    position: relative;
    top: 4px;
    left: 10px;
  }
  /* line 206, pages/analyze/_content.scss */
  .analyze-content .data .column-graph {
    padding-right: 10px;
    padding-top: 116px;
    font-size: 14px;
    background: rgba(241, 239, 239, 0.98);
    margin-bottom: 40px;
    padding-bottom: 37px;
    min-height: 15.5em;
    //box-sizing: content-box;
  }
  /* line 214, pages/analyze/_content.scss */
  .analyze-content .data .column-graph.question-double-6 {
    height: 16em;
  }
  /* line 217, pages/analyze/_content.scss */
  .analyze-content .data .column-graph.question-double-6:nth-child(odd) {
    padding-top: 115px;
    padding-bottom: 12px;
    margin-bottom: 0;
  }
  /* line 223, pages/analyze/_content.scss */
  .analyze-content .data .column-graph.question-double-6:nth-child(even) {
    padding-top: 52px;
  }

  .analyze-content .data .column-list {
    background-color: rgba(241, 239, 239, 0.98);
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 37px;
    font-size: 14px;
    margin-bottom: 40px;
    height: 25.5em;

    &.question-single-undefined {
      height: 30em;
      overflow: auto;

      ul {
        li {
          list-style-type: disc;
          padding-bottom: 0.5em;
        }
      }
    }
  }
  /* line 228, pages/analyze/_content.scss */
  .analyze-content .data .column-graph.question-single-2 {
    height: 16.357142857em;
  }
  /* line 232, pages/analyze/_content.scss */
  .analyze-content .data .column-graph.question-single-3 {
    height: 18.642857142em;
  }
  /* line 236, pages/analyze/_content.scss */
  .analyze-content .data .column-graph.question-single-4 {
    height: 20.92857142em;
  }
  /* line 240, pages/analyze/_content.scss */
  .analyze-content .data .column-graph.question-single-5 {
    height: 23.214285714em;
  }
  /* line 244, pages/analyze/_content.scss */
  .analyze-content .data .column-graph.question-single-6 {
    height: 25.5em;
  }
  /* line 248, pages/analyze/_content.scss */
  .analyze-content .data .column-graph.question-single-7 {
    height: 27.857142857142em;
  }
  /* line 252, pages/analyze/_content.scss */
  .analyze-content .data .column-graph.question-single-8 {
    height: 30.07142857em;
  }
  /* line 256, pages/analyze/_content.scss */
  .analyze-content .data .column-graph.question-single-9 {
    height: 32.35714285714em;
  }
  .analyze-content .data .column-graph.question-single-10 {
    height: 35.14285714em;
  }
  .analyze-content .data .column-graph.question-single-11 {
    height: 37.85714285em;
  }
  .analyze-content .data .column-graph.question-single-12 {
    height: 40.571428571em;
  }
  .analyze-content .data .column-graph.question-single-14 {
    height: 46em;
  }
  .analyze-content .data .column-graph.question-single-16 {
    height: 50em;
  }
  .analyze-content .data .column-graph.question-single-47 {
    height: 119.2142857em;
  }
  .analyze-content .data .column-graph.question-single-51 {
    height: 130em;
  }
  .analyze-content .data .column-graph.question-single-52 {
    height: 130em;
  }

  /* line 264, pages/analyze/_content.scss */
  .analyze-content .data .column-graph .g-bar-row:first-child {
    margin-top: 0;
  }
  /* line 268, pages/analyze/_content.scss */
  .analyze-content .data .column-graph .g-bar-row:last-child {
    margin-bottom: 0;
  }
  /* line 273, pages/analyze/_content.scss */
  .analyze-content .data .question {
    font-size: 14px;
    background: rgba(241, 239, 239, 0.98);
    padding: 20px;
    margin-bottom: 40px;
    //box-sizing: content-box;
  }
  /* line 279, pages/analyze/_content.scss */
  .analyze-content .data .question.question-double-6 {
    height: 25.5em;
  }
  .analyze-content .data .question.question-single-undefined {
    height: 30em;
    overflow: auto;
  }
  /* line 283, pages/analyze/_content.scss */
  .analyze-content .data .question.question-single-2 {
    height: 16.357142857em;
  }
  /* line 287, pages/analyze/_content.scss */
  .analyze-content .data .question.question-single-3 {
    height: 18.642857142em;
  }
  /* line 291, pages/analyze/_content.scss */
  .analyze-content .data .question.question-single-4 {
    height: 20.92857142em;
  }
  /* line 295, pages/analyze/_content.scss */
  .analyze-content .data .question.question-single-5 {
    height: 23.214285714em;
  }
  /* line 299, pages/analyze/_content.scss */
  .analyze-content .data .question.question-single-6 {
    height: 25.5em;
  }
  /* line 303, pages/analyze/_content.scss */
  .analyze-content .data .question.question-single-7 {
    height: 27.857142857142em;
  }
  /* line 307, pages/analyze/_content.scss */
  .analyze-content .data .question.question-single-8 {
    height: 30.07142857em;
  }
  /* line 311, pages/analyze/_content.scss */
  .analyze-content .data .question.question-single-9 {
    height: 32.35714285714em;
  }
  .analyze-content .data .question.question-single-10 {
    height: 35.14285714em;
  }
  .analyze-content .data .question.question-single-11 {
    height: 37.85714285em;
  }
  .analyze-content .data .question.question-single-12 {
    height: 40.571428571em;
  }
  .analyze-content .data .question.question-single-14 {
    height: 46em;
  }
  .analyze-content .data .question.question-single-16 {
    height: 50em;
  }
  /* line 315, pages/analyze/_content.scss */
  .analyze-content .data .question.question-single-47 {
    height: 119.2142857em;
  }
  .analyze-content .data .question.question-single-51 {
    height: 130em;
  }
  .analyze-content .data .question.question-single-52 {
    height: 130em;
  }

  /* line 320, pages/analyze/_content.scss */
  .analyze-content .data .question .title p {
    line-height: 18px;
    font-size: 14px;
    height: 54px;
    margin-bottom: 0px;
    overflow: auto;
  }
  /* line 327, pages/analyze/_content.scss */
  .analyze-content .data .question .title p.related-questions {
    height: 32px;
  }
  /* line 333, pages/analyze/_content.scss */
  .analyze-content .data .question .question-responses {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 40px;
  }
  /* line 338, pages/analyze/_content.scss */
  .analyze-content .data .question .question-responses:last-child {
    margin-bottom: 0;
  }
  /* line 342, pages/analyze/_content.scss */
  .analyze-content
    .data
    .question
    .question-responses.question-responses-single {
    -ms-grid-columns: auto;
    grid-template-columns: auto;
  }
  /* line 347, pages/analyze/_content.scss */
  .analyze-content .data .question .question-responses div:nth-child(1) {
    -ms-grid-column: 1;
  }
  /* line 351, pages/analyze/_content.scss */
  .analyze-content .data .question .question-responses div:nth-child(2) {
    -ms-grid-column: 2;
  }
  /* line 356, pages/analyze/_content.scss */
  .analyze-content .data .question .question-responses .labels {
    text-align: right;
  }
  /* line 358, pages/analyze/_content.scss */
  .analyze-content .data .question .question-responses .labels div {
    height: 20px;
    margin: 12px 0px;
    line-height: 1em;
  }
  /* line 365, pages/analyze/_content.scss */
  .analyze-content .data .question .question-responses .title-section {
    box-sizing: border-box;
    padding-right: 40px;
    background-image: url('/assets/images/analyze/row_arrow.svg');
    background-repeat: no-repeat;
    background-position: right 50%;
    text-transform: uppercase;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-align: center;
    align-items: center;
    font-weight: bold;
  }
  /* line 380, pages/analyze/_content.scss */
  .analyze-content .data .compare-filter {
    padding: 20px;
    background: rgba(241, 239, 239, 0.98);
    display: block;
  }
  /* line 385, pages/analyze/_content.scss */
  .analyze-content .data .compare-filter h3 {
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 0.75em;
  }
  /* line 391, pages/analyze/_content.scss */
  .analyze-content .data .compare-filter label {
    width: 100%;
    margin: 15px 0;
  }
  /* line 396, pages/analyze/_content.scss */
  .analyze-content .data .compare-filter input[type='text'] {
    margin-bottom: 0.5em;
  }
  /* line 400, pages/analyze/_content.scss */
  .analyze-content .data .compare-filter .subset-selection {
    margin-left: 48px;
  }
  /* line 405, pages/analyze/_content.scss */
  .analyze-content .data thead {
    font-size: 16px;
  }
  /* line 408, pages/analyze/_content.scss */
  .analyze-content .data thead th {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    text-align: left;
  }
  /* line 414, pages/analyze/_content.scss */
  .analyze-content .data thead th.title {
    width: 230px;
    text-align: right;
    vertical-align: bottom;
  }
  /* line 419, pages/analyze/_content.scss */
  .analyze-content .data thead th.labels {
    width: 280px;
  }
  /* line 422, pages/analyze/_content.scss */
  .analyze-content .data thead th p {
    margin-bottom: 0;
  }
  /* line 428, pages/analyze/_content.scss */
  .analyze-content .data .column {
    border-left: 1px solid #979797;
  }
  /* line 433, pages/analyze/_content.scss */
  .analyze-content .data tr.bumper td {
    height: 40px;
  }
  /* line 438, pages/analyze/_content.scss */
  .analyze-content .data tr.question {
    font-size: 14px;
    background: rgba(241, 239, 239, 0.98);
  }
  /* line 442, pages/analyze/_content.scss */
  .analyze-content .data tr.question > td {
    padding: 20px;
  }
  /* line 446, pages/analyze/_content.scss */
  .analyze-content .data tr.question .column-graph {
    padding-left: 0;
    padding-right: 0;
  }
  /* line 450, pages/analyze/_content.scss */
  .analyze-content .data tr.question .title {
    box-sizing: border-box;
    padding-right: 40px;
  }
  /* line 453, pages/analyze/_content.scss */
  .analyze-content .data tr.question .title p {
    font-size: 14px;
    line-height: 18px;
  }
  /* line 457, pages/analyze/_content.scss */
  .analyze-content .data tr.question .title p > a {
    cursor: pointer;
  }
  /* line 461, pages/analyze/_content.scss */
  .analyze-content .data tr.question .title .question-bold {
    font-weight: bold;
  }
  /* line 465, pages/analyze/_content.scss */
  .analyze-content .data tr.question .title-section {
    box-sizing: border-box;
    padding-right: 40px;
    background-image: url('/assets/images/analyze/row_arrow.svg');
    background-repeat: no-repeat;
    background-position: right 50%;
    text-transform: uppercase;
  }
  /* line 474, pages/analyze/_content.scss */
  .analyze-content .data tr.question .labels {
    margin: 0;
    padding: 0;
    line-height: 30px;
    margin-left: 40px;
    text-align: right;
    padding-right: 20px;
  }
  /* line 482, pages/analyze/_content.scss */
  .analyze-content .data tr.question .labels li {
    margin: 0;
    padding: 0;
  }
  /* line 487, pages/analyze/_content.scss */
  .analyze-content .data tr.question .labels table {
    table-layout: fixed;
  }
  /* line 490, pages/analyze/_content.scss */
  .analyze-content .data tr.question .labels table tr td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  /* line 495, pages/analyze/_content.scss */
  .analyze-content .data tr.question .labels table tr td abbr {
    text-decoration: none;
  }
  /* line 506, pages/analyze/_content.scss */
  .analyze-content .compare-question {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  /* line 510, pages/analyze/_content.scss */
  .analyze-content .compare-question p {
    margin-bottom: 0;
  }
  /* line 514, pages/analyze/_content.scss */
  .analyze-content .header-label .header-score {
    font-weight: bold;
    text-align: right;
  }
  /* line 518, pages/analyze/_content.scss */
  .analyze-content .header-score {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: normal;
  }
  /* line 523, pages/analyze/_content.scss */
  .analyze-content .question-score {
    float: right;
    width: 37px;
    padding-right: 3px;
    text-align: right;
    font-weight: bold;
  }
  /* line 531, pages/analyze/_content.scss */
  .analyze-content .g-bar-row {
    height: 20px;
    margin: 12px 0;
    width: 100%;
    text-align: right;
  }
  /* line 537, pages/analyze/_content.scss */
  .analyze-content .g-bar-hold {
    float: left;
    width: calc(100% - 55px);
  }
  /* line 541, pages/analyze/_content.scss */
  .analyze-content .g-bar {
    float: left;
    height: 20px;
    min-width: 1px;
    max-width: 100%;
    background-color: #00a94e;
  }
  /* line 548, pages/analyze/_content.scss */
  .analyze-content .g-bar-label {
    display: inline-block;
    width: 37px;
    padding-top: 3px;
    padding-right: 3px;
    text-align: right;
  }

  /* Colors */
  /* Sizes */
  /* line 4, pages/_application.scss */
  .logo.logo-application img {
    max-height: 115px;
  }
  @media only screen and (max-width: 1024px) {
    /* line 4, pages/_application.scss */
    .logo.logo-application img {
      max-height: 75px;
    }
  }

  /* line 12, pages/_application.scss */
  body .page-container .application-description.application-description {
    margin-top: -10px;
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 1024px) {
    /* line 12, pages/_application.scss */
    body .page-container .application-description.application-description {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  /* line 20, pages/_application.scss */
  body .page-container .application-description.application-description > p {
    font-size: 24px;
  }
  @media only screen and (max-width: 1024px) {
    /* line 20, pages/_application.scss */
    body .page-container .application-description.application-description > p {
      font-size: 18px;
      max-width: initial;
    }
  }

  /* line 30, pages/_application.scss */
  .page-left .application-padding {
    padding-right: 20px;
  }

  /* line 33, pages/_application.scss */
  .page-right .application-padding {
    padding-left: 20px;
  }

  /* line 37, pages/_application.scss */
  .btn-array {
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    border: solid 1px #aaa;
    border-radius: 8px;
    overflow: hidden;
  }
  /* line 45, pages/_application.scss */
  .btn-array .btn-array-item {
    height: 75px;
    -ms-flex: 1 1;
    flex: 1 1;
    text-align: center;
    font-size: 14px;
    overflow: hidden;
  }
  /* line 52, pages/_application.scss */
  .btn-array .btn-array-item:not(:last-of-type) {
    border-right: solid 1px #aaa;
  }
  /* line 55, pages/_application.scss */
  .btn-array .btn-array-item .btn-array-text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    height: 100%;
    line-height: normal;
    z-index: 1;
  }
  /* line 64, pages/_application.scss */
  .btn-array .btn-array-item .btn-array-text:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  /* line 75, pages/_application.scss */
  .btn-array .btn-array-item:hover .btn-array-text {
    color: #fff;
  }
  /* line 78, pages/_application.scss */
  .btn-array .btn-array-item:hover .btn-array-text:before {
    background-color: #d2252f;
  }
  /* line 83, pages/_application.scss */
  .btn-array .btn-array-item input[type='radio'] {
    position: absolute;
    opacity: 0;
  }
  /* line 87, pages/_application.scss */
  .btn-array .btn-array-item input[type='radio']:checked ~ .btn-array-text {
    color: #fff;
  }
  /* line 90, pages/_application.scss */
  .btn-array
    .btn-array-item
    input[type='radio']:checked
    ~ .btn-array-text:before {
    background-color: #d2252f;
  }
  @media only screen and (max-width: 1024px) {
    /* line 37, pages/_application.scss */
    .btn-array {
      -ms-flex-direction: column;
      flex-direction: column;
    }
    /* line 100, pages/_application.scss */
    .btn-array .btn-array-item {
      -ms-flex-preferred-size: 60px;
      flex-basis: 60px;
    }
    /* line 103, pages/_application.scss */
    .btn-array .btn-array-item:not(:last-of-type) {
      border-right: none;
      border-bottom: solid 1px #aaa;
    }
  }

  /* line 111, pages/_application.scss */
  .custom-checkbox {
    display: inline-block;
    position: relative;
    height: 24px;
    width: 24px;
    margin-right: 15px;
  }
  /* line 118, pages/_application.scss */
  .custom-checkbox > input {
    position: absolute;
    opacity: 0;
  }
  /* line 122, pages/_application.scss */
  .custom-checkbox + label {
    display: inline-block;
  }

  /* line 126, pages/_application.scss */
  .custom-checkbox-state {
    position: relative;
    display: inline-block;
    height: 23px;
    width: 23px;
    margin-right: 8px;
    border-style: solid;
    border-width: 1px;
    border-color: #aaa;
    background-color: #fff;
  }
  /* line 137, pages/_application.scss */
  .custom-checkbox-state:before {
    content: '';
    position: absolute;
    top: 11px;
    left: 6px;
    height: 7px;
    width: 3px;
    background-color: #fff;
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  /* line 147, pages/_application.scss */
  .custom-checkbox-state:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 12px;
    height: 12px;
    width: 3px;
    background-color: #fff;
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  /* line 158, pages/_application.scss */
  .custom-checkbox > input:focus ~ .custom-checkbox-state {
    box-shadow: 0 0 3px 2px rgba(210, 37, 47, 0.5);
  }

  /* line 161, pages/_application.scss */
  .custom-checkbox > input:checked ~ .custom-checkbox-state {
    background-color: #d2252f;
    border-color: #bce;
  }

  /* line 165, pages/_application.scss */
  .checkbox-hold {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 20px;
  }
  /* line 170, pages/_application.scss */
  .checkbox-hold > label:nth-of-type(2) {
    font-size: 16px;
    font-weight: bold;
  }

  /* line 176, pages/_application.scss */
  .label-title {
    display: block;
    margin-top: 15px;
    font-size: 16px;
    font-weight: bold;
  }

  /* line 184, pages/_application.scss */
  .application-form.application-form input[type='text'],
  .application-form.application-form input[type='number'],
  .application-form.application-form input[type='email'],
  .application-form.application-form input[type='tel'] {
    padding: 14px;
  }
  /* line 191, pages/_application.scss */
  .application-form.application-form .section {
    margin-bottom: 40px;
    padding-bottom: 40px;
  }
  /* line 196, pages/_application.scss */
  .application-form.application-form .selectric .label {
    height: 29px;
    line-height: 32px;
  }
  /* line 200, pages/_application.scss */
  .application-form.application-form .selectric-items {
    margin: -51px 0 0 0;
    padding: 50px 0 0 0;
  }
  /* line 204, pages/_application.scss */
  .application-form.application-form .selectric-items .selectric-scroll {
    width: calc(100% - 0.5px);
  }
  @media only screen and (max-width: 1024px) {
    /* line 209, pages/_application.scss */
    .application-form.application-form .page-flex {
      display: block;
    }
    /* line 212, pages/_application.scss */
    .application-form.application-form .application-padding {
      padding: 0;
    }
    /* line 215, pages/_application.scss */
    .application-form.application-form h2 {
      font-size: 24px;
    }
  }

  /* line 221, pages/_application.scss */
  .submit-center {
    text-align: center;
  }

  /* line 225, pages/_application.scss */
  .fakeinput {
    display: inline-block;
    min-width: 85px;
    margin-left: 18px;
    padding: 14px;
    border: 1px solid #979797;
    border-radius: 5px;
    font-size: 18px;
    box-sizing: border-box;
    background-color: #fff;
    line-height: normal;
    font-weight: normal;
  }

  /* line 239, pages/_application.scss */
  .side-label {
    margin: 30px 0;
    font-weight: bold;
  }

  /* line 243, pages/_application.scss */
  .application-code.application-code {
    width: 100px;
    margin-right: 18px;
  }

  /* line 248, pages/_application.scss */
  .application-shortinp {
    max-width: 250px;
  }
  @media only screen and (max-width: 1024px) {
    /* line 248, pages/_application.scss */
    .application-shortinp {
      max-width: initial;
    }
  }

  /* line 256, pages/_application.scss */
  .inst-info {
    margin-bottom: 20px;
    color: #d2252f;
  }

  /* line 260, pages/_application.scss */
  .inst-hold {
    display: -ms-flexbox;
    display: flex;
    width: 435px;
    margin: 20px 0;
  }

  /* line 265, pages/_application.scss */
  .inst-name {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-width: 0;
    margin-right: -5px;
    padding: 15px 0 0 15px;
    border: solid 1px #979797;
    border-right: none;
    border-radius: 5px 0 0 5px;
    cursor: not-allowed;
    white-space: nowrap;
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* line 279, pages/_application.scss */
  .inst-add {
    -ms-flex: 0 0 188px;
    flex: 0 0 188px;
    margin: 0;
    padding: 15px 0;
    text-align: center;
    background-color: #d2252f;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    line-height: normal;
  }

  /* Colors */
  /* line 3, pages/_charts.scss */
  .button-group.graph-button-group {
    width: auto;
  }

  /* line 8, pages/_charts.scss */
  .graph .button-group {
    margin-top: 40px;
    max-width: 500px;
    min-width: 320px;
  }
  /* line 13, pages/_charts.scss */
  .graph .button-group .button {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 4em;
  }

  /* line 22, pages/_charts.scss */
  .graph-columns,
  .mirror-bar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  /* line 26, pages/_charts.scss */
  .graph-columns .graph,
  .mirror-bar .graph {
    -ms-flex-preferred-size: 100px;
    flex-basis: 100px;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  /* line 31, pages/_charts.scss */
  .graph-columns table,
  .mirror-bar table {
    -ms-flex-preferred-size: 100px;
    flex-basis: 100px;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 800px;
  }

  /* line 39, pages/_charts.scss */
  .line h3 {
    font-size: 24px;
    margin: 40px 0 10px;
  }
  /* line 44, pages/_charts.scss */
  .line h4 {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 40px;
  }
  /* line 51, pages/_charts.scss */
  .line .percentile-hold .percentile-line {
    height: 95%;
    margin-left: 0;
    transition: left 0.5s ease-in;
  }
  /* line 57, pages/_charts.scss */
  .line .percentile-hold .percentile-label {
    width: 100em;
    margin-left: -101em;
    right: 0;
    text-align: right;
    left: 0;
    transition: margin-left 0.5s ease-in, left 0.5s ease-in;
  }
  /* line 67, pages/_charts.scss */
  .line .data-buttons {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 40px;
  }
  /* line 73, pages/_charts.scss */
  .line .data-buttons .button-group {
    margin: 0;
  }

  /* line 82, pages/_charts.scss */
  .section-sidebar .mirror-bar .graph {
    margin-bottom: 100px;
    margin-right: 40px;
  }
  /* line 86, pages/_charts.scss */
  .section-sidebar .mirror-bar .graph .mirror-half,
  .section-sidebar .mirror-bar .graph .mirror-half-double {
    height: 200px;
  }
  /* line 91, pages/_charts.scss */
  .section-sidebar .mirror-bar table {
    display: table;
  }

  /* line 98, pages/_charts.scss */
  .line table,
  .graph-columns table,
  .section-sidebar table {
    border-top: solid #00a94e 7px;
  }
  /* line 102, pages/_charts.scss */
  .line table thead tr,
  .graph-columns table thead tr,
  .section-sidebar table thead tr {
    background-color: #00a94e;
  }
  /* line 106, pages/_charts.scss */
  .line table thead th,
  .graph-columns table thead th,
  .section-sidebar table thead th {
    font-size: 12px;
    color: white;
    padding: 10px 5px;
    border-left: solid white 1px;
  }
  /* line 112, pages/_charts.scss */
  .line table thead th:first-child,
  .graph-columns table thead th:first-child,
  .section-sidebar table thead th:first-child {
    font-size: 16px;
    padding-left: 10px;
    text-align: left;
    border-width: 0;
  }
  /* line 123, pages/_charts.scss */
  .line table tbody tr:nth-child(odd),
  .graph-columns table tbody tr:nth-child(odd),
  .section-sidebar table tbody tr:nth-child(odd) {
    background: none;
  }
  /* line 127, pages/_charts.scss */
  .line table tbody tr:nth-child(even),
  .graph-columns table tbody tr:nth-child(even),
  .section-sidebar table tbody tr:nth-child(even) {
    background: rgba(241, 239, 239, 0.98);
  }
  /* line 131, pages/_charts.scss */
  .line table tbody tr:last-child,
  .graph-columns table tbody tr:last-child,
  .section-sidebar table tbody tr:last-child {
    background: none;
  }
  /* line 135, pages/_charts.scss */
  .line table tbody td,
  .graph-columns table tbody td,
  .section-sidebar table tbody td {
    font-size: 12px;
    padding: 10px 5px;
    border-left: solid #999999 1px;
    text-align: center;
  }
  /* line 141, pages/_charts.scss */
  .line table tbody td:first-child,
  .graph-columns table tbody td:first-child,
  .section-sidebar table tbody td:first-child {
    border-width: 0;
    text-align: left;
    padding-left: 10px;
  }

  /* line 151, pages/_charts.scss */
  .supporting-information {
    background: rgba(241, 239, 239, 0.98);
    padding: 20px;
    margin-top: 80px;
  }
  /* line 156, pages/_charts.scss */
  .supporting-information h2 {
    background: #00a94e;
    padding: 20px;
    margin: -20px -20px 10px -20px;
    color: white;
  }
  /* line 163, pages/_charts.scss */
  .supporting-information .supporting-columns {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  /* line 169, pages/_charts.scss */
  .supporting-information .supporting-data {
    -ms-flex-preferred-size: 100px;
    flex-basis: 100px;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding-top: 10px;
    padding-right: 30px;
  }
  /* line 175, pages/_charts.scss */
  .supporting-information .supporting-data .button {
    width: auto;
    margin-right: 20px;
    margin-top: 0;
  }
  /* line 182, pages/_charts.scss */
  .supporting-information .supporting-charts {
    padding-top: 10px;
    padding-left: 30px;
    padding-bottom: 30px;
    -ms-flex-preferred-size: 100px;
    flex-basis: 100px;
    -ms-flex-positive: 1;
    flex-grow: 1;
    border-left: solid #999999 1px;
  }
  /* line 190, pages/_charts.scss */
  .supporting-information .supporting-charts .dashboard-chart {
    padding-left: 3.75em;
    line-height: 4em;
    background-size: 3em;
  }

  .edit-field {
    background-color: @brand-light-gray;
    margin-left: 0.5em;
  }

  .v-expansion-panel-header {
    font-size: 1em;
  }

  .page-container {
    .v-btn {
      white-space: normal;
    }
  }
</style>
