import Vue from 'vue'
import Router from 'vue-router'
import store from '@/vuex/store'
import Dashboard from './views/Dashboard.vue'
import Survey from './views/Survey.vue'
import Login from './views/Login.vue'

Vue.use(Router)

// var alertTimer = null
// function checkLogin(to, next) {
//   // get user from local storage to confirm logged in state
//   let user = JSON.parse(localStorage.getItem('user'))

//   // if logged in, allow nav, otherwise force login
//   if (user) {
//     next()
//   } else next({ name: 'login' })
// }

export default new Router({
  scrollBehavior() {
    return { x: 0, y: 0 }
  },

  // mode: 'history',
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: Dashboard,
      children: [
        {
          path: '/token/:token',
        },
      ],
      // beforeEnter: (to, from, next) => {
      //   checkLogin(to, next)
      // },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/assessment/:assessment_id/analyze/:page_type',
      name: 'survey',
      component: Survey,
      props: true,

      children: [
        {
          path: ':page_id',
          name: 'analyze',
        },
      ],
      // beforeEnter: (to, from, next) => {
      //   checkLogin(to, next)
      // },
    },
    // {
    //   path: '/assess/:domain',
    //   name: 'assessDomain',
    //   component: Assessment,
    //   children: [
    //     {
    //       path: ':capability',
    //       name: 'assessCapability',
    //     },
    //   ],
    //   beforeEnter: (to, from, next) => {
    //     setRestrictedActionsAlert(to, next)
    //   },
    // },
    // {
    //   path: '/learning-plan/goal/',
    //   redirect: '/learning-plan/goal/general-improvements/self-guided',
    // },
    // {
    //   path: '/learning-plan/goal/:goalType/:goal',
    //   name: 'learningGoalType',
    //   component: LearningGoal,

    //   beforeEnter: (to, from, next) => {
    //     setRestrictedActionsAlert(to, next)
    //   },
    // },
    // {
    //   path: '/learning-plan/resources',
    //   redirect: '/learning-plan/resources/personal',
    // },
    // {
    //   path: '/learning-plan/resources/:resourceDomain',
    //   name: 'learningResources',
    //   component: LearningResources,

    //   beforeEnter: (to, from, next) => {
    //     setRestrictedActionsAlert(to, next)
    //   },
    // },
    // {
    //   path: '/learning-plan',
    //   redirect: '/learning-plan/all-resources',
    // },
    // {
    //   path: '/learning-plan/:resourceType',
    //   name: 'learningPlan',
    //   component: LearningPlan,

    //   beforeEnter: (to, from, next) => {
    //     setRestrictedActionsAlert(to, next)
    //   },
    // },
    // {
    //   path: '/:domain',
    //   name: 'cm',
    //   component: CapabilityModel,
    //   children: [
    //     {
    //       path: ':capability',
    //     },
    //   ],
    // },
  ],
})
