<template>
  <v-expansion-panel class="assessment-details" v-show="assessment">
    <v-expansion-panel-header>
      <v-row no-gutters>
        <v-col cols="4" md="6" sm="4" lg="8" class="assessment-title">
          <h2>{{ assessment.title }}</h2>
        </v-col>

        <v-col cols="4" md="3" sm="4" lg="2">
          <v-icon color="#343131">school</v-icon>
          &nbsp;
          <span>Student Survey</span>
          &nbsp;
          <v-icon color="#00a94e" v-if="assessment.studentExtId">link</v-icon>
          <v-icon color="#00a94e" v-if="!assessment.studentExtId">
            link_off
          </v-icon>
        </v-col>

        <v-col cols="4" md="3" sm="4" lg="2">
          <v-icon color="#343131">menu_book</v-icon>
          &nbsp;
          <span>Institution Survey</span>
          &nbsp;
          <v-icon color="#00a94e" v-if="assessment.institutionExtId">
            link
          </v-icon>
          <v-icon color="#00a94e" v-if="!assessment.institutionExtId">
            link_off
          </v-icon>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12" md="8">
          <v-row>
            <v-col cols="12" md="8" lg="9" xl="10">
              <assessment-table
                :studentSurvey="getSurvey(assessment, 'student')"
                :institutionSurvey="getSurvey(assessment, 'institution')"
                :studentSplitSurvey="getSurvey(assessment, 'student', true)"
              ></assessment-table>
            </v-col>
            <v-col cols="12" md="4" lg="3" xl="2">
              <above-median
                v-if="getSurvey(assessment, 'student')"
                :value="getSurvey(assessment, 'student').aboveMedian"
              ></above-median>
            </v-col>
          </v-row>
          <top-questions
            :studentSurvey="getSurvey(assessment, 'student')"
          ></top-questions>
        </v-col>
        <v-col cols="12" md="4">
          <survey-details :assessment="assessment"></survey-details>
          <document-links :assessment="assessment"></document-links>
          <contacts :schoolId="school"></contacts>
          <classification-details
            :schoolId="school"
            :assessmentId="parseInt(assessment.id)"
          ></classification-details>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import AssessmentTable from '@/components/AssessmentTable'
  import AboveMedian from '@/components/AboveMedian'
  import TopQuestions from '@/components/TopQuestions'
  import SurveyDetails from '@/components/SurveyDetails'
  import DocumentLinks from '@/components/DocumentLinks'
  import Contacts from '@/components/Contacts'
  import ClassificationDetails from '@/components/ClassificationDetails'

  export default {
    name: 'AssessmentDetails',
    components: {
      AssessmentTable,
      AboveMedian,
      ClassificationDetails,
      DocumentLinks,
      Contacts,
      TopQuestions,
      SurveyDetails,
    },
    props: {
      assessmentData: {
        type: Object,
      },
    },
    data() {
      return {}
    },
    computed: {
      assessment() {
        if (this.assessmentData) {
          return this.assessmentData
        } else return false
      },
      school() {
        if (this.assessment) {
          return parseInt(this.assessment.school[0].id)
        } else return false
      },
    },
    methods: {},
    updated() {},
    mounted() {},
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';

  .assessment-details {
    margin: 0.5em 0;
    border-radius: 5px;
    border: solid @brand-mid-gray 1px;
    font-size: 0.875em;

    & > button {
      box-shadow: none;
      background: none;
      margin: 0;
    }

    h3 {
      font-size: 1.1428571em;
      text-transform: uppercase;
    }

    a,
    a:link,
    a:hover,
    a:focus,
    a:visited {
      color: @brand-green;
    }

    .v-expansion-panel-header {
      padding: 1.5em;

      span {
        font-weight: 600;
      }

      .assessment-title {
        display: flex;
        align-items: center;
        font-size: 1.142857em;

        h2 {
          font-size: 1.125em;
          color: @brand-green;
          text-transform: none;
        }
      }
    }
  }
</style>
