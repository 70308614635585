<template>
  <div class="compare-filter comparison-tool">
    <h3>Compare To:</h3>
    <input
      type="text"
      v-model="searchText"
      placeholder="Filter Assessments..."
      ref="filter"
    />
    <template v-for="filter in filtered">
      <input
        :id="`Filter-${filter.name}`"
        :key="`Filter-${filter.name}`"
        type="radio"
        name="filters"
        :value="filter.name"
        :checked="
          column.filter &&
          (filter.name === column.filter ||
            filter.name === column.filter.split('!!')[0])
        "
        @change="setFilter(filter.name)"
        class="styled radio"
      />
      <label
        :for="'Filter-' + filter.name"
        class
        :key="'Filter2-' + filter.name"
      >
        <i class></i>
        <span>{{ filter.title }}</span>
      </label>
      <select
        :name="`${filter.name}-selector`"
        :key="`${filter.name}-selector`"
        v-if="showSubselect(filter)"
        @change="setFilter(`${filter.name}!!${$event.target.value}`)"
      >
        <option value></option>
        <option
          v-for="choice in filter.subselectors"
          :selected="`${filter.name}!!${choice.key}` === column.filter"
          :value="choice.key"
          :key="choice.key"
        >
          {{ choice.title }}
        </option>
      </select>
    </template>
    <p>
      <a
        href
        @click.prevent="nextPage"
        :class="['button', { inactive: !column.filter }]"
      >
        Next: Select Subsets
      </a>
    </p>
    <p>
      <a href @click.prevent="cancel">Cancel</a>
    </p>
  </div>
</template>

<script>
  import _ from 'lodash'

  export default {
    name: 'FilterForm',
    props: ['column', 'next', 'cancel', 'filters'],
    computed: {
      filtered() {
        const self = this
        return _.filter(
          this.filters,
          (filter) =>
            filter.title
              .toUpperCase()
              .indexOf(self.searchText.toUpperCase()) !== -1
        )
      },
    },
    data() {
      return {
        searchText: '',
      }
    },
    methods: {
      setFilter(filter) {
        this.column.setFilter(filter)
      },

      nextPage() {
        if (this.column.filter) this.next()
      },

      showSubselect(filter) {
        return (
          filter.subselectors &&
          filter.subselectors.length &&
          this.column.filter &&
          (filter.name === this.column.filter ||
            filter.name === this.column.filter.split('!!')[0])
        )
      },

      focusOnLoad() {
        this.$refs.filter.focus()
      },
    },
    mounted() {
      this.focusOnLoad()
    },
  }
</script>
