<template>
  <div class="assessment-table">
    <v-data-table
      hide-default-footer
      no-data-text="No Scores Available"
      :headers="headers"
      :items="scoreTable"
      dense
    ></v-data-table>

    <!--<ul class="legend">
      <li>
        <v-icon class="strength">lens</v-icon>Strength
      </li>
      <li>
        <v-icon class="challenge">lens</v-icon>Challenge
      </li>
      <li>
        <v-icon class="disconnect">lens</v-icon>Disconnect
      </li>
    </ul>-->
  </div>
</template>

<script>
  export default {
    props: {
      studentSurvey: {
        type: Object,
      },
      studentSplitSurvey: {
        type: Object,
      },
      institutionSurvey: {
        type: Object,
      },
    },
    data() {
      return {
        headers: [
          {
            text: 'PRINCIPLES',
            align: 'start',
            value: 'title',
          },
          { text: 'Satisfaction', value: 'satisfaction' },
          { text: 'Median', value: 'median' },
          { text: 'Quality', value: 'quality' },
        ],
        scoreTable: [],
      }
    },
    methods: {
      scores() {
        //console.log('anything?')
        var sSurvey = this.studentSurvey
        var sSpSurvey = this.studentSplitSurvey
        var iSurvey = this.institutionSurvey

        //console.log('student: ' + sSurvey.principleScores)
        //console.log('institution: ' + iSurvey.principleScores)

        for (let principle of this.data.principles) {
          if (
            principle.title != 'About Me' &&
            principle.title != 'Additional Questions'
          ) {
            //console.log('principle: ' + principle.id)
            //console.log('score: ' + sSurvey.principleScores[principle.id].q.s)
            var sRound
            var iRound
            var mTotal = 0
            var mCount = 0

            //console.log(iSurvey.title)
            //console.log('sSurvey exists? ' + sSurvey)
            //console.log('sSpSurvey exists? ' + sSpSurvey)
            //console.log('iSurvey exists? ' + iSurvey)

            if (sSpSurvey) {
              if (sSpSurvey.principleScores[principle.id]) {
                sRound = sSpSurvey.principleScores[principle.id].q.s.toFixed(2)
              } else if (sSurvey.principleScores[principle.id]) {
                sRound = sSurvey.principleScores[principle.id].q.s.toFixed(2)
              } else {
                sRound = ''
              }
            } else if (sSurvey) {
              sRound = sSurvey.principleScores[principle.id]
                ? sSurvey.principleScores[principle.id].q.s.toFixed(2)
                : ''
            } else {
              sRound = ''
            }

            if (iSurvey) {
              iRound = iSurvey.principleScores[principle.id]
                ? iSurvey.principleScores[principle.id].q.s.toFixed(2)
                : ''
            }

            for (let q of principle.studentQuestions) {
              if (this.data.studentQuestionsMedian[q.id]) {
                mTotal += this.data.studentQuestionsMedian[q.id]
                mCount++
              }
            }

            // Only calculate median values if there are values to be counted
            if (mCount > 0) {
              var mVal = mTotal / mCount
              var mRound = mVal.toFixed(2)
            } else {
              mRound = ''
            }

            let score = {
              title: principle.title,
              satisfaction: sRound,
              median: mRound,
              quality: iRound,
            }
            this.scoreTable.push(score)
          }
        }
      },
    },
    updated() {},
    mounted() {
      this.scores()
    },
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';

  .assessment-table {
    font-size: 0.8571428em;

    .v-icon {
      &.strength {
        color: @brand-green;
      }

      &.challenge {
        color: @brand-red;
      }

      &.disconnect {
        color: @brand-yellow;
      }
    }

    table {
      thead {
        th {
          border-width: 0 !important;
        }
      }
      tbody {
        tr {
          &:nth-child(odd) {
            background-color: @brand-green-pale;
          }

          &:nth-child(even) {
            background: none;
          }

          td {
            border-width: 0 !important;
          }
        }
      }
    }

    .legend {
      display: flex;
      flex-direction: row;
      margin: 2em 0 2em 0;
      padding: 0;

      li {
        display: flex;
        align-items: center;
        margin-right: 2em;

        .v-icon {
          margin-right: 0.1em;
        }
      }
    }
  }
</style>
