// Install html-loader in webpack `yarn add html-loader` //
https://gist.github.com/calebporzio/623c536f7fff77861b8a37ec11a1d3da#gistcomment-2671601
<template>
  <svg
    v-bind="svgAttributes"
    :id="svgId"
    :class="svgClass"
    :title="svgTitle"
    v-html="svgData"
  ></svg>
</template>

<script>
  import axios from 'axios'

  export default {
    props: ['img', 'svgId', 'svgClass', 'svgUrl', 'svgTitle'],
    data() {
      return {
        svgData: '',
        svgAttributes: {},
        prevSvgUrl: '',
        prevImg: '',
      }
    },
    mounted() {
      this.buildSvg()
    },
    updated() {
      this.buildSvg()
    },
    methods: {
      buildSvg() {
        if (this.prevSvgUrl != this.svgUrl || this.prevImg != this.img) {
          this.prevSvgUrl = this.svgUrl
          this.prevImg = this.img

          this.getSvg()
        }
      },
      getSvg() {
        if (this.svgUrl) {
          // load remote asset
          axios(this.svgUrl).then((response) => this.extractSvg(response.data))
        } else {
          // load local asset
          this
            .extractSvg
            //require('!!html-loader!./../assets/svg/' + this.img + '.svg') COMMENTING OUT BECAUSE THIS WAS BREAKING THINGS
            ()
        }
      },
      extractSvg(svg) {
        // get svg tag with attributes
        let svgTag = svg.match(/<\s*svg[^>]*>/g)[0]
        // find starting svg tag and calculate length
        const svgStart = svg.search(/<\s*svg[^>]*>/g) + svgTag.length
        // find end svg tag
        const svgEnd = svg.search(/<\s*\/\s*svg>/g)

        // strip svg to attributes
        svgTag = svgTag.replace('<svg', '')
        svgTag = svgTag.replace('>', ' ')

        // build attributes object
        let attributes = '{'
        let attName = ''
        let attValue = ''
        let sliceEnd = 0

        do {
          // get attribute name
          sliceEnd = svgTag.search('=')
          attName = svgTag.slice(0, sliceEnd).trim()
          svgTag = svgTag.substring(sliceEnd + 1)

          // get attribuet value
          sliceEnd = svgTag.search('" ')
          attValue = svgTag.slice(0, sliceEnd + 1).trim()
          svgTag = svgTag.substring(sliceEnd + 2)

          // build up attributes
          attributes += '"' + attName + '": ' + attValue + ', '
        } while (svgTag.search('=') != -1)

        // remove trailing command and add closing curly brace
        attributes = attributes.slice(0, attributes.length - 2) + ' }'

        // set component attributes and data
        this.svgAttributes = JSON.parse(attributes)
        this.svgData = svg.slice(svgStart, svgEnd)
      },
    },
  }
</script>
