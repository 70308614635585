import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/vuex/store'
import vuetify from '@/plugins/vuetify'
import axios from 'axios'
import vClickOutside from 'v-click-outside'
import Global from '@/mixins/global'
import VueAnalytics from 'vue-analytics'
import moment from 'moment'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'

Vue.config.productionTip = false

// Global Components
import SvgImg from '@/components/SvgImg'
Vue.component('svg-img', SvgImg)

// Add lodash
Vue.use(VueLodash, { lodash: lodash })

//Vue.use(VueAxios, axios)
Vue.use(vClickOutside)

// Setting up global mixins across app: https://freek.dev/823-using-global-mixins-in-vuejs
Vue.mixin(Global)

// Setup Google Analytics
Vue.use(VueAnalytics, {
  id: 'UA-90884303-2',
  router,
})

// Add vanilla javascript libraries globally to vue
Object.defineProperty(Vue.prototype, '$moment', { value: moment })

new Vue({
  router,
  store,
  axios,
  created() {
    this.$store.dispatch('stateLoad') // get app state
  },
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
