<template>
  <div class="above-median">
    <div class="above-median-stat">
      <h3>{{ (value * 100).toFixed(0) }}%</h3>
      <p>Above Median</p>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        type: Number,
        default: 0,
      },
    },
  }
</script>
<style lang="less">
  @import (reference) '~@/less/variables.less';

  .above-median {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    .above-median-stat {
      height: 9em;
      width: 9em;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      flex-direction: column;
      background-color: @brand-green-pale;
      border-radius: 50%;

      h3 {
        font-size: 2em;
        color: @brand-green;
        margin: 0;
        line-height: 1em;
      }

      p {
        color: @text-color;
        text-transform: uppercase;
        margin: 0;
        line-height: 1em;
        font-size: 0.7142857em;
        font-weight: 600;
      }
    }
  }
</style>
