<template>
  <div class="column dynamic-column">
    <div class="column-head" v-if="column.data">
      <a
        class="remove-column"
        href
        v-if="index !== 0"
        @click.prevent="removeColumn"
      >
        <img
          src="/assets/images/close.svg"
          :alt="'Remove ' + title + ' from this comparison'"
        />
      </a>
      <h4 v-if="index === 0">{{ title }}</h4>
      <h4 v-else tabindex="1" ref="columnTitle">
        <a href @click.prevent="setStep('FILTER')">{{ title }}</a>
      </h4>
      <p>
        Subset(s):
        <a href @click.prevent="setStep('SUBSET')">
          <abbr :title="subsets">{{ subsets }}</abbr>
        </a>
      </p>
      <div v-if="hasRows" class="header-score">
        <div>
          {{ getQuestionsScore(column.data.questions)
          }}{{ getPercentile(column.data.qualityPercentile) }}
        </div>
      </div>
    </div>
    <div v-else-if="column.empty && !step">
      <button class="add-another" @click="setStep('FILTER')">
        Add Another Data Set
      </button>
    </div>
    <div v-else class="column-head">
      <a href @click.prevent="setStep('FILTER')">{{ title }}</a>
    </div>

    <div v-if="column.data && !step">
      <template v-for="question in column.data.questions">
        <div
          :class="
            (question.data.answers ? 'column-list' : 'column-graph').concat(
              ' question-single-' + getChoiceClass(question.questionId)
            )
          "
          :key="question.id"
          v-if="question.data"
        >
          <template v-if="question.data.answers">
            <ul>
              <li
                v-for="(answer, index) in question.data.answers"
                :key="'oe' + index"
              >
                {{ answer }}
              </li>
            </ul>
          </template>
          <template v-else>
            <div class="g-bar-row" v-if="question.score">
              <div class="question-score">{{ question.score.toFixed(2) }}</div>
            </div>
            <div v-for="item in question.data" class="g-bar-row" :key="item.id">
              <div class="g-bar-hold">
                <div class="g-bar" :style="{ width: item * 100 + '%' }"></div>
              </div>
              <div class="g-bar-label">{{ Math.round(item * 100) }}%</div>
            </div>
            <div v-if="addBumper(question)" class="g-bar-bumper"></div>
          </template>
        </div>
        <div
          v-if="!question.data"
          :key="question.id"
          :class="
            'column-graph ' +
            'question-single-' +
            getChoiceClass(question.questionId)
          "
        >
          <p>No Matching Data Found</p>
        </div>
      </template>
    </div>
    <filter-form
      v-else-if="step === 'FILTER'"
      :filters="filteringData.filters"
      :column="column"
      :next="() => setStep('SUBSET')"
      :cancel="() => setStep(null)"
    ></filter-form>
    <subset
      v-else-if="step === 'SUBSET'"
      :subsets="filteringData.subsets"
      :column="column"
      :type="pageType"
      :submit="getData"
    ></subset>
    <div class="loading-data" v-else-if="!column.empty">
      <img src="/assets/images/analyze/loading.svg" alt="Fetching Data" />
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'

  import ordinal from 'ordinal-number-suffix'

  import Filter from './Filter'
  import Subset from './Subset'

  export default {
    name: 'Column',
    props: [
      'column',
      'id',
      'filteringData',
      'index',
      'pageType',
      'remove',
      'updateData',
      'editing',
      'questions',
      'hasRows',
    ],
    components: {
      'filter-form': Filter,
      Subset,
    },
    data() {
      return {
        step: null,
      }
    },
    computed: {
      title() {
        const self = this
        let filter = _.find(
          this.filteringData.filters,
          (filter) => filter.name === self.column.filter
        )
        if (filter) {
          return filter.title
        } else if (!self.column.filter) {
          return ''
        } else {
          var titleId = self.column.filter.split('!!')[1]
          var titleWords = self.column.filter.split('!!')[0]
          let filter = _.find(
            this.filteringData.filters,
            (filter) => filter.name === titleWords
          )
          let sub = _.find(filter.subselectors, (sub) => sub.key === titleId)
          //console.log('filter:', filter)
          var title = sub ? filter.title + ': ' + sub.title : filter.title
          return title
        }
      },
      subsets() {
        const self = this

        //console.log('this.column.subsets', this.column.subsets)
        //console.log('self.filteringData.subsets', self.filteringData.subsets)
        //console.log('pageType:' + this.pageType)

        const subsets =
          this.pageType.toUpperCase() === 'STUDENT'
            ? _.chain(this.column.subsets)
                .groupBy('parent')
                //.tap((x) => console.log('subsets grouped: ', x))
                .map((subsets, parentId) => {
                  const parentObj = _.find(
                    self.filteringData.subsets,
                    (parentObj) =>
                      Number(parentObj.questionId) === Number(parentId)
                  )

                  //console.log('parentObj', parentObj)
                  if (!parentObj) return

                  const systemSubsets = _.chain(subsets)
                    .map((subset) => {
                      const sysSubset = _.find(
                        parentObj.choices,
                        (choice) =>
                          Number(choice.id) === Number(subset.subsetRef)
                      )
                      return sysSubset ? sysSubset.title : ''
                    })
                    .compact()
                    .value()
                  //console.log('parentObj.title:' + parentObj.title)
                  return `${parentObj.title} (${systemSubsets.join(
                    ', '
                  )})`.trim()
                })
                .compact()
                .join(', ')
                .value()
            : _.chain(this.column.subsets)
                .map((subset) => {
                  const sysSubset = _.find(
                    self.filteringData.subsets,
                    (_subset) => Number(subset.subsetRef) === Number(_subset.id)
                  )
                  return sysSubset ? sysSubset.title : undefined
                })
                .compact()
                .join(', ')
                .value()
        return subsets || 'None Defined'
      },
    },
    methods: {
      getPercentile(point) {
        return point ? ` / ${ordinal(point)} Percentile` : ''
      },
      getQuestionsScore(questions) {
        var qtotal = 0
        var qCount = 0
        for (let question of questions) {
          if (question.score) {
            qtotal += question.score
            qCount++
          }
        }
        var qCalc = qtotal / qCount
        return qCalc.toFixed(2)
      },
      getData() {
        // Clear out previous
        this.column.setData(null)
        this.column.removeEmpty()
        this.setStep(null)
        this.updateData()
        this.focusOnLoad()
      },

      focusOnLoad() {
        this.$refs.columnTitle.focus()
      },
      removeColumn() {
        this.remove(this.column)
      },
      setStep(step) {
        this.step = step
        this.editing.next(this.column.uuid)
      },
      addBumper(question) {
        const questions = _.filter(
          this.column.data.questions,
          (_question) => _question.questionId === question.questionId
        )
        return questions.length < 2 || question.rowId === questions[1].rowId
      },
      getChoiceClass(q) {
        //console.log('question:' + q)
        if (this.data.questions[q]) {
          if (this.data.questions[q].choices.length) {
            //console.log('found: ' + this.data.questions[q].choices.length)
            return this.data.questions[q].choices.length
          } else {
            //console.log('no choices: ' + this.data.questions[q].choices.length)
            return '6'
          }
        } else {
          //console.log(JSON.stringify(this.column.data.questions))
          //console.log('not in data, looking in column')
          for (let question of this.column.data.questions) {
            if (question.questionId == q && question.data) {
              //console.log(JSON.stringify(question))
              //console.log('answer count:' + question.data.length)
              return question.data.length
            }
          }
        }
      },
    },
    mounted() {
      const self = this
      this.editing.subscribe((uuid) => {
        if (uuid !== self.column.uuid) self.step = null
      })
    },
    destroyed() {
      //this.editing.unsubscribe()
    },
  }
</script>
